
import moment from 'moment';

export const DateUtil = {

  dateFrom(val: any): Date|null {

    if( ! val)
      return null;

    if(val instanceof Date)
      return val;

    const fromStandardFormat = new Date(val);
    if( ! isNaN(fromStandardFormat.getTime()))
      return fromStandardFormat;

    const parsed = moment(val);
    return parsed.isValid()? parsed.toDate() : null;
  },

  /** to "yyyy-mm-dd" format */
  formatToISOYearDay(date: Date | string | number){
    const parsed = this.dateFrom(date);
    if( ! parsed)
      throw new Error('invalid date value: '+date);
    return moment(parsed).format('YYYY-MM-DD');
  },

  get18YearsOld(): Date {
    return moment().subtract(18, 'y').toDate();
  },

  get14YearsOld(): Date {
    return moment().subtract(14, 'y').toDate();
  }

};