import { actionCreator } from '@/utils/redux/action-creator';

import { Value } from './types';


export enum ExtraDocValueType {
  SSN = 'SSN',
  TAX_ID = 'TAX_ID',
}

export type SaveValueActionPayload = Value & { type: ExtraDocValueType };

const creator = actionCreator('DOCUMENTS');

export const DocumentAction = {
  Set:                      creator.asyncActionWithPayload<SaveValueActionPayload, void>('SET_VALUE'),
  ValuesUpdated:            creator.syncAction('VALUES_UPDATED'),
  SaveAndStartVerification: creator.asyncAction<void>('SAVE_AND_START_VERIFICATION'),
  SetTaxIdServiceUnavailable: creator.syncActionWithPayload<boolean>('SET_TAX_ID_SERVICE_UNAVAILABLE'),
};