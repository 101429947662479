import * as React from 'react';
import * as ReactDOM from 'react-dom';
import App from '@/App';
import { initPolyfillForPromiseAllSettled } from '@/utils/promise';
import * as Sentry from '@sentry/react';
import { Config } from '@/config';
import { Net } from '@/utils/axios';
import { Goal, Metrika } from '@/api/metrika';
import { LSCache } from '@/api/storage';


Sentry.init({
  dsn: 'https://d761d198fe3540f7ab4fa4ca9f120a89@o379421.ingest.sentry.io/5436881?stacktrace.app.packages=application&maxmessagelength=3000',
  environment: Config.Env || 'prod',
  release: Config.Version,
});


LSCache.setBucket('-mid-');
LSCache.setExpiryMilliseconds(1);
LSCache.flushExpired();

// for old browsers support
initPolyfillForPromiseAllSettled();

const rootEl = document.getElementById('root');
ReactDOM.render(<App />, rootEl);


// send goals about requests errors
Net.interceptors.response.use(undefined, (e)=>{

  const {status} = e;

  if( ! status)
    Metrika.reach(Goal.err.Network);
  else if(status === 400)
    Metrika.reach(Goal.err.BadData)
  else if(status === 401 || status === 403)
    Metrika.reach(Goal.err.Access);
  else if(status >= 500 && status <= 599)
    Metrika.reach(Goal.err.Server);

  // rethrow e;
  throw e;
})

// comment in for PWA with service worker in production mode
// registerServiceWorker();
