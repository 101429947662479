
import * as lscache from 'lscache';

export const LocalStorage = {

  getItem(key: string): string | null {
    try {
      return localStorage.getItem(key);
    }
    catch (e){
      console.warn('cannot localStorage.getItem', e.message);
      return null;
    }
  },

  setItem(key: string, value: string){
    try {
      localStorage.setItem(key, value);
    }
    catch (e){
      console.warn('cannot localStorage.setItem', e.message);
    }
  },

  removeItem(key: string) {
    try {
      localStorage.removeItem(key);
    }
    catch (e){
      console.warn('cannot localStorage.removeItem', e.message);
    }
  }
}


export const SessionStorage = {

  getItem(key: string): string | null {
    try {
      return sessionStorage.getItem(key);
    }
    catch (e){
      console.warn('cannot sessionStorage.getItem', e.message);
      return null;
    }
  },

  setItem(key: string, value: string){
    try {
      sessionStorage.setItem(key, value);
    }
    catch (e){
      console.warn('cannot sessionStorage.setItem', e.message);
    }
  },

  removeItem(key: string) {
    try {
      sessionStorage.removeItem(key);
    }
    catch (e){
      console.warn('cannot sessionStorage.removeItem', e.message);
    }
  }
}

export const LSCache = {

  set(key: string, value: any, time?: number): boolean {
    try {
      return lscache.set(key, value, time)
    }
    catch (e){
      console.warn('cannot lscache.set', e.message);
      return false;
    }
  },

  get(key: string): any {
    try {
      return lscache.get(key);
    }
    catch (e){
      console.warn('cannot lscache.get', e.message);
      return undefined;
    }
  },

  setBucket(bucket: string){
    try {
      lscache.setBucket(bucket);
    }
    catch (e){
      console.warn('cannot lscache.setBucket', e.message);
    }
  },

  setExpiryMilliseconds(milliseconds: number){
    try {
      lscache.setExpiryMilliseconds(milliseconds);
    }
    catch (e){
      console.warn('cannot lscache.setExpiryMilliseconds', e.message);
    }
  },

  flushExpired(){
    try {
      lscache.flushExpired();
    }
    catch (e){
      console.warn('cannot lscache.flushExpired', e.message);
    }
  },
}



