import React, { PropsWithChildren } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, createStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';

type stylesProps = { size: number };
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    vertical: {
      display: 'flex',
      flexDirection: 'column',
      '& > :not(:last-child)': {
        marginBottom: ({ size }: stylesProps): string => `${theme.spacing(size)}px`,
      },
    },

    horizontal: {
      display: 'flex',
      flexDirection: 'row',
      '& > :not(:last-child)': {
        marginRight: ({ size }: stylesProps): string => (theme.direction === 'ltr' ? `${theme.spacing(size)}px` : '0'),
        marginLeft: ({ size }: stylesProps): string => (theme.direction === 'rtl' ? `${theme.spacing(size)}px` : '0'),
      },
    },

    none: {
      display: 'block'
    }
  }),
);

export type SpaceProps = PropsWithChildren<
  {
    component?: React.ElementType;
    className?: string;
    style?: React.CSSProperties;
    direction?: 'horizontal' | 'vertical' | 'none';
    size?: number;
    wrapItems?: boolean;
  } & React.HTMLAttributes<HTMLElement>
>;

const Space: React.FC<SpaceProps> = ({
  className,
  component = 'span',
  style,
  direction = 'horizontal',
  size = 1,
  children,
  wrapItems = false,
  ...props
}: SpaceProps) => {
  const classes = useStyles({ size });
  if (children === null || children === undefined) {
    return <></>;
  }

  let directionClass = classes.horizontal;
  if(direction === 'vertical')
    directionClass = classes.vertical;
  else if(direction === 'none')
    directionClass = classes.none;

  return (
    <Box
      style={style}
      component={component}
      className={clsx(className, directionClass, 'x-space')}
      {...props}
    >
      {// prettier-ignore
      wrapItems
          ? React.Children.map(children, (child, index) => (
            <span
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              style={{}}
            >
              {child}
            </span>
          ))
          : children}
    </Box>
  );
};

export default Space;
