import { RemoteAction } from '@/model/remote_state/actions';
import { AuthAction } from '@/model/auth/actions';
import { ConfirmationAction } from '@/model/confirmation/actions';
import { GlobalAction } from '@/model/global/actions';
import { DocumentAction } from '@/model/documents/actions';
import { ProfileAction } from '@/model/profile/actions';
import { VerificationAction } from '@/model/verification/actions';
import { AgreementAction } from '@/model/agreement/actions';
import { MobileIdAction } from '@/model/mobileId/actions';
import { ParamsAction } from '@/model/params/actions';

export const Action = {

  global:         GlobalAction,
  remoteState:    RemoteAction,
  agreement:      AgreementAction,
  auth:           AuthAction,
  confirmation:   ConfirmationAction,
  mobileId:       MobileIdAction,
  profile:        ProfileAction,
  document:       DocumentAction,
  verification:   VerificationAction,
  params:         ParamsAction,
};