import { Config } from '@/config';


export interface AgreementState {
  userAgreement: boolean;
  done?: boolean;
  isModal: boolean;
}

export const initialState: AgreementState = {
  userAgreement: Config.DefaultAgreement,
  isModal: false,
  done: undefined,
};

export enum Document {
  USER_AGREEMENT = 'USER_AGREEMENT'
}
