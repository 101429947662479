import { StringUtil } from '@/utils/string-util';

declare global {
  interface Window {
    ENV: any;
  }
}

const env = window.ENV || {};
const serverUrl = process.env.REACT_APP_SERVER_URL || env.REACT_APP_SERVER_URL || 'https://mid-api.dev.mnxsc.tech';


export const Config = {
  Env: env.ENVIRONMENT_NAME,
  Version: env.VERSION || '-',
  ServerUrl:  StringUtil.removeEnd(serverUrl, '/'),
  FetchTimeout: env.REACT_APP_FETCH_TIMEOUT || 30000,
  SupportEmail: env.REACT_SUPPORT_EMAIL || process.env.REACT_SUPPORT_EMAIL || 'helpdesk@support.moneta.ru',
  CheckIframeParent: env.ENVIRONMENT_NAME !== 'dev',
  DefaultAgreement: true,
  SkipInn: false,
  InServiceMaintenance: false,
};