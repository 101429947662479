import React from 'react';
import MaskedInput from 'react-text-mask';
import { onMaskFocus } from '@/components/mask-util';


export interface MaskInputProps {
  inputRef?: (ref: HTMLElement | null) => void;
}

export const PassportMaskInput: React.FC<MaskInputProps> = ({ inputRef, ...other }: MaskInputProps) => (
  <MaskedInput
    type="number"
    {...other}
    ref={(ref: MaskedInput): void => {
      if (inputRef) {
        inputRef(ref ? ref.inputElement : null);
      }
    }}
    mask={[/\d/, /\d/, /\d/, /\d/, ' ', '/', ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/]}
    onFocus={onMaskFocus}
    placeholderChar="_"
    showMask
  />
);