import React from 'react';
import { IntlProvider } from 'react-intl';
import { flatten } from 'flat';
import 'moment/locale/ru';

import { DEFAULT_I18N } from '../withI18nSupport';
import * as locales from '../../i18n';

const tryLocale = (locale: string): [string, Record<string, string>] => {
  const foundLocale = (locales as { [k in string]: {} })[locale.replace('-', '')];
  return foundLocale && [locale, flatten(foundLocale)];
};

const parseLocale = ({ locale }: { locale?: string }): [string, Record<string, string>] =>
  (locale && tryLocale(locale)) || tryLocale(DEFAULT_I18N);

const withI18n = (Component: React.FC) => (props: any) => {
  const [locale, messages] = parseLocale(props);
  return (
    <IntlProvider locale={locale} messages={messages}>
      <Component {...props} />
    </IntlProvider>
  );
};

export default withI18n;
