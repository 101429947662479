import { VerificationStatus } from '@/model/verification/types';
import { Net } from '@/utils/axios';


export const queryStartVerification = async (): Promise<VerificationStatus> =>{
  const {status} = await Net.post('/user/verification/start');
  return status;
};

export const queryGetVerificationStatus = async (): Promise<VerificationStatus> => {
  const {status} = await Net.get('/core/verification/status');
  return status;
}
