
export enum LangType {
  Ru = 'ru',
  En = 'en'
}
export const DefLang = LangType.Ru;

export const Lang = {
  toLang(locale?: string):LangType {

    if(locale?.startsWith('ru'))
      return LangType.Ru;

    if(locale?.startsWith('en'))
      return LangType.En;

    return DefLang;
  }
};