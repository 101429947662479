import { ActionWithPayload } from '@/utils/redux/action-creator';

import { createReducer } from '@/utils/redux/reducer-util';
import { Action } from '@/model/actions';
import { AgreementState, Document, initialState } from './types';
import { ChangeDocumentStatePayload, SetAgreementDonePayload } from './actions';
import { Config } from '@/config';

export const reducers = createReducer<AgreementState>(initialState, {
  [Action.agreement.SetConfirm.type](
    state: AgreementState, { payload }: ActionWithPayload<ChangeDocumentStatePayload>
  ): AgreementState {
    switch (payload.document) {
      case Document.USER_AGREEMENT:
        return { ...state, userAgreement: Config.DefaultAgreement || payload.confirmed };
      default:
        return state;
    }
  },
  [Action.agreement.SetModal.type](
    state: AgreementState
  ): AgreementState {
    return {...state, isModal: true}
  },
  [Action.agreement.SetDone.type](
    state: AgreementState, {payload}: ActionWithPayload<SetAgreementDonePayload>
  ): AgreementState {
    return {...state, done: payload.done}
  }
});

export default reducers;
