import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { history } from '@/hocs/withStore/configureStore';
import { Action } from '@/model/actions';
import { Util } from '@/utils/util';
import { AppParams } from '@/model/params/types';
import { queryGetAppParams } from '@/api/app_params';


const withAppParams = (Component: React.FC) => (props: any) => {

  const dispatch = useDispatch();
  const [init, setInit] = useState(false);

  useEffect(()=>{
    if(!init){

      setInit(true);

      const params: AppParams = queryGetAppParams();
      Action.params.SetAll(params)(dispatch);

      // remove params from url
      if( ! Util.isBlank(window.location.search)){
        history.push('/');
      }
    }
  }, [init, dispatch]);

  return (
    <>
      <Component {...props} />
    </>
  );
}

export default withAppParams;