import { Net } from '@/utils/axios';
import { RemotePassport, RemoteProfile } from '@/model/remote_state/types';
import { StringUtil } from '@/utils/string-util';

export interface PutUserReq {
  firstName: string,
  lastName: string,
  middleName?:string | null,
  birthDay?: string | null,
  insuranceCode?: string | null,
  taxCode?: string | null,
}

export interface FindInnReq {
  firstName: string,
  lastName: string,
  middleName: string,
  passport: string,
  birthDay: string
}

export const queryFindInn = async (data: FindInnReq): Promise<string|null> => {
  const result: any = await Net.post('/inn/find', data);
  return result?.inn || null;
};

export const queryPutUserInfo = async (data: PutUserReq): Promise<RemoteProfile> => {

  return Net.put('/core/verification/profile', {
    firstName: data.firstName.trim(),
    lastName: data.lastName.trim(),
    middleName: data.middleName?.trim() || '-',
    birthDay: data.birthDay?.trim(),
    insuranceCode: data.insuranceCode?.trim(),
    taxCode: data.taxCode?.trim(),
  });
};

export const queryPutPassport = async (passport?: string | null): Promise<RemotePassport> => {

  const normPassport = StringUtil.replaceAll(passport?.trim() || '', ' ', '');
  const series = normPassport?.length === 10? normPassport.substring(0, 4) : '';
  const num = normPassport?.length === 10? normPassport.substring(4) : '';

  return Net.put('/core/verification/documents/passport', {
    series,
    num,
  })
};