import { MobileIdConfig, PhoneOperator } from '@/model/Phone';
import { Value } from '@/model/common';


export interface GetPhoneOperatorResp {
  operator: PhoneOperator,
  mobileIdConfig?: MobileIdConfig,
}

export interface MobileIdState {
  status?: MobileIdStatus,
  reqId?: string,
  operator?: PhoneOperator,
  config?: MobileIdConfig,
  birthDayForCheck?: Value<string>
}

export enum MobileIdStatus {
  NeedSelect = 'NeedSelect',
  NeedBirthDay = 'NeedBirthDay',
  Skip = 'Skip',
  WaitResult = 'WaitResult',
  Failed = 'Failed',
}

export interface MobileIdReq {
  reqId: string,
  timeout?:number,
}

export interface MobileIdData {
  status: MobileIdStatus,
  reqId?: string,
}

export enum MobileIdRequestState {
  WaitConfirm             = 'WaitConfirm',
  FailedGetInfo           = 'FailedGetInfo',
  CanceledByUser          = 'CanceledByUser',
  VerificationStarted     = 'VerificationStarted',
  VerificationNotStarted  = 'VerificationNotStarted',
  PersonificationDone     = 'PersonificationDone',
}

export interface MobileIdRequestStatus {
  reqId: string,
  state: MobileIdRequestState,
  errorCode?: string
}


export const initialState: MobileIdState = {};


