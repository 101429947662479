import { VerificationStatus } from '@/model/verification/types';
import { MobileIdConfig, PhoneOperator } from '@/model/Phone';
import { LangType } from '@/model/Lang';


export interface RemoteState {
  status: RemoteStateStatus;
  data?: RemoteStateData;
  authToken?: string;
}

export enum RemoteStateStatus {
  NotLoaded = 'NotLoaded',
  Valid = 'Valid',
  Error = 'Error'
}

export interface PartnerInfo {
  id: string,
  lang: LangType,
  name: string,
  returnUrl: string,
  iconUrl?: string,
  iconHasNaming: boolean,
  allowedDomains?: string,
}

export const StubPartner: PartnerInfo = {
  id: '',
  lang: LangType.Ru,
  name: '',
  returnUrl: '',
  iconHasNaming: false,
};


export interface ProfilePhone {
  number: string,
  status: ProfilePhoneStatus
}

export enum ProfilePhoneStatus {
  Verified = 'Verified',
  NotVerified = 'NotVerified',
}


export interface RemoteProfile {

  profileId: number,
  firstName: string,
  lastName: string,
  middleName?: string,
  birthDay?: string,

  insuranceCode?: string,
  taxCode?: string,

  phone?: ProfilePhone,
}

export function isEmptyRemoteField(val?: string): boolean {

  if(!val || val.length === 0)
    return true;

  const normVal = val.toLowerCase().trim();
  return normVal === '-' || normVal === 'не указано';
}


/**
 * Example:
 * <pre>
 {
    "num": "9200",
    "series": "000555",
    "issued": "2007-01-01",
    "issuer": "УВД Советского района г. Казани",
    "militaryCode": "011-00"
}
 * </pre>
 */
export interface RemotePassport {
  num: string, // Passport number
  series: string, // Passport series
  issued?: string, // Document issuance date
  issuer?: string, // Document issuer full qualified name
  militaryCode?: string, // Special military code in Russian national passport
}


export interface RemoteStateData {

  userId?: string,
  profile?: RemoteProfile,
  passport?: RemotePassport,
  verification?: VerificationStatus,
  partner?: PartnerInfo,
  mobileIdOperator?: PhoneOperator,
  mobileIdConfig?: MobileIdConfig,
  isPersonified: boolean,
  workMode: WorkMode,
  needPhoneExtraCheck: boolean,
  config: FrontConfig,
}

export enum WorkMode {
  Any = 'Any',
  Simple = 'Simple',
  Full = 'Full'
}

export interface FrontConfig {
  skipInn: boolean,
  inServiceMaintenance: boolean
}


export const initialState: RemoteState = {
  status: RemoteStateStatus.NotLoaded,
  data: undefined,
  authToken: undefined,
};


