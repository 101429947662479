import { ActionWithPayload } from '@/utils/redux/action-creator';

import { createReducer } from '@/utils/redux/reducer-util';
import { Action } from '@/model/actions';
import { initialState, State } from './types';
import { SaveConfirmationStateActionPayloadState } from './actions';

export const reducers = createReducer<State>(initialState, {
  [Action.confirmation.support.SetState.type](
    state: State,
    { payload }: ActionWithPayload<SaveConfirmationStateActionPayloadState>,
  ): State {
    return {
      ...state,
      state: payload.state,
      validTill: payload.validTill !== undefined ? payload.validTill || undefined : state.validTill,
      reqId: payload.reqId !== undefined? payload.reqId : state.reqId,
    };
  }
});

export default reducers;
