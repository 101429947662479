import { Util } from '@/utils/util';
import { Duration } from '@/utils/duration';
import { LSCache } from '@/api/storage';

const expiresDays = 30 * Duration.OneDay;

function key(phone: string) {
  const phoneHash = Util.hashCode(phone);
  return `agreement-${phoneHash}`;
}

export const storeAgreementDone = (phone: string)=>{
  LSCache.set(key(phone), 'true', expiresDays);
};

export const isAgreementDone = (phone: string): boolean => {
  const val = LSCache.get(key(phone));
  return Util.parseBool(val, false);
};