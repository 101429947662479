

export type Value = {
  selected: boolean;
  value: string;
  hasError: boolean;
};

export interface DocumentState {
  taxId?: Value;
  ssn?: Value;
  changed: boolean;
  taxIdServiceUnavailable: boolean;
}

export const initialState: DocumentState = {
  changed: true,
  taxIdServiceUnavailable: false,
};


// from https://github.com/Kholenkov/js-data-validation/blob/master/data-validation.js
export function isValidInn(inn: string): boolean {

  if(inn.length !== 12) {
    return false;
  }

  const n11 = checkInnDigest(inn, [7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
  const n12 = checkInnDigest(inn, [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
  if (n11 !== parseInt(inn.charAt(10), 10)
      || n12 !== parseInt(inn.charAt(11), 10)) {
    return false;
  }

  return true;
}

// from https://github.com/Kholenkov/js-data-validation/blob/master/data-validation.js
export function isValidSnils(snils: string): boolean {

  if(snils.length !== 11) {
    return false;
  }

  let sum = 0;
  for (let i = 0; i < 9; i++) {
    sum += parseInt(snils.charAt(i), 10) * (9 - i);
  }

  let checkDigit = 0;
  if (sum < 100) {
    checkDigit = sum;
  } else if (sum > 101) {
    checkDigit = sum % 101;
    if (checkDigit === 100) {
      checkDigit = 0;
    }
  }

  const last2Digits = snils.slice(-2);
  const targetNum = parseInt(last2Digits, 10);
  return checkDigit === targetNum;
}

function checkInnDigest(inn: string, coefficients: number[]) {
  let n = 0;
  for (let i=0; i<coefficients.length; i++) {
    const digit = parseInt(inn.charAt(i), 10);
    const coeff = coefficients[i];
    n += coeff * digit;
  }
  return n % 11 % 10;
}
