import { createReducer } from '@/utils/redux/reducer-util';
import { RemoteStateResp } from '@/model/remote_state/actions';
import { ActionWithPayload } from '@/utils/redux/action-creator';
import { Action } from '@/model/actions';
import { initialState, RemoteState } from './types';

export const reducers = createReducer<RemoteState>(initialState, {
  [Action.remoteState.Set.type](state: RemoteState, {payload}: ActionWithPayload<RemoteStateResp>): RemoteState {
    return {
      ...state,
      status: payload.status,
      data: payload.data
    };
  }
});

export default reducers;
