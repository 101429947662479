import { actionCreator } from '@/utils/redux/action-creator';
import { ConfirmationState } from './types';


export type CheckConfirmationActionPayload = { code: string, reqId: string};

export type SaveConfirmationStateActionPayloadState = {
  state: ConfirmationState;
  reqId?: string,
  validTill?: Date | null
};

const creator = actionCreator('CONFIRMATION');

export const ConfirmationAction = {

  Send:       creator.asyncAction('SEND_CONFIRMATION'),
  CheckCode:  creator.asyncActionWithPayload<CheckConfirmationActionPayload, void>('CHECK_CONFIRMATION'),
  Reset:      creator.syncAction('CONFIRMATION_FINISHED'),

  support: {
    SetState: creator.syncActionWithPayload<SaveConfirmationStateActionPayloadState>('SAVE_CONFIRMATION_STATE'),
  }
};