import React, { ReactNode } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

import { Space } from '@/components';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

const useStyles = makeStyles((theme: Theme) => ({
  base: {
    alignItems: 'center',
    color: '#999BBF',
  },
}));

interface IdentificationDurationProps {
  title: ReactNode;
}

const IdentificationDuration: React.FC<IdentificationDurationProps> = ({ title }: IdentificationDurationProps) => {
  const classes = useStyles();
  return (
    <Space className={classes.base}>
      <AccessTimeIcon />
      <span>{title}</span>
    </Space>
  );
};

export default IdentificationDuration;
