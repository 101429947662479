import { all, put } from 'redux-saga/effects';
import { combineReducers, Reducer } from 'redux';
import * as auth from './auth';
import * as global from './global';
import * as documents from './documents';
import * as confirmation from './confirmation';
import * as profile from './profile';
import * as verification from './verification';
import * as remoteState from './remote_state';
import * as agreement from './agreement';
import * as mobileId from './mobileId';
import * as params from './params';
import { RootState } from './types';
import { setDefaultErrorHandler } from '@/utils/redux/action-creator';
import { Action } from '@/model/actions';

export function* rootSaga(): Generator {
  yield all([
    global.sagas(),
    auth.sagas(),
    confirmation.sagas(),
    documents.sagas(),
    profile.sagas(),
    verification.sagas(),
    remoteState.sagas(),
    agreement.sagas(),
    mobileId.sagas(),
  ]);
}

export const rootReducer = (): Reducer<RootState> =>
  combineReducers<RootState>({
    auth: auth.reducers,
    documents: documents.reducers,
    confirmation: confirmation.reducers,
    global: global.reducers,
    profile: profile.reducers,
    verification: verification.reducers,
    remoteState: remoteState.reducers,
    agreement: agreement.reducers,
    mobileId: mobileId.reducers,
    params: params.reducers,
  });


setDefaultErrorHandler(function* (e: Error){
  yield put(Action.global.AddError({ messageId: 'common.error.info' }).pure);
});


export default {
  rootReducer,
};
