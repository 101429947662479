import { delay, put, putResolve, takeEvery } from 'redux-saga/effects';
import { Action as ReduxAction } from 'redux';

import { history } from '@/hocs/withStore/configureStore';

import { ActionWithPayload, withErrorHandler } from '@/utils/redux/action-creator';
import { Action } from '@/model/actions';
import { AddErrorActionPayload, SetPhoneActionPayload, TransmitToActionPayload } from './actions';
import { Util } from '@/utils/util';

let locks = 0;
const singleWorkers = new Set();

export const withGlobalLock = <T extends ReduxAction>(
  worker: (action: T) => unknown,
  singleWorkerId?: string,
): ((action: T) => Generator<unknown, unknown, T>) =>
  function* runner(action: T): Generator<unknown, void, T> {

    if(singleWorkerId){
      if(singleWorkers.has(singleWorkerId))
        return;
      singleWorkers.add(singleWorkerId);
    }

    locks++;
    yield putResolve(Action.global.SetLoading({ loading: true }).pure);
    try {
      yield worker(action);
    } finally {
      if (--locks === 0) {
        yield putResolve(Action.global.SetLoading({ loading: false }).pure);
      }

      if(singleWorkerId){
        singleWorkers.delete(singleWorkerId);
      }
    }
  };

function* setPhone({ payload: { phone } }: ActionWithPayload<SetPhoneActionPayload>): Generator {
  const hasError = phone.length !== 0 && phone.length < 11;
  yield put(Action.global.support.SetValidatedPhone({ phone, hasError }).pure);
}

function transmitTo(
  { payload: { state, replaceHistory } }: ActionWithPayload<TransmitToActionPayload>
): void {
  const location = `/${state.toLowerCase()}`;
  if (history.location.pathname !== location) {
    replaceHistory ? history.replace(location) : history.push(location);
  }
}

function* addError({ payload: error }: ActionWithPayload<AddErrorActionPayload>): Generator {
  const id = Util.uuid();
  yield put(Action.global.support.SetError({ id, ...error }).pure);
  yield delay(10000);
  yield put(Action.global.support.RemoveError({ id }).pure);
}

export default function* rootSaga(): Generator {
  yield takeEvery(Action.global.Transmit.type,
    withGlobalLock(
      withErrorHandler(
        transmitTo)));

  yield takeEvery(Action.global.AddError.type,
    withErrorHandler(
      addError));

  yield takeEvery(Action.global.SetPhone.type,
    withErrorHandler(
      setPhone));
}
