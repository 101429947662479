import { Util } from '@/utils/util';
import { GetPhoneOperatorResp, MobileIdData, MobileIdReq, MobileIdRequestStatus } from '@/model/mobileId/types';
import { Net } from '@/utils/axios';
import { Duration } from '@/utils/duration';
import { LSCache } from '@/api/storage';

const expiresDays = 20 * Duration.OneMin;

function key(phone: string) {
  const phoneHash = Util.hashCode(phone);
  return `mobileid-${phoneHash}-#2`; // #2 key for skip old cache with much more ttl (30 days)
}

export interface StartMobileIdReq {
  birthDayForCheck?: string
}


export const storeMobileIdData = (phone: string, data: MobileIdData)=>{
  LSCache.set(key(phone), data, expiresDays);
};

export const getMobileIdData = (phone: string): MobileIdData | undefined => {
  return LSCache.get(key(phone)) as MobileIdData;
};

export const queryGetMobileIdOperator = async (): Promise<GetPhoneOperatorResp> => {
  const resp: GetPhoneOperatorResp = await Net.get('/mobile-id/discovery/operator');
  return resp;
};

export const queryStartMobileId = async (data?: StartMobileIdReq): Promise<MobileIdReq> =>{
  return Net.post('/mobile-id/start', data);
};

export const queryGetMobileIdRequestStatus = async (reqId: string): Promise<MobileIdRequestStatus> => {
  return Net.post(
    '/mobile-id/status',
    {
      reqId
    });
}
