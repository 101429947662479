import React, { ReactNode } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Divider, Theme, Typography } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import { Space } from '@/components';

const useStyles = makeStyles((theme: Theme) => ({
  optionBox: {
    cursor: 'pointer',
    border: '1px solid #C3C3D9',
    backgroundColor: 'white',
    boxSizing: 'border-box',
    borderRadius: '12px',
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    '&:hover': {
      borderColor: theme.typography.overline.color,
    },
  },
  optionRow: {
    alignItems: 'center',
    fontSize: '16px',
  },
}));

interface SingleOptionProps {
  icon: ReactNode;
  label: ReactNode;
  detalization?: ReactNode;
  postfix?: ReactNode;
  onSelect: () => void | Promise<void>;
}

const SingleOption: React.FC<SingleOptionProps> = ({
  icon,
  label,
  detalization,
  postfix,
  onSelect,
}: SingleOptionProps) => {
  const classes = useStyles();
  return (
    <Space direction="vertical" size={1.5} className={classes.optionBox} onClick={onSelect}>
      <Space className={classes.optionRow}>
        {icon}
        {label}
        <ArrowForwardIosIcon style={{ marginLeft: 'auto', justifySelf: 'flex-end', color: '#969CB4', opacity: 0.4 }} />
      </Space>
      {detalization && (
        <>
          <Divider />
          <Typography variant="body1">{detalization}</Typography>
        </>
      )}
      {postfix}
    </Space>
  );
};

export default SingleOption;
