
export const ApiError = {

  common: {
    BadRequest:         'BadRequest',
    Unauthorized:       'Unauthorized',
    Forbidden:          'Forbidden',
    NotFound:           'NotFound',
    Unavailable:        'ServiceUnavailable',
    RateLimit:          'RateLimit',
    Unknown:            'Unknown',
    OutdatedRequest:    'OutdatedRequest',
    UnknownInput:       'UnknownInput',
    UnknownOperation:   'UnknownOperation',
    InvalidReqType:     'InvalidRequestType',
  },

  phone: {
    UnsupportedPhoneFormat: 'UnsupportedPhoneFormat',
  },

  mobileId: {
    UnknownPhoneProvider:   'UnknownPhoneProvider',
    UnknownReqId:           'UnknownReqId',
    NeedBirthDayForCheck:   'NeedBirthDayForCheck',
    InvalidBirthDayForCheck:'InvalidBirthDayForCheck',
  },

  document: {
    ProfileNotFound:    'ProfileNotFound',
    PassportNotFound:   'PassportNotFound',
  },

  verification: {
    SendLimit:               'SendLimit',
    CheckLimit:              'CheckLimit',
    InvalidCode:             'InvalidConfirmationCode',
    PhoneExists:             'PhoneExists',
    PhoneNotExists:          'PhoneNotExists',
    PhoneAlreadyVerified:    'PhoneAlreadyVerified',
    PhoneNotVerified:        'PhoneNotVerified',
    FullModeOnly:            'FullModeOnly',
    SimpleModeOnly:          'SimpleModeOnly',
    AlreadyVerifiedInOtherProfile: 'AlreadyVerifiedInOtherProfile',
  },
};