import { Util } from '@/utils/util';
import queryString from 'query-string';
import lscache from 'lscache';
import { AppParams } from '@/model/params/types';
import { ModalQueryFlag } from '@/model/Modal';

const cacheKey = 'appParams';

export const queryGetAppParams = (): AppParams => {

  const {search} = window.location;
  let params;

  if( ! Util.isBlank(search) && search !== ModalQueryFlag){

    // use url params
    params = queryString.parse(search) || {};

    // and store them
    const toStore: AppParams = ({
      ...params
    }) as any;

    // filter storing data
    delete toStore.loaded;
    delete toStore.token;

    lscache.set(cacheKey, toStore);

  } else {
    // use stored params
    params = lscache.get(cacheKey) || {};
  }

  params.loaded = true;

  return params;
}