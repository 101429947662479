import { actionCreator } from '@/utils/redux/action-creator';

import { MobileIdStatus } from './types';
import { MobileIdConfig, PhoneOperator } from '@/model/Phone';
import { Value } from '@/model/common';

export type ShowMobileIdIfNeedPayload = {
  refreshMobileIdOperatorFromServer: boolean;
}

export type WaitResultPayload = {
  reqId: string;
}


const creator = actionCreator('MOBILE_ID');

export const MobileIdAction = {
  RestoreState:       creator.syncAction('RESTORE_STATE'),
  SaveState:          creator.syncAction('SAVE_STATE'),
  SetOperator:        creator.syncActionWithPayload<PhoneOperator>('SET_OPERATOR'),
  SetMobileIdConfig:  creator.syncActionWithPayload<MobileIdConfig>('SET_MOBILE_ID_CONFIG'),
  SetStatus:          creator.syncActionWithPayload<MobileIdStatus>('SET_STATUS'),
  SetReqId:           creator.syncActionWithPayload<string|undefined>('SET_REQ_ID'),
  ShowMobileIdIfNeed: creator.asyncActionWithPayload<ShowMobileIdIfNeedPayload, void>('SHOW_MOBILE_ID_IF_NEED'),
  StartRequest:       creator.asyncAction('START_REQUEST'),
  WaitResult:         creator.asyncActionWithPayload<WaitResultPayload, void>('WAIT_RESULT'),
  SetBirthdayForCheck: creator.syncActionWithPayload<Value<string>|undefined>('SET_BIRTHDAY_FOR_CHECK'),
};
