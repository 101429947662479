import { Action, Reducer } from 'redux';

export interface ActionType<T, P> extends Action<T> {
  type: T;
  payload: P;
}

export function createReducer<S>(
  initialState: S,
  handlers: { [k in string]: (state: S, action: ActionType<any, any>) => S },
): Reducer<S> {
  const r = (state: S, action: ActionType<any, any>): S =>
    handlers[action.type]?.(state, action) || state || initialState;
  return r as Reducer<S>;
}