import React from 'react';
import clsx from 'clsx';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/styles';

import { history } from '@/hocs/withStore/configureStore';

const useStyles = makeStyles(() => ({
  button: {
    cursor: 'pointer',
  },
}));

export type CloseButtonProps = {
  className?: string;
  onClick?: () => void | Promise<void>;
};

export const CloseButton: React.FC<CloseButtonProps> = ({ className, onClick }: CloseButtonProps) => {
  const classes = useStyles();

  return (
    <CloseIcon
      className={clsx(classes.button, className)}
      onClick={(): void | Promise<void> => (onClick ? onClick() : history.goBack())}
    />
  );
};

export default CloseButton;
