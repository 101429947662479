import { call, put, putResolve, select, takeEvery } from 'redux-saga/effects';

import { withErrorHandler } from '@/utils/redux/action-creator';
import { withGlobalLock } from '@/model/global/sagas';
import { RootState } from '@/model/types';

import { Action } from '@/model/actions';
import { queryPutUserInfo } from '@/api/profile';
import { Goal, Metrika } from '@/api/metrika';

function* submitDocuments(): Generator {

  const rootState = (yield select((state: RootState) => state)) as RootState;
  const [docs, profile] = [
    rootState.documents,
    rootState.profile
  ];

  const {taxId, ssn} = docs;

  if(docs.changed){

    // get new value or clear old if user unselect it
    let taxCode = taxId?.selected? taxId.value : undefined;
    if(taxId
        && !taxId.selected
        && taxId.value.length > 0){
      taxCode = '';
    }

    // get new value or clear old if user unselect it
    let insuranceCode = ssn?.selected? ssn.value : undefined;
    if(ssn
        && !ssn.selected
        && ssn.value.length > 0){
      insuranceCode = '';
    }

    try {

      yield call(queryPutUserInfo, {
        firstName: profile.firstName?.value || '',
        lastName: profile.lastName?.value || '',
        middleName: profile.middleName?.value || '',
        birthDay: profile.birthDay?.value || '',
        taxCode,
        insuranceCode
      });

      Metrika.reach(Goal.doc.Updated);

    } catch (e){
      Metrika.reach(Goal.err.DocumentUpdate);
      throw e;
    }

    yield put(Action.document.ValuesUpdated().pure);
  }

  yield putResolve(Action.verification.Start().pure);
}

export default function* rootSaga(): Generator {

  yield takeEvery(Action.document.SaveAndStartVerification.type,
    withGlobalLock(
      withErrorHandler(
        submitDocuments
      )));
}
