import { v4 as v4uuid } from 'uuid';
import * as Sentry from '@sentry/react';
import { Severity } from '@sentry/react';

export const Util = {

    /** support for ...msgs params */
    getLog(loggerName: string){
        return logWrapper(loggerName);
    },

    isEmpty(obj: any){
        return obj === null || obj === undefined;
    },

    isBlank(str: string|undefined|null ) {
        return str === null
          || str === undefined
          || str.length === 0;
    },

    isObject(obj: any){
        return obj !== null && typeof obj === 'object';
    },

    isString(obj: any){
        return ! this.isEmpty(obj) && typeof obj === 'string';
    },

    isArray(obj: any){
        return Array.isArray(obj);
    },

    lastFrom(list?: Array<any>){
        return list && list.length > 0? list[list.length-1] : undefined;
    },


    toJson(obj: any, pretty = false):string {
        return pretty?
            JSON.stringify(obj, null, 2)
            : JSON.stringify(obj);
    },

    toJsonPretty(obj: any):string {
        return Util.toJson(obj, true);
    },

    parseBool(obj: any, defVal?: boolean) : boolean {
        const strVal = obj?.toString().toLowerCase();
        if( this.isEmpty(strVal))
            return defVal || false;
        return strVal === 'true';
    },

    onErr(e: any) {
        // eslint-disable-next-line no-alert
        log.error('async error', e);
    },

    async timeout(ms: number, ...result: any[]): Promise<any> {
        return new Promise(resolve => setTimeout(resolve, ms, ...result));
    },

    hashCode(str: string): string {
        return str.split('').reduce((prevHash, currVal) =>
          // eslint-disable-next-line no-bitwise
          (((prevHash << 5) - prevHash) + currVal.charCodeAt(0))|0, 0).toString(16);
    },

    uuid(): string {
        return v4uuid();
    },

    isHexString(val: string) {
        return /^[0-9a-fA-F]+$/.test(val);
    }
};


const log = Util.getLog('Util');

function logWrapper(loggerName: string) {

    const prefix = `[${loggerName}] `;

    return {
        info(...msgs: any[]){
            console.info(prefix, ...msgs);
        },
        log(...msgs: any[]){
            console.log(prefix, ...msgs);
        },
        warn(...msgs: any[]){
            console.warn(prefix, ...msgs);
        },
        error(...msgs: any[]){
            console.error(prefix, ...msgs);
            sendLogToSentry(prefix, ...msgs);
        },
    }
}

function sendLogToSentry(prefix: string, ...msgs: any[]){

    Sentry.withScope(scope => {

        scope.setLevel(Severity.Error);
        scope.setTags({
            logger: prefix,
        });

        const error = msgs.find(msg => msg instanceof Error);
        if(error){

            const otherMsgs = msgs.filter(msg => msg !== error);
            const extraInfo = dataListToSingleMsg(otherMsgs);
            const extra = extraInfo? {extraInfo} : undefined;

            Sentry.captureException(error, { extra });
        }
        else {
            const singleMsg = dataListToSingleMsg(msgs);
            Sentry.captureMessage(singleMsg);
        }
    });
}

function dataListToSingleMsg(list: any[]) {
    return list
      .map(obj => toMsgPart(obj))
      .filter(str => str.length > 0)
      .join(' ').trim();
}

function toMsgPart(obj: any) {

    if(Util.isEmpty(obj))
        return '';

    if(Util.isString(obj))
        return obj.trim();

    if(Util.isObject(obj) || Util.isArray(obj)){
        try {
            return '\n' + Util.toJsonPretty(obj) + '\n';
        } catch (e) {
            return obj.toString();
        }
    }

    return obj.toString();
}
