import { actionCreator } from '@/utils/redux/action-creator';
import { Document } from './types';


export type ChangeDocumentStatePayload = { document: Document; confirmed: boolean };
export type SetAgreementDonePayload = { done: boolean };

const creator = actionCreator('AGREEMENT');

export const AgreementAction = {
  LoadDoneState:  creator.syncAction('RESTORE_AGREEMENT_DONE'),
  SetModal:     creator.syncAction('SET_IS_MODAL'),
  SetConfirm:   creator.syncActionWithPayload<ChangeDocumentStatePayload>('CHANGE_DOCUMENT_STATE'),
  SetDone:      creator.syncActionWithPayload<SetAgreementDonePayload>('SET_AGREEMENT_DONE'),
  StoreDone:    creator.syncAction('STORE_AGREEMENT'),
};
