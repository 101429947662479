import { call, delay, put, putResolve, select, spawn, takeEvery } from 'redux-saga/effects';

import { queryGetVerificationStatus, queryStartVerification } from '@/api/verification';
import { withErrorHandler } from '@/utils/redux/action-creator';
import { withGlobalLock } from '@/model/global/sagas';
import { UIState } from '@/model/global/types';

import { Action } from '@/model/actions';
import { VerificationStatus } from './types';
import { ApiError } from '@/model/ApiError';
import { Util } from '@/utils/util';
import { RootState } from '@/model/types';
import { Goal, Metrika } from '@/api/metrika';

const log = Util.getLog('verification/saga');
const updateStatusFastTimeout = 1000 * 30;
const updateStatusLongTimeout = 1000 * 60;
let waitStatusInProgress = false;

export const StartVerificationContext = {
  withAutoInn: false,
  again: false,
}

function* startVerification(): Generator {

  try {

    const status = (yield call(queryStartVerification)) as VerificationStatus;

    Metrika.reach(Goal.verification.Started);

    if(StartVerificationContext.withAutoInn) {
      Metrika.reach(Goal.verification.StartedWithAutoInn);
    }

    if(StartVerificationContext.again){
      Metrika.reach(Goal.verification.StartedAgain);
    }

    yield putResolve(Action.verification.SetStatus({ status }).pure);
    yield put(Action.global.Transmit({ state: UIState.VERIFICATION }).pure);

    if(status === VerificationStatus.InProgress){
      yield spawn(waitVerificationResult);
    }

  } catch (e) {

    Metrika.reach(Goal.err.VerificationStart);

    // verified for other account
    if(e.code === ApiError.verification.AlreadyVerifiedInOtherProfile
        || e.message === 'Upstream generic error 500.7.8'){
      Metrika.reach(Goal.err.AlreadyVerifiedByOther);
      yield putResolve(Action.verification.SetStatus({ status: VerificationStatus.AlreadyVerifiedInOtherProfile }).pure);
      yield put(Action.global.Transmit({ state: UIState.VERIFICATION }).pure);
    }
    // unknown error
    else {
      yield putResolve(Action.global.AddError({ messageId: 'verification.errors.start_failed' }).pure);
      yield put(Action.global.Transmit({ state: UIState.DOCUMENTS }).pure);
    }
  } finally {
    StartVerificationContext.withAutoInn = false;
    StartVerificationContext.again = false;
  }
}

function* waitVerificationResult(){

  if(waitStatusInProgress)
    return;

  log.info('start wait verification status...');
  waitStatusInProgress = true;

  const waitStatus = VerificationStatus.InProgress;
  let timeout = updateStatusFastTimeout;

  // wait loop
  while (true) {

    yield delay(timeout);

    try {

      const rootState = (yield select((state: RootState) => state)) as RootState;
      const curStatus = rootState.verification.status;
      if(curStatus !== waitStatus)
        break;

      const status = (yield call(queryGetVerificationStatus)) as VerificationStatus;
      yield putResolve(Action.verification.SetStatus({ status }).pure);

      if (status !== waitStatus)
        break;
    }
    catch (e){
      timeout = updateStatusLongTimeout;
      log.error('cannot get verification status', e);
    }
  }

  log.info('stop wait verification status.');
  waitStatusInProgress = false;
}

function* repeatVerification(): Generator {

  Metrika.reach(Goal.verification.NewTry);
  StartVerificationContext.again = true;

  yield put(Action.global.Transmit({ state: UIState.PROFILE }).pure);
}

export default function* rootSaga(): Generator {

  yield takeEvery(
    Action.verification.Start.type,
    withGlobalLock(
      withErrorHandler(
        startVerification
    )));

  yield takeEvery(Action.verification.Repeat.type,
    withGlobalLock(
      withErrorHandler(repeatVerification)));

  yield takeEvery(Action.verification.WaitStatus.type,
    withErrorHandler(
      waitVerificationResult
    ));
}
