import React, { ReactNode, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import { Label, Space } from '@/components';
import { StatusLayout } from '@/layout';
import { Action } from '@/model/actions';
import { RootState } from '@/model/types';
import { VerificationStatus } from '@/model/verification/types';
import { StubPartner } from '@/model/remote_state/types';
import { isFrameMode, postMessageToParent } from '@/hocs/withIFrameDetect';
import { Util } from '@/utils/util';
import { Config } from '@/config';

export const useStatusStyles = makeStyles((theme: Theme) => ({
  bodyBox: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    textAlign: 'center',
    alignItems: 'center',
  },
  bigDesc: {
    fontSize: '20px !important',
  },
  bigDesc2: {
    fontSize: '20px !important',
    color: '#141233',
    fontWeight: 500,
  }
}));

export const VerificationPage: React.FC = () => {
  const classes = useStatusStyles();
  const dispatch = useDispatch();

  const remoteData = useSelector((state: RootState) => state.remoteState.data);
  const status: VerificationStatus | undefined = useSelector((state: RootState) => state.verification.status);
  const partner = remoteData?.partner || StubPartner;
  const hasReturnUrl = ! isFrameMode() && ! Util.isBlank(partner.returnUrl);

  useEffect(()=>{
    if(status){
      postMessageToParent({type: 'status', value: status});
    }
  }, [status]);

  switch (status) {
    case VerificationStatus.AlreadyVerifiedInOtherProfile:
      return (
        <StatusLayout
          height={520}
          status="failed"
          submit={{
            title: <FormattedMessage id="verification.fail.repeat.title" />,
            onClick: (): Promise<void> => Action.verification.Repeat()(dispatch),
          }}
        >
          <Space direction="vertical" size={2} className={classes.bodyBox}>
            <Typography variant="h5" data-test="result">
              <FormattedMessage id="verification.fail.title" />
            </Typography>
            <Label>
              <FormattedMessage id="verification.fail.already_verified_in_other_profile_1" />
            </Label>
            <Label>
              <FormattedMessage id="verification.fail.already_verified_in_other_profile_2" />
            </Label>
            <Label>
              <FormattedMessage
                id="verification.fail.already_verified_in_other_profile_3"
                values={{
                  link: (content: string): ReactNode => <Link href={`mailto:${Config.SupportEmail}`}>{content}</Link>,
                }}
              />
            </Label>
          </Space>
        </StatusLayout>
      );
    case VerificationStatus.VerificationFailed:
      return (
        <StatusLayout
          height={520}
          status="failed"
          submit={{
            title: <FormattedMessage id="verification.fail.repeat.title" />,
            onClick: (): Promise<void> => Action.verification.Repeat()(dispatch),
          }}
        >
          <Space direction="vertical" size={2} className={classes.bodyBox}>
            <Typography variant="h5" data-test="result">
              <FormattedMessage id="verification.fail.title" />
            </Typography>
            <Label className={classes.bigDesc}>
              <FormattedMessage id="verification.fail.description" />
            </Label>
            <Label className={classes.bigDesc2}>
              <FormattedMessage id="verification.fail.details" />
            </Label>
          </Space>
        </StatusLayout>
      );
    case VerificationStatus.Verified:
      return (
        <StatusLayout
          status="success"
          height={250}
          {...(hasReturnUrl
            ? {
                submit: {
                  onClick: (): void => {
                    window.location.href = partner.returnUrl;
                  },
                  title: <FormattedMessage id="verification.success.submit.title" />,
                },
              }
            : {})}
        >
          <Space direction="vertical" size={2} className={classes.bodyBox}>
            <Typography variant="h5" data-test="result">
              <FormattedMessage id="verification.success.title" />
            </Typography>
            <Label>
              <FormattedMessage id="verification.success.description" />
            </Label>
          </Space>
        </StatusLayout>
      );
    case VerificationStatus.InProgress:
      return (
        <StatusLayout status="awaiting" height={200}>
          <Space direction="vertical" size={2} className={classes.bodyBox}>
            <Typography variant="h5" data-test="result">
              <FormattedMessage id="verification.progress.title" />
            </Typography>
            <Label>
              <FormattedMessage id="verification.progress.description" />
            </Label>
            <Label>
              <FormattedMessage id="verification.progress.details" />
            </Label>
          </Space>
        </StatusLayout>
      );
    default:
      return <></>;
  }
};

export default VerificationPage;
