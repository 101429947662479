import { ActionWithPayload } from '@/utils/redux/action-creator';

import { createReducer } from '@/utils/redux/reducer-util';
import { Action } from '@/model/actions';
import { initialState, ProfileState } from './types';
import { SaveValueActionPayload, ProfileValueType } from './actions';

export const reducers = createReducer<ProfileState>(initialState, {

  [Action.profile.UserInfoUpdated.type](
    state: ProfileState
  ){
    return {
      ...state,
      userInfoChanged: false
    }
  },

  [Action.profile.PassportUpdated.type](
    state: ProfileState
  ){
    return {
      ...state,
      passportChanged: false
    }
  },

  [Action.profile.Set.type](
    state: ProfileState,
    { payload: { value, type, hasError } }: ActionWithPayload<SaveValueActionPayload>,
  ): ProfileState {
    switch (type) {
      case ProfileValueType.FirstName:
        return { ...state, userInfoChanged: true, firstName: { value, hasError } };
      case ProfileValueType.MiddleName:
        return { ...state, userInfoChanged: true, middleName: { value, hasError } };
      case ProfileValueType.LastName:
        return { ...state, userInfoChanged: true, lastName: { value, hasError } };
      case ProfileValueType.BirthDay:
        return { ...state, userInfoChanged: true, birthDay: { value, hasError } };
      case ProfileValueType.Passport:
        return { ...state, passportChanged: true, passport: { value, hasError } };
      default:
        return state;
    }
  },

  [Action.profile.NeedMiddleName.type](
    state: ProfileState,
    { payload }: ActionWithPayload<boolean>
  ): ProfileState{
    return {
      ...state,
      needMiddleName: payload,
    }
  },
});

export default reducers;
