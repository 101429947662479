import * as React from 'react';
import { CSSProperties, FocusEvent, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import MaskedInput from 'react-text-mask';
import { Phone } from '@/model/Phone';
import { TextFieldInput } from '@/components';
import { Util } from '@/utils/util';
import { onMaskFocus } from '@/components/mask-util';
import { DigitsKeyboardAttrs } from '@/components/mobile-util';

interface PhoneMaskInputProps {
  inputRef?: (ref: HTMLElement | null) => void;
}

export const phoneMask = [
  '+',
  '7',
  ' ',
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
];

const PhoneMaskInput: React.FC<PhoneMaskInputProps> = (
  {
    inputRef,
    ...other
  }: PhoneMaskInputProps) => {

  let updatedPasteValue: string | null;

  const handleFocus = (event: FocusEvent<HTMLInputElement>) => {

    // for better input
    onMaskFocus(event);

    const {onFocus} = (other as any);
    if(onFocus)
      onFocus(event);
  }

  return (
    <MaskedInput
      {...other}
      ref={(ref: MaskedInput): void => {
        if (inputRef) {
          inputRef(ref ? ref.inputElement : null);
        }
      }}
      onPaste={(event) => {
        const value = event.clipboardData.getData('text');
        const phone = Phone.normalizePhone(value);
        updatedPasteValue = Phone.isStandardPhoneSize(phone) ? phone : null;
      }}
      pipe={(conformedValue) => {
        return updatedPasteValue || conformedValue;
      }}
      onFocus={handleFocus}
      mask={phoneMask}
      placeholderChar="_"
      showMask
    />
  );
};

type PhoneInputProps = {
  value?: string;
  hasError?: boolean;
  setFocus?: boolean;
  style?: CSSProperties;
  onChange: (value: string) => Promise<void> | void;
};

const PhoneInput: React.FC<PhoneInputProps> = (
  {
    value: inputValue,
    onChange,
    hasError,
    setFocus,
    ...other
  }: PhoneInputProps
) => {

  hasError = !!hasError;

  const [canShowError, setCanShowError] = useState(false);
  const [focusReturned, setFocusReturned] = useState(false);
  const [valueChanged, setValueChanged] = useState(false);

  // remove first 7 (added by masked input itself)
  const initialValue = inputValue && inputValue.length > 0 ? inputValue?.substring(1) : inputValue;
  const valueWasReset = ! Util.isEmpty(inputValue) && initialValue?.length === 0;
  const showError = canShowError && !valueWasReset && hasError;

  // for hide error if user deleted all input data
  useEffect(()=>{
    if(valueWasReset || !hasError) {
      setCanShowError(false);
    }
  }, [valueWasReset, hasError]);

  // for hide error if user start update input
  useEffect(()=>{
    if(focusReturned && valueChanged){
      setFocusReturned(false);
      setValueChanged(false);
      setCanShowError(false);
    }
  }, [focusReturned, valueChanged]);

  const handleChange = (maskedPhone: string): void => {

    if ( ! onChange)
      return;

    const phone = maskedPhone.replace(/[^\d]/g, ''); // exclude all non digits
    if (inputValue !== phone) {
      onChange(phone);
      setValueChanged(true);
    }
  };

  const handleBlur = () =>{
    setCanShowError(true);
    setValueChanged(false);
  }

  const handleFocus = () =>{
    setFocusReturned(true);
  }


  return (
    <TextFieldInput
      InputLabelProps={{ shrink: true }}
      InputAttrs={DigitsKeyboardAttrs}
      setFocus={setFocus}
      focusOnStart={!initialValue || initialValue.length === 0}
      inputComponent={PhoneMaskInput}
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={handleFocus}
      // PhoneMaskInput expect same pattern as his phoneMask (with +7 in begging) or he will filter input "97" to "9"
      value={{ value: initialValue? `+7${initialValue}` : initialValue, hasError: showError }}
      messages={{
        error: <FormattedMessage id="welcome.phone.invalid" />,
      }}
    />
  );
};

export default PhoneInput;
