import * as React from 'react';
import { Provider } from 'react-redux';

import { rootStore } from './configureStore';

const withStore = (Component: React.FunctionComponent) => (props: { [k in string]: unknown }): JSX.Element => (
  <Provider store={rootStore}>
    <Component {...props} />
  </Provider>
);

export default withStore;
