import React, { useEffect } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { isFrameMode } from '@/hocs/withIFrameDetect';
import { useSelector } from 'react-redux';
import { RootState } from '@/model/types';

const withMobileDetect = (Component: React.FC) => (props: any) => {

  const smallWidth = useMediaQuery('(max-width:460px)');
  const smallHeight = useMediaQuery('(max-height:600px)');

  // wait paramsLoaded for "frameEmulation" support
  const paramsLoaded = useSelector((state: RootState) => state.params.loaded);

  useEffect(()=>{

    // in frame use small-screen view
    const inFrame = isFrameMode();

    if(smallWidth || smallHeight || inFrame){
      document.body.classList.add('small-screen');
    } else {
      document.body.classList.remove('small-screen');
    }
  }, [smallWidth, smallHeight, paramsLoaded]);

  return (
    <>
      <Component {...props} />
    </>
  );
}

export default withMobileDetect;