
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AppParams extends Record<ParamKey, any> {}

export type ParamKey =
  'loaded'
  | 'token'
  | 'frame-mode'
  | 'skip-inn'
  | 'warn-inn'
  | 'prod-like'
  | 't-main-color'
  | 't-back-color'
  | 't-font';


export const initialState: AppParams = {
  loaded: false,
} as any;