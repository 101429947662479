import { createReducer } from '@/utils/redux/reducer-util';
import { ActionWithPayload } from '@/utils/redux/action-creator';
import { Action } from '@/model/actions';
import { initialState, MobileIdState, MobileIdStatus } from './types';
import { MobileIdConfig, PhoneOperator } from '@/model/Phone';
import { Value } from '@/model/common';

export const reducers = createReducer<MobileIdState>(initialState, {

  [Action.mobileId.SetOperator.type](state: MobileIdState, {payload}: ActionWithPayload<PhoneOperator>): MobileIdState {
    return {
      ...state,
      operator: payload
    };
  },

  [Action.mobileId.SetMobileIdConfig.type](state: MobileIdState, {payload}: ActionWithPayload<MobileIdConfig>): MobileIdState {
    return {
      ...state,
      config: payload
    }
  },

  [Action.mobileId.SetStatus.type](state: MobileIdState, {payload}: ActionWithPayload<MobileIdStatus>): MobileIdState {
    return {
      ...state,
      status: payload
    };
  },

  [Action.mobileId.SetReqId.type](state: MobileIdState, {payload}: ActionWithPayload<string|undefined>): MobileIdState {
    return {
      ...state,
      reqId: payload
    };
  },

  [Action.mobileId.SetBirthdayForCheck.type](
    state: MobileIdState,
    {payload}: ActionWithPayload<Value<string>|undefined>
  ): MobileIdState {
    return {
      ...state,
      birthDayForCheck: payload
    };
  }
});

export default reducers;
