import { actionCreator } from '@/utils/redux/action-creator';
import { Value } from '@/model/common';


export enum ProfileValueType {
  FirstName = 'FirstName',
  LastName = 'LastName',
  MiddleName = 'MiddleName',
  BirthDay = 'BirthDay',
  Passport = 'Passport',
}

export type SaveValueActionPayload = Value<string> & { type: ProfileValueType };


const creator = actionCreator('PROFILE');

export const ProfileAction = {
  Set:              creator.asyncActionWithPayload<SaveValueActionPayload, void>('SET_VALUE'),
  Save:             creator.asyncAction<void>('SAVE'),
  UserInfoUpdated:  creator.syncAction('USER_INFO_UPDATED'),
  PassportUpdated:  creator.syncAction('PASSPORT_UPDATED'),
  NeedMiddleName:   creator.syncActionWithPayload<boolean>('NEED_MIDDLE_NAME'),
};
