import React, { ReactNode } from 'react';
import { StatusLayout } from '@/layout';
import { FormattedMessage } from 'react-intl';
import { Link, Typography } from '@material-ui/core';
import { Label, Space } from '@/components';
import { useStatusStyles } from '@/pages/VerificationPage';
import { Config } from '@/config';

export const ServiceMaintenancePage: React.FC = () => {

  const classes = useStatusStyles();

  return (
    <StatusLayout
      height={320}
      status="failed"
    >
      <Space direction="vertical" size={2} className={classes.bodyBox}>
        <Typography variant="h5" data-test="result">
          <FormattedMessage id="service_maintenance.title" />
        </Typography>
        <Label>
          <FormattedMessage id="service_maintenance.description" />
        </Label>
        <Label>
          <FormattedMessage
            id="service_maintenance.description2"
            values={{
              link: (content: string): ReactNode => <Link href={`mailto:${Config.SupportEmail}`}>{content}</Link>,
            }}
          />
        </Label>
      </Space>
    </StatusLayout>
  );
};

export default ServiceMaintenancePage;