import React, { createRef, ReactNode, useEffect } from 'react';
import { InputLabelProps as InputLabelPropsType, TextField } from '@material-ui/core';
import { InputProps as StandardInputProps } from '@material-ui/core/Input/Input';
import { InputBaseComponentProps } from '@material-ui/core/InputBase/InputBase';

import { Value } from '@/model/common';
import clsx from 'clsx';

export type TextFieldInputProps = {
  InputProps?: Partial<Omit<StandardInputProps, 'disableUnderline' | 'inputComponent'>>;
  InputAttrs?: any;
  InputLabelProps?: Partial<InputLabelPropsType>;
  inputComponent?: React.ElementType<InputBaseComponentProps>;
  value?: Value<string>;
  onChange?: (value: string) => Promise<void> | void;
  onBlur?: () => Promise<void> | void;
  onFocus?: () => Promise<void> | void;
  setFocus?: boolean,
  focusOnStart?: boolean,
  messages?: {
    helperText?: ReactNode;
    label?: ReactNode;
    error?: ReactNode;
  };
  disabled?: boolean;
};

const TextFieldInput: React.FC<TextFieldInputProps> = ({
  InputProps,
  InputAttrs,
  InputLabelProps,
  inputComponent,
  value,
  onBlur,
  onFocus,
  onChange,
  messages,
  setFocus,
  focusOnStart,
  disabled,
}: TextFieldInputProps) => {

  const ref = createRef<any>();

  useEffect(()=>{
    if(setFocus){
      ref.current?.focus();
      if(focusOnStart)
        ref.current?.setSelectionRange(0, 0);
    }
  }, [setFocus, focusOnStart, ref]);

  useEffect(()=>{
    if(InputAttrs && ref.current){
      Object.keys(InputAttrs).forEach(key => {
        ref.current.setAttribute(key, InputAttrs[key]);
      });
    }
  }, [InputAttrs, ref])

  return (<TextField
    style={{flexShrink: 0}}
    disabled={disabled}
    inputRef={ref}
    InputLabelProps={InputLabelProps}
    variant={messages?.label ? 'filled' : 'standard'}
    inputProps={{className: clsx('ym-disable-keys')}}
    InputProps={{
      disableUnderline: true,
      ...(inputComponent ? { inputComponent } : {}),
      ...InputProps,
    }}
    label={messages?.label}
    value={value?.value || ''}
    onBlur={onBlur}
    onFocus={onFocus}
    onChange={async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
      if (!onChange) {
        return;
      }
      const inputValue = event.target.value;
      if (inputValue !== value?.value) {
        await onChange(inputValue);
      }
    }}
    {...(value?.hasError ? { helperText: messages?.error, error: true } : { helperText: messages?.helperText })}
  />);
};

export default TextFieldInput;
