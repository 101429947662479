import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Link } from '@material-ui/core';
import { DocumentLayout } from '@/layout';
import { documentByPath, documentTypeMessageMapping } from '@/pages/AgreementDocumentPage/types';
import { Action } from '@/model/actions';
import { RootState } from '@/model/types';

export const AgreementDocumentPage: React.FC<RouteComponentProps> = ({ match, history }: RouteComponentProps) => {
  const dispatch = useDispatch();
  const agreement = useSelector((state: RootState) => state.agreement);

  const document = documentByPath(match.url);
  if (!document) {
    history.goBack();
    return <></>;
  }

  return (
    <DocumentLayout
      title={<FormattedMessage id={`${documentTypeMessageMapping[document]}.title`} />}
      close={agreement.isModal}
      text={{
        id: `${documentTypeMessageMapping[document]}.content`,
        extraValues: {
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          linkToOwnName: (content: string): ReactNode => <Link href="#">{content}</Link>,
        },
      }}
      submit={agreement.isModal
        ? {
          onClick: async (): Promise<void> => {
            await Action.agreement.SetConfirm({ document, confirmed: true })(dispatch);
            history.goBack();
          },
          title: <FormattedMessage id="registration.documents.submit.title" />,
        }
        : undefined
      }
    />
  );
};

export default withRouter(AgreementDocumentPage);
