import React from 'react';

import { MainLayout } from '@/layout';
import { FormattedMessage } from 'react-intl';
import { Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { getInputOrRestoredPhone, RootState } from '@/model/types';
import { conformToMask } from 'react-text-mask';
import { phoneMask } from '@/components/PhoneInput';
import { useSelector } from 'react-redux';
import { PhoneOperator } from '@/model/Phone';

const useStyles = makeStyles((theme: Theme) => ({
  boxClassname: {
    backgroundColor: theme.activeElementBackground
  }
}));

export function MobileIdFailed(){

  const classes = useStyles();

  const rootState = useSelector((state: RootState) => state);
  const operator = rootState.mobileId.operator || PhoneOperator.Unknown;
  const phone = getInputOrRestoredPhone(rootState) || '';
  const formattedPhone = conformToMask(phone.substring(1), phoneMask, {}).conformedValue;

  return (
    <MainLayout
      boxClassname={classes.boxClassname}
      height={590}
      title={<FormattedMessage id="welcome.title"/>}
    >

      <Typography variant="body1">
        <FormattedMessage
          id="mobile_id.by_phone.cannot_handle_phone"
          values={{ phone: <strong style={{whiteSpace: 'nowrap'}}>{formattedPhone}</strong>, operator }}
        />
      </Typography>

    </MainLayout>
  );
}
