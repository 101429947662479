import React, { PropsWithChildren, ReactNode } from 'react';

import { Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import { BaseContainer, HeaderBox } from '@/layout/components';
import { BaseContainerProps } from '@/layout/components/BaseContainer';
import { HeaderBoxProps } from '@/layout/components/HeaderBox';
import { FormattedMessageWithMarkup, Space } from '@/components';
import clsx from 'clsx';
import { getThemeValues } from '@/hocs/withTheme';

const useStyles = makeStyles((theme: Theme) => {

  const values = getThemeValues();

  const button = {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderRadius: '12px',
    fontStyle: 'normal',
    padding: 0,
    fontSize: '18px',
    lineHeight: `${theme.spacing(7)}px`,
  };

  return {
    header: {
      paddingTop: theme.spacing(),
      marginBottom: theme.spacing(3),
    },
    mainBoxContainer: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      justifySelf: 'center',
      flexDirection: 'column',
    },
    mainContainer: theme.MainContainer,
    mainBox: {
      flexGrow: 1,
      width: theme.MainContainer.width,
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column',
      overflowX: 'auto',
    },
    buttonContainer: {
      display: 'flex',
      flex: 'auto',
      flexShrink: 0,
    },
    buttonEnabled: {
      ...button,
      alignSelf: 'flex-end',
      background: `${values.mainColor} !important`,
      borderColor: `${values.mainColor} !important`,
      color: `${theme.palette.secondary.contrastText}`,
    },
  };
});

export type DocumentLayoutProps = HeaderBoxProps &
  Omit<BaseContainerProps, 'children'> &
  PropsWithChildren<{
    title: ReactNode;
    text: {
      id: string;
      extraValues: Record<string, string | ReactNode>;
    };
    close?: { onClick?: () => void | Promise<void> } | boolean;
    submit?: {
      title: ReactNode;
      onClick: () => void | Promise<void>;
    };
  }>;

export const DocumentLayout: React.FC<DocumentLayoutProps> = ({
  title,
  text,
  close,
  submit,
  buttons,
  ...props
}: DocumentLayoutProps) => {
  const classes = useStyles();

  return (
    <BaseContainer {...props}>
      <HeaderBox title={title} buttons={buttons} />
      <Box className={clsx(classes.mainBox, 'x-text')}>
        <Space direction="vertical" size={3} style={{ overflow: 'auto', flexShrink: 0 }}>
          <FormattedMessageWithMarkup id={text.id} values={text.extraValues} />
        </Space>
        {submit && (
          <span className={classes.buttonContainer}>
            <Button className={classes.buttonEnabled} onClick={submit.onClick} variant="outlined" color="primary">
              {submit.title}
            </Button>
          </span>
        )}
      </Box>
    </BaseContainer>
  );
};

export default DocumentLayout;
