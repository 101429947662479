import { ActionWithPayload } from '@/utils/redux/action-creator';

import { createReducer } from '@/utils/redux/reducer-util';
import { Action } from '@/model/actions';
import { initialState, State } from './types';
import {
  RemoveErrorActionPayload,
  SaveErrorActionPayload,
  SavePhoneActionPayload,
  SetLoadingActionPayload,
} from './actions';
import { LangType } from '@/model/Lang';

export const reducers = createReducer<State>(initialState, {
  [Action.global.support.SetValidatedPhone.type](
    state: State, { payload: { phone, hasError } }: ActionWithPayload<SavePhoneActionPayload>,
  ): State {
    return { ...state, phone: { value: phone, hasError } };
  },
  [Action.global.SetLoading.type](
    state: State, { payload }: ActionWithPayload<SetLoadingActionPayload>
  ): State {
    return { ...state, loading: payload.loading };
  },
  [Action.global.support.SetError.type](
    state: State, { payload }: ActionWithPayload<SaveErrorActionPayload>
  ): State {
    return { ...state, errors: [payload, ...state.errors] };
  },
  [Action.global.support.RemoveError.type](
    state: State, { payload: { id } }: ActionWithPayload<RemoveErrorActionPayload>
  ): State {
    return { ...state, errors: [...state.errors.filter(({ id: saved }) => saved !== id)] };
  },
  [Action.global.CleanErrors.type](
    state: State
  ): State {
    return { ...state, errors: [] };
  },
  [Action.global.SetLang.type](
    state: State, { payload }: ActionWithPayload<LangType>
  ): State {
    return { ...state, lang: payload };
  },
  [Action.global.SetInvalidSession.type](
    state: State, { payload }: ActionWithPayload<boolean>
  ): State {
    return { ...state, invalidSession: payload };
  }
});

export default reducers;
