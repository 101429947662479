import React, { ReactNode, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { Checkbox, FormControlLabel, Link, Typography, useTheme } from '@material-ui/core';

import { PhoneInput } from '@/components';
import { MainLayout } from '@/layout';
import { RootState } from '@/model/types';
import { documentPath, documentTypeMessageMapping } from '@/pages/AgreementDocumentPage/types';
import { Document } from '@/model/agreement/types';
import { Action } from '@/model/actions';
import { ModalQueryFlag } from '@/model/Modal';
import { Config } from '@/config';
import AboutProviderLabel from '@/components/AboutProviderLabel';
import { Goal, Metrika } from '@/api/metrika';

export const RegistrationPage: React.FC = () => {
  const classes = useTheme();
  const dispatch = useDispatch();
  const phone = useSelector((state: RootState) => state.global.phone);
  const agreement = useSelector((state: RootState) => state.agreement);
  const [firstShow, setFirstShow] = useState(true);
  const showAgreement = ! Config.DefaultAgreement;

  useEffect(() => {
    if (!agreement.isModal)
      Action.agreement.SetModal()(dispatch);
  }, [agreement, dispatch]);

  useEffect(()=>{
    if(firstShow){
      setFirstShow(false);
    }
  }, [firstShow, setFirstShow]);

  useEffect(()=>{
    if(phone?.value && ! phone?.hasError) {
      Metrika.reachOnce(Goal.phone.Entered);
    }
  }, [phone]);

  return (
    <MainLayout
      title={<FormattedMessage id="welcome.title" />}
      height={438}
      submit={{
        onClick: async (): Promise<void> => {
          await Action.auth.Registration()(dispatch);
        },
        disabled:
          // prettier: FIXME: https://github.com/prettier/prettier/pull/7111
          // prettier-ignore
          !phone?.value
          || phone.value.length === 0
          || phone.hasError
          || !agreement.userAgreement,
        title: <FormattedMessage id="registration.submit.title" />,
      }}
      footer={{
        beforeSubmit: (
          <>
            { showAgreement &&
              <FormControlLabel
                style={{ marginBottom: '9px' }}
                control={
                  <Checkbox
                    checked={agreement.userAgreement}
                    onChange={(_: React.ChangeEvent<HTMLInputElement>, confirmed: boolean): void =>
                      Action.agreement.SetConfirm({ confirmed, document: Document.USER_AGREEMENT })(dispatch)
                    }
                  />
                }
                label={
                  <FormattedMessage
                    id={`${documentTypeMessageMapping[Document.USER_AGREEMENT]}.link`}
                    values={{
                      link: (content: string): ReactNode => (
                        <Link component={RouterLink} to={`${documentPath(Document.USER_AGREEMENT)}${ModalQueryFlag}`}>
                          {content}
                        </Link>
                      ),
                    }}
                  />
                }
              />
            }
            { ! showAgreement && <AboutProviderLabel/> }
          </>
        )
      }}
    >
      <Typography variant="body1" style={{ ...classes.formLayout.baseElement }}>
        <FormattedMessage id="registration.description" />
      </Typography>
      <PhoneInput
        setFocus={firstShow}
        value={phone?.value}
        hasError={phone?.hasError}
        onChange={(newPhone: string) => Action.global.SetPhone({ phone: newPhone })(dispatch)}
        style={{ ...classes.formLayout.baseElement }}
      />
    </MainLayout>
  );
};

export default withRouter(RegistrationPage);
