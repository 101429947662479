import 'fontsource-roboto';

import React from 'react';
import { Route, RouteComponentProps, Router, Switch, withRouter } from 'react-router-dom';

import { history } from '@/hocs/withStore/configureStore';
import {
  withAppParams,
  withGlobalBackdrop,
  withGlobalErrorHandler,
  withI18n,
  withI18nSupport,
  withIFrameDetect,
  withInvalidSessionMessage,
  withMobileDetect,
  withProfileInit,
  withStore,
  withTheme,
} from '@/hocs';
import {
  AgreementDocumentPage,
  AgreementOnlyPage,
  ConfirmationPage,
  DocumentsPage,
  MobileIdPage,
  ProfilePage,
  RegistrationPage,
  VerificationPage,
} from '@/pages';
import { UIState } from '@/model/global/types';
import { Util } from '@/utils/util';

const log = Util.getLog('App');

export const AppPath = {
  Agreement: `/${UIState.AGREEMENT.toLowerCase()}`,
  Login: `/${UIState.LOGIN.toLowerCase()}`,
  Registration: `/${UIState.REGISTRATION.toLowerCase()}`,
  Confirmation: `/${UIState.CONFIRMATION.toLowerCase()}`,
  MobileId: `/${UIState.MOBILE_ID.toLowerCase()}`,
  Profile: `/${UIState.PROFILE.toLowerCase()}`,
  Documents: `/${UIState.DOCUMENTS.toLowerCase()}`,
  Verification: `/${UIState.VERIFICATION.toLowerCase()}`,
};

export const DefaultAppPath = AppPath.Registration;

const DefaultRoute: React.FC<RouteComponentProps> = ({ location }: RouteComponentProps) => {
  log.warn(`URL not found: ${location.pathname}`);
  return (<></>);
};

const Routes: React.FC = () => (
  <div>
    <Switch>
      <Route exact path={AppPath.Agreement} component={AgreementOnlyPage} />
      <Route exact path={`${AppPath.Agreement}/*`} component={AgreementDocumentPage} />
      <Route exact path={AppPath.Registration} component={RegistrationPage} />
      <Route exact path={AppPath.Confirmation} component={ConfirmationPage} />
      <Route exact path={AppPath.MobileId} component={MobileIdPage} />
      <Route exact path={AppPath.Profile} component={ProfilePage} />
      <Route exact path={AppPath.Documents} component={DocumentsPage} />
      <Route exact path={AppPath.Verification} component={VerificationPage} />
      <Route component={withRouter(DefaultRoute)} />
    </Switch>
  </div>
);

const App: React.FC = () => {
  return (
    <Router history={history}>
      <Routes />
    </Router>);
}

export default
  withStore(
    withMobileDetect(
      withIFrameDetect(
        withAppParams(
          withI18nSupport(
            withI18n(
              withTheme(
                withGlobalBackdrop(
                  withGlobalErrorHandler(
                    withProfileInit(
                      withInvalidSessionMessage(
                        App
                      )))))))))));
