import { call, put, putResolve, select, takeEvery } from 'redux-saga/effects';

import { withGlobalLock } from '@/model/global/sagas';
import { withErrorHandler } from '@/utils/redux/action-creator';
import { queryGetInitState, queryPreloadImage } from '@/api/init_state';
import {
  FrontConfig,
  isEmptyRemoteField,
  RemotePassport,
  RemoteProfile,
  RemoteStateData,
  RemoteStateStatus,
  WorkMode,
} from '@/model/remote_state/types';
import { Util } from '@/utils/util';
import { Action } from '@/model/actions';
import { ProfileValueType } from '@/model/profile/actions';
import { ExtraDocValueType } from '@/model/documents/actions';
import { VerificationStatus } from '@/model/verification/types';
import { MobileIdConfig, PhoneOperator } from '@/model/Phone';
import { DefLang } from '@/model/Lang';
import { RootState } from '@/model/types';
import { clearSessionToken } from '@/utils/axios';
import { Config } from '@/config';
import { Metrika } from '@/api/metrika';

const log = Util.getLog('remote_state.sagas');

function* loadState() {
  try {

    yield reloadState();

  } catch (e) {
    log.error('cannot loadState', e);
    yield putResolve(Action.remoteState.Set({status: RemoteStateStatus.Error, data: undefined}).pure);
  }

}


function* reloadState() {

  const rootState = (yield select((state: RootState) => state)) as RootState;
  const lang = rootState.global.lang || DefLang;
  const authToken = rootState.params.token;
  const jwtMode = true;

  // new session
  if(authToken)
    clearSessionToken();

  const data: RemoteStateData = yield call(queryGetInitState, lang, authToken, jwtMode);

  Metrika.userParams({
    userId: data.userId,
    unitId: data.profile?.profileId,
    partner: data.partner?.name,
  });

  yield call(queryPreloadImage, data.partner?.iconUrl);

  const {isPersonified, workMode} = data;

  // isPersonified flag is the only matter for Full mode
  if(workMode === WorkMode.Full){
    log.info('update verification status to NotVerified');
    data.verification = VerificationStatus.NotVerified;
  }

  // isPersonified means the end of all actions
  if(isPersonified) {
    log.info('update verification status to Verified');
    data.verification = VerificationStatus.Verified;
  }

  handleConfig(data.config);

  yield restoreProfile(data.profile);
  yield restorePassport(data.passport);
  yield restoreExtraDocs(data.profile);
  yield restoreVerification(data.verification);
  yield restoreMobileIdOperator(data.mobileIdOperator, data.mobileIdConfig);

  yield putResolve(Action.remoteState.Set({status: RemoteStateStatus.Valid, data}).pure);
}


function handleConfig(config?: FrontConfig){

  if(!config)
    return;

  Config.SkipInn = config.skipInn;
  Config.InServiceMaintenance = config.inServiceMaintenance;
}


function* restoreProfile(profile?: RemoteProfile) {

  if( ! profile)
    return;

  let updated = false;

  if( ! isEmptyRemoteField(profile.firstName)) {
    yield put(Action.profile.Set({
      type: ProfileValueType.FirstName,
      value: profile.firstName,
      hasError: false
    }).pure);
    updated = true;
  }

  if( ! isEmptyRemoteField(profile.lastName)) {
    yield put(Action.profile.Set({
      type: ProfileValueType.LastName,
      value: profile.lastName,
      hasError: false
    }).pure);
    updated = true;
  }

  if( ! isEmptyRemoteField(profile.middleName)) {
    yield put(Action.profile.Set({
      type: ProfileValueType.MiddleName,
      value: profile.middleName,
      hasError: false
    }).pure);
    updated = true;
  }

  if(profile.birthDay) {
    yield put(Action.profile.Set({
      type: ProfileValueType.BirthDay,
      value: profile.birthDay,
      hasError: false
    }).pure);
    updated = true;
  }

  if(updated){
    yield put(Action.profile.UserInfoUpdated().pure);
  }
}

function* restorePassport(passport?: RemotePassport) {

  if( ! passport)
    return;

  const {series, num} = passport;

  if(series && num) {

    yield put(Action.profile.Set({
      type: ProfileValueType.Passport,
      value: `${series}${num}`,
      hasError: false
    }).pure);

    yield put(Action.profile.PassportUpdated().pure);
  }
}

function* restoreExtraDocs(profile?: RemoteProfile) {

  if( ! profile)
    return;

  let updated = false;

  if(profile.taxCode){
    yield put(Action.document.Set({
      selected: true,
      type: ExtraDocValueType.TAX_ID,
      value: profile.taxCode,
      hasError: false
    }).pure);
    updated = true;
  }

  if(profile.insuranceCode){
    yield put(Action.document.Set({
      selected: true,
      type: ExtraDocValueType.SSN,
      value: profile.insuranceCode,
      hasError: false
    }).pure);
    updated = true;
  }

  if(updated){
    yield put(Action.document.ValuesUpdated().pure);
  }
}


function* restoreVerification(status?: VerificationStatus) {

  yield put(Action.verification.SetStatus({status: status || VerificationStatus.NotVerified}).pure);

  if(status === VerificationStatus.InProgress)
    yield putResolve(Action.verification.WaitStatus().pure);

}

function* restoreMobileIdOperator(
  operator?: PhoneOperator,
  mobileIdConfig?: MobileIdConfig
) {

  yield put(Action.mobileId.SetOperator(operator || PhoneOperator.Unknown).pure);

  if(mobileIdConfig){
    yield put(Action.mobileId.SetMobileIdConfig(mobileIdConfig).pure);
  }
}


export default function* rootSaga() {

  yield takeEvery(
    Action.remoteState.Load.type,
    withGlobalLock(
      withErrorHandler(
        loadState
      )));

  yield takeEvery(
    Action.remoteState.Reload.type,
    withGlobalLock(
      withErrorHandler(
        reloadState
      )));
}