import { actionCreator } from '@/utils/redux/action-creator';
import { GlobalError, GlobalIdentifiedError, UIState } from './types';
import { LangType } from '@/model/Lang';

export type AddErrorActionPayload = GlobalError;
export type TransmitToActionPayload = { state: UIState; replaceHistory?: boolean };
export type SetPhoneActionPayload = { phone: string };
export type SetLoadingActionPayload = { loading: boolean };

export type SaveErrorActionPayload = GlobalIdentifiedError;
export type RemoveErrorActionPayload = { id: string };
export type SavePhoneActionPayload = { phone: string; hasError: boolean };


const creator = actionCreator('GLOBAL');

export const GlobalAction = {

  AddError:     creator.asyncActionWithPayload<AddErrorActionPayload, void>('ADD_ERROR'),
  CleanErrors:  creator.syncAction('CLEAN_ERROR'),
  Transmit:     creator.asyncActionWithPayload<TransmitToActionPayload, void>('TRANSMIT_TO'),
  SetPhone:     creator.asyncActionWithPayload<SetPhoneActionPayload, void>('SET_PHONE'),
  SetLoading:   creator.syncActionWithPayload<SetLoadingActionPayload>('SET_LOADING'),
  SetLang:      creator.syncActionWithPayload<LangType>('SET_LANG'),
  SetInvalidSession: creator.syncActionWithPayload<boolean>('SET_INVALID_SESSION'),

  support: {
    SetError:           creator.syncActionWithPayload<SaveErrorActionPayload>('SAVE_ERROR'),
    RemoveError:        creator.syncActionWithPayload<RemoveErrorActionPayload>('REMOVE_ERROR'),
    SetValidatedPhone:  creator.syncActionWithPayload<SavePhoneActionPayload>('SAVE_PHONE')
  }
};

