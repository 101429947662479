import { actionCreator } from '@/utils/redux/action-creator';

import { ParamKey } from './types';

export type SetParamPayload = {
  key: ParamKey,
  value: any,
}

const creator = actionCreator('APP_PARAMS');

export const ParamsAction = {
  SetAll:   creator.syncActionWithPayload<Record<string, any>>('SET_ALL_PARAMS'),
  SetParam: creator.syncActionWithPayload<SetParamPayload>('SET_PARAM'),
};
