import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/model/types';
import { RemoteStateStatus } from '@/model/remote_state/types';
import { Util } from '@/utils/util';
import { Config } from '@/config';

const inFrame = window.self !== window.top;

// acting like in frame by url param: frame-mode=true
let frameEmulation = false;

function isDevHost(host:string): boolean{
  return host === 'localhost'
    || host === 'mnxsc.tech'
    || host.endsWith('.mnxsc.tech');
}

function isWhiteHost(host:string, allowedDomains:string|undefined): boolean{
  if(!allowedDomains || Util.isBlank(allowedDomains))
    return false;

  const parsedDomains = allowedDomains.split(',')
    .map(val => val.trim())
    .filter(val => val.length > 0);

  return parsedDomains.includes(host);
}

export const isFrameMode = ()=>{
  return inFrame || frameEmulation;
}

export type MsgToParentType = 'status';

export interface MsgToParent {
  type: MsgToParentType,
  value?: any,
}

export const postMessageToParent = (msg: MsgToParent)=>{
  if(inFrame && window.parent){
    window.parent.postMessage(msg, '*');
  }
}

const withIFrameDetect = (Component: React.FC) => (props: any) => {

  const params = useSelector((state: RootState) => state.params);
  const status = useSelector((state: RootState) => state.remoteState.status);
  const returnUrl = useSelector((state: RootState) => state.remoteState.data?.partner?.returnUrl);
  const allowedDomains = useSelector((state: RootState) => state.remoteState.data?.partner?.allowedDomains);

  const paramsLoaded = params.loaded;
  const likeInFrame = Util.parseBool(params['frame-mode'], false);
  const likeInProd = Util.parseBool(params['prod-like'], false);


  useEffect(()=>{
    if(paramsLoaded && likeInFrame){
      frameEmulation = true;
    }
  }, [paramsLoaded, likeInFrame]);

  useEffect(()=>{
    if(inFrame || likeInFrame){
      document.body.classList.add('in-frame');
    } else {
      document.body.classList.remove('in-frame');
    }
  }, [likeInFrame]);


  // check iframe for valid use
  if(inFrame
      && returnUrl
      && status === RemoteStateStatus.Valid){

    const parentRef = document.referrer;
    const parentHost = parentRef? new URL(parentRef).hostname : '';
    const validHost = new URL(returnUrl).hostname;
    const checkParent = Config.CheckIframeParent || likeInProd;

    // iframe used in wrong site
    if(checkParent
        && parentHost !== validHost
        && !isWhiteHost(parentHost, allowedDomains)
        && !isDevHost(parentHost)){
      return (
        <>
          <div
            style={{
              backgroundColor: 'white',
              color: 'black',
              padding: '10px',
              fontFamily: 'arial'
            }}>
            <p>Invalid parent host: {parentHost}</p>
            <p>Expected host: {validHost}</p>
          </div>
        </>
      );
    }
  }

  return (
    <>
      <Component {...props} />
    </>
  );
}


export default withIFrameDetect;