import { Util } from '@/utils/util';

// from index.html
const {ym, METRIKA_ID} = window as any;

const use = !!(ym && METRIKA_ID);
const id = METRIKA_ID;
const log = Util.getLog('YandexMetrika');
const onceGoals: any = {};

export const Goal = {
  err: {
    Network:            'err_network',
    Server:             'err_server',
    BadData:            'err_bad_data',
    Access:             'err_access',
    InvalidToken:       'err_invalid_token',
    GetInn:             'err_get_inn_by_passport',
    ProfileUpdate:      'err_profile_update',
    VerificationStart:  'err_verification_start',
    DocumentUpdate:     'err_update_document',
    Maintenance:        'err_maintenance',
    AlreadyVerifiedByOther: 'err_already_verified_by_other',
    InvalidSession:     'err_invalid_session',
    MobileIdStart:      'error_mobile_id_start',
    InvalidBirthdateForMobileId: 'error_invalid_birthdate_for_mobile_id',
    GetUserInfoFromMobileId: 'error_get_user_info_from_mobile_id',
    StartUpridNotPersonification: 'error_start_uprid_not_personification',
    CannotStartUpridAndPersonification: 'error_cannot_start_uprid_and_personification',
  },
  verification: {
    Started:            'verification_started',
    StartedWithAutoInn: 'verification_started_with_auto_inn',
    StartedAgain:       'verification_started_again',
    NewTry:             'verification_new_try',
  },
  profile: {
    InvalidData:        'profile_cannot_update_invalid_data',
    Updated:            'profile_updated',
    NameUpdated:        'profile_name_updated',
    LastNameUpdated:    'profile_lastname_updated',
    MiddleNameUpdated:  'profile_middlename_updated',
    MiddleNameSkip:     'profile_middlename_skip',
    BirthDayUpdated:    'profile_birthday_updated',
    PassportUpdated:    'profile_passport_updated',
  },
  doc: {
    InvalidData:  'extra_doc_cannot_update_invalid_data',
    Updated:      'extra_doc_updated',
    InnUpdated:   'extra_doc_inn_updated',
    SnilsUpdated: 'extra_doc_snils_updated',
  },
  phone: {
    Entered:      'phone_entered',
    Confirmed:    'phone_confirmed',
    ResendCode:   'phone_resend_code',
    InvalidCode:  'phone_invalid_code',
  },
  mobileId: {
    StartScreen:      'mobile_id_start',
    MegafonScreen:    'mobile_id_megafon_start',
    BeelineScreen:    'mobile_id_beeline_start',
    MtsScreen:        'mobile_id_mts_start',
    GotoUprid:        'mobile_id_goto_uprid',
    ReturnFromUprid:  'mobile_id_return_from_uprid',
    SetBirthdate:     'mobile_id_set_birthdate',
    SendReq:          'mobile_id_send_req',
    CancelWait:       'mobile_id_cancel_wait',
    CancelReq:        'mobile_id_cancel_request',
    TooManyReqs:      'mobile_id_too_many_reqs',
    PersonificationDone: 'mobile_id_personification_done',
    Failed:           'mobile_id_failed',
  },
  tooltip: {
    NameInLastName: 'tooltip_name_in_lastname',
    MayBeWrongName: 'tooltip_maybe_wrong_name',
  }
}

export const Metrika = {

  userParams(params: any){

    if(!use || !params)
      return;

    try {
      ym(id, 'userParams', params);
    } catch (e){
      log.info('cannot userParams', e);
    }
  },

  reachOnce(goal: string){

    if(!use || !goal)
      return;

    if(onceGoals[goal])
      return;
    onceGoals[goal] = true;

    this.reach(goal);
  },

  reach(goal: string){

    if(!use || !goal)
      return;

    try {
      ym(id, 'reachGoal', goal);
    } catch (e){
      log.info('cannot reachGoal', e);
    }
  }
}
