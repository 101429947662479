import React from 'react';
import { Notifications } from './components';

const withGlobalErrorHandler = (Component: React.FC) => (props: any) => (
  <>
    <Component {...props} />
    <Notifications />
  </>
);

export default withGlobalErrorHandler;
