import React, { useEffect } from 'react';
import { addSessionListener, Net } from '@/utils/axios';
import { rootStore } from '@/hocs/withStore/configureStore';
import { Action } from '@/model/actions';
import { useSelector } from 'react-redux';
import { RootState } from '@/model/types';
import { InvalidSessionPage } from '@/pages';
import { Goal, Metrika } from '@/api/metrika';
import { Util } from '@/utils/util';

const log = Util.getLog('hocs/withInvalidSession');
let sessionExpTimerId: any;


// catch special types of server errors
Net.interceptors.response.use(undefined, (e)=>{

  const {status} = e;

  if(status === 401 || status === 403){
    onSessionExpired();
  }

  throw e;
});


addSessionListener({

  onSessionUpdated(sessionExpDate?: number){

    // clear old timer
    clearTimeout(sessionExpTimerId);

    if( ! sessionExpDate)
      return;

    const waitTime = sessionExpDate - Date.now();
    if(waitTime <= 0){
      onSessionExpired();
      return;
    }

    // new wait timer
    log.info('wait session exp in', (waitTime / 1000 / 60 / 60), 'hours');
    sessionExpTimerId = setTimeout(onSessionExpired, waitTime);
  }
});

function onSessionExpired(){
  rootStore.dispatch(Action.global.SetInvalidSession(true).pure);
}

const withInvalidSessionMessage = (Component: React.FC) => (props: any) => {

  const invalidSession = useSelector((state: RootState) => state.global.invalidSession);

  useEffect(()=>{

    if(invalidSession){
      Metrika.reachOnce(Goal.err.InvalidSession);
    }

  }, [invalidSession]);

  if(invalidSession) {
    return (<InvalidSessionPage/>);
  }

  return (
    <>
      <Component {...props} />
    </>
  );
}

export default withInvalidSessionMessage;