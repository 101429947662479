import moment from 'moment';
import { call, put, putResolve, select, takeEvery } from 'redux-saga/effects';
import { withGlobalLock } from '@/model/global/sagas';
import { ActionWithPayload, withErrorHandler } from '@/utils/redux/action-creator';
import { getInputOrRestoredPhone, RootState } from '@/model/types';
import { UIState } from '@/model/global/types';

import {
  queryConfirmUserPhone, queryExtraConfirmPhone,
  queryPutUserPhone, querySendPhoneExtraConfirmCode,
} from '@/api/user';
import { Action } from '@/model/actions';
import { ConfirmationState } from './types';
import { CheckConfirmationActionPayload } from './actions';
import { storeAgreementDone } from '@/api/agreement';
import { DefLang } from '@/model/Lang';
import { ApiError } from '@/model/ApiError';
import { ReqIdResp } from '@/api/dto';
import { Goal, Metrika } from '@/api/metrika';

const defTimeout = 30;
const maxStubTimeout = 60;

function* requestConfirmationCode(): Generator {

  const rootState = (yield select((state: RootState) => state)) as RootState;
  const lang = rootState.global.lang || DefLang;
  const phone = getInputOrRestoredPhone(rootState);
  const needPhoneExtraCheck = rootState.remoteState.data?.needPhoneExtraCheck === true;
  const oldReqId = rootState.confirmation.reqId

  if (!phone) {
    throw new Error('invalid state: phone are unknown');
  }

  let reqId: string|undefined;
  let timeoutFromSever: number|undefined;

  try {

    let resp: ReqIdResp;
    if (needPhoneExtraCheck) {
      resp = (yield call(querySendPhoneExtraConfirmCode, lang)) as ReqIdResp;
    } else {
      resp = (yield call(queryPutUserPhone, { phone, lang })) as ReqIdResp;
    }

    reqId = resp.reqId;

    // max timeout from server meaning stub value (can call next one)
    if(resp.timeout && resp.timeout < maxStubTimeout)
      timeoutFromSever = resp.timeout;


  } catch (e){

    if(e.code !== ApiError.common.RateLimit)
      throw e;

    // can use old reqId
    if(oldReqId){
      reqId = oldReqId;
      timeoutFromSever = e.details?.timeoutSec;
    }
    else {
      yield put(Action.global.AddError({ messageId: 'common.error.to_many_request' }).pure);
      return;
    }
  }

  const timeout = timeoutFromSever && timeoutFromSever > defTimeout?
    timeoutFromSever
    : defTimeout;

  yield put(
    Action.confirmation.support.SetState({
      state: ConfirmationState.SENT,
      validTill: moment(new Date()).add(timeout, 's').toDate(),
      reqId,
    }).pure,
  );

  yield put(Action.global.Transmit({ state: UIState.CONFIRMATION }).pure);
}

function* checkConfirmationCode(
  { payload: { code, reqId } }: ActionWithPayload<CheckConfirmationActionPayload>
): Generator {
  try {

    const rootState = (yield select((state: RootState) => state)) as RootState;
    const phone = rootState.global.phone?.value;
    const needPhoneExtraCheck = rootState.remoteState.data?.needPhoneExtraCheck === true;
    const refreshMobileIdOperatorFromServer = !needPhoneExtraCheck;

    if(needPhoneExtraCheck){
      yield call(queryExtraConfirmPhone, {reqId, code});
    } else {
      yield call(queryConfirmUserPhone, {reqId, code});
    }

    Metrika.reachOnce(Goal.phone.Confirmed);

    yield putResolve(Action.global.CleanErrors().pure);
    yield put(Action.mobileId.ShowMobileIdIfNeed( { refreshMobileIdOperatorFromServer }).pure);
    yield put(Action.confirmation.support.SetState({ state: ConfirmationState.PASSED }).pure);

    if(phone)
      yield call(storeAgreementDone, phone);

  } catch (e) {

    if (e.code === ApiError.verification.CheckLimit) {
      yield put(Action.confirmation.support.SetState({ state: ConfirmationState.ATTEMPTS_NUMBER_EXHAUSTED }).pure);
    }
    else if (e.code === ApiError.verification.InvalidCode) {
      Metrika.reach(Goal.phone.InvalidCode);
      yield put(Action.confirmation.support.SetState({ state: ConfirmationState.WRONG_CODE }).pure);
    } else {
      throw e;
    }
  }
}

export default function* rootSaga(): Generator {
  yield takeEvery(
    Action.confirmation.Send.type,
    withGlobalLock(
      withErrorHandler(requestConfirmationCode, function* onError(): Generator {
        yield putResolve(Action.global.AddError({ messageId: 'confirmation.errors.send.error' }).pure);
      }),
    ),
  );
  yield takeEvery(
    Action.confirmation.CheckCode.type,
    withGlobalLock(
      withErrorHandler(checkConfirmationCode, function* onError(): Generator {
        yield put(Action.global.AddError({ messageId: 'confirmation.errors.check.error' }).pure);
      }),
      'checkConfirmationCode'
    ),
  );
}
