import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Typography } from '@material-ui/core';

import { RootState } from '@/model/types';
import { MainLayout } from '@/layout';
import { TitleButtonType } from '@/layout/components/TitleButton';
import { Action } from '@/model/actions';
import { MobileIdStatus } from '@/model/mobileId/types';
import { DateFieldInput } from '@/pages/ProfilePage/components';
import { DateUtil } from '@/utils/date-util';
import { PhoneOperator } from '@/model/Phone';
import { DigitsKeyboardAttrs } from '@/components/mobile-util';
import { Goal, Metrika } from '@/api/metrika';


export const NeedBirthDayPage: React.FC = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const rootState = useSelector((state: RootState) => state);
  const {mobileId} = rootState;
  const birthDay = mobileId.birthDayForCheck;

  const operator = mobileId.operator || PhoneOperator.Unknown;
  const operatorLabel = formatMessage({id: `phone_operator.${operator}.title`});

  const minSelectDate = DateUtil.get14YearsOld();
  const minSelectYearDay = DateUtil.formatToISOYearDay(minSelectDate);

  return (
    <MainLayout
      height={560}
      title={<FormattedMessage id="welcome.title"/>}
      buttons={{
        leftTitle: {
          type: TitleButtonType.BACK,
          onClick: () => {
            Action.mobileId.SetStatus(MobileIdStatus.NeedSelect)(dispatch);
            Action.mobileId.SaveState()(dispatch);
            Action.mobileId.SetBirthdayForCheck(undefined)(dispatch);
          },
        },
      }}
      submit={{
        onClick: async (): Promise<void> => {
          await Action.mobileId.StartRequest()(dispatch);
        },
        disabled: !birthDay || birthDay.hasError,
        title: <FormattedMessage id="profile.submit.title" />,
      }}
    >
      <Typography variant="body1">
        <FormattedMessage id="mobile_id.need_birthday.desc" values={{ operator: operatorLabel }} />
      </Typography>
      <DateFieldInput
        InputAttrs={DigitsKeyboardAttrs}
        value={{ value: birthDay?.value ? new Date(birthDay.value) : undefined, hasError: !!birthDay?.hasError }}
        maxDate={minSelectDate}
        messages={{
          invalid: <FormattedMessage id="profile.fields.birthDay.errors.invalid" />,
          max: <FormattedMessage id="profile.fields.birthDay.errors.max" values={{ year: 14 }} />,
          placeholder: formatMessage({ id: 'profile.fields.birthDay.title' }),
          empty: <FormattedMessage id="profile.fields.birthDay.errors.empty" />,
        }}
        onChange={async (value?: Date): Promise<void> => {

          const hasError = !value
            || Number.isNaN(value.getTime())
            || DateUtil.formatToISOYearDay(value) > minSelectYearDay;

          if(!hasError){
            Metrika.reach(Goal.mobileId.SetBirthdate);
          }

          await Action.mobileId.SetBirthdayForCheck({
            value: value && !hasError ? DateUtil.formatToISOYearDay(value) : undefined,
            hasError,
          })(dispatch);

        }}
      />
    </MainLayout>
  );
};
