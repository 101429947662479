import clsx from 'clsx';
import React, { ReactNode } from 'react';
import { Typography, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';

import { Space } from '@/components';
import { TitleButtonProp } from '@/layout/components/TitleButton';
import { TitleButton } from '@/layout/components';
import { isFrameMode } from '@/hocs/withIFrameDetect';

const useStyles = makeStyles((theme: Theme) => ({
  prefixBox: {
    fontSize: '20px',
    fontWeight: 800,
    backgroundColor: '#F2F2F7',
    borderBottom: '1px solid #E4E4EE',
    '& > span': {
      flex: '1',
      height: '68px',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  leftTitleButton: {
    marginRight: '20px !important'
  },
  rightTitleButton: {
    position: 'absolute',
    right: 0,
    height: '20px',
    width: '20px',
  },
  headerBox: {
    padding: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    alignItems: 'center',
    position: 'relative',
  },
}));

export type HeaderBoxProps = {
  title?: ReactNode;
  headerClassName?: string;
  prefixTitle?: ReactNode;
  buttons?: {
    leftTitle?: TitleButtonProp;
    rightTitle?: TitleButtonProp;
  };
};

export const HeaderBox: React.FC<HeaderBoxProps> = ({
  title,
  prefixTitle,
  buttons,
  headerClassName,
}: HeaderBoxProps) => {

  const classes = useStyles();
  const baseTheme = useTheme();
  const inFrame = isFrameMode();

  return (
    <>
      {!inFrame && prefixTitle && (
        <Space className={classes.prefixBox}>
          <Space>{prefixTitle}</Space>
        </Space>
      )}
      <Space
        className={clsx(classes.headerBox, headerClassName, 'x-header')}
        style={{
          paddingTop: baseTheme.spacing(prefixTitle ? 3 : 4),
          paddingBottom: '16px',
          position: 'relative',
        }}
      >
        {buttons?.leftTitle && <TitleButton type={buttons.leftTitle} className={classes.leftTitleButton} />}
        {title &&
        <Typography
          variant="h5"
          style={{
            textAlign: buttons?.leftTitle? 'left': 'center',
            width:'100%'
          }}>{title}
        </Typography>}
        {buttons?.rightTitle && <TitleButton type={buttons.rightTitle} className={classes.rightTitleButton} />}
      </Space>
    </>
  );
};

export default HeaderBox;
