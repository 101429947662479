import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { LinearProgress, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { MainLayout } from '@/layout';
import { Label, Space } from '@/components';
import { RootState } from '@/model/types';
import { MobileIdStatus } from '@/model/mobileId/types';
import { Action } from '@/model/actions';
import { TitleButtonType } from '@/layout/components/TitleButton';

import { PhoneOperator } from '@/model/Phone';
import clsx from 'clsx';
import { getThemeValues } from '@/hocs/withTheme';
import { isFrameMode } from '@/hocs/withIFrameDetect';
import { Goal, Metrika } from '@/api/metrika';

const useStyles = makeStyles((theme: Theme) => {

  const values = getThemeValues();

  return {
    phone: {
      borderBottom: 'solid 1px #E4E4EE',
      backgroundColor: theme.activeElementBackground,
      flexShrink: 2,
      width: '100%',
    },
    header: {
      backgroundColor: theme.activeElementBackground,
    },
    phoneParent: {
      paddingLeft: '0px !important',
      paddingRight: '0px !important',
    },
    bodyBox: {
      paddingLeft: 0,
      paddingRight: 0,
      textAlign: 'center',
      alignItems: 'center',
      width: '100%',
    },
    mainButtonEnabled: {
      marginBottom: theme.spacing(2),
      borderRadius: '12px',
      padding: 0,
      fontSize: '18px',
      lineHeight: `${theme.spacing(7)}px`,
      background: `${values.mainColor} !important`,
      borderColor: `${values.mainColor} !important`,
      color: theme.palette.secondary.contrastText,
    },
  };
});

export const WaitUserResult: React.FC = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const rootState = useSelector((state: RootState) => state);
  const { reqId } = rootState.mobileId;
  const operator = rootState.mobileId.operator || PhoneOperator.Unknown;
  const operatorLabel = formatMessage({id: `phone_operator.${operator}.title`});
  const inFrame = isFrameMode();

  useEffect(() => {
    if (reqId) {
      Action.mobileId.WaitResult({ reqId })(dispatch);
    }
  }, [reqId, dispatch]);

  return (
    <MainLayout
      title={<FormattedMessage
        id="mobile_id.wait.title"
        values={{ operator: operatorLabel }}
      />}
      height={600}
      boxClassname={clsx('x-wait-mobile-id')}
      buttons={{
        leftTitle: {
          type: TitleButtonType.BACK,
          onClick: () => {
            Metrika.reach(Goal.mobileId.CancelWait);
            Action.mobileId.SetStatus(MobileIdStatus.NeedSelect)(dispatch);
            Action.mobileId.SaveState()(dispatch);
          },
        },
      }}
      childrenParentClassName={clsx(classes.phoneParent, 'x-skip')}
      headerClassName={clsx(classes.header, inFrame? 'x-wait-mobile-id-header' : '')}
      mainContentClassName={clsx(classes.phoneParent, 'x-skip')}
    >
      <Space direction="vertical" size={2} className={clsx(classes.bodyBox, 'x-skip')}>
        <img src="/phone.png" className={classes.phone} alt="Mobile Id" />
        <div style={{ width: '160px' }}>
          <LinearProgress />
        </div>
        <Typography variant="h6">
          <FormattedMessage id="mobile_id.wait.description" />
        </Typography>
        <Label>
          <FormattedMessage id="mobile_id.wait.details" />
        </Label>
      </Space>
    </MainLayout>
  );
};
