import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { conformToMask } from 'react-text-mask';
import { Link, Theme, Typography } from '@material-ui/core';

import { getInputOrRestoredPhone, getWorkMode, RootState } from '@/model/types';
import { MainLayout } from '@/layout';
import { MobileIdStatus } from '@/model/mobileId/types';
import { PhoneOperator } from '@/model/Phone';
import { Action } from '@/model/actions';
import { UIState } from '@/model/global/types';
import { Space } from '@/components';
import { phoneMask } from '@/components/PhoneInput';

import { IdentificationDuration, SingleOption } from './components';
import { ReactComponent as BeelineWithMidIcon } from './icons/beeline_with_mid.svg';
import { ReactComponent as MegafonIcon } from './icons/megafon.svg';
import { ReactComponent as MTSIcon } from './icons/mts.svg';
import { ReactComponent as ManualIcon } from './icons/manual.svg';
import { WorkMode } from '@/model/remote_state/types';
import { makeStyles } from '@material-ui/styles';
import { Goal, Metrika } from '@/api/metrika';

const useStyles = makeStyles((theme: Theme) => ({
  boxClassname: {
    backgroundColor: theme.activeElementBackground
  }
}));

export const NeedSelectPage: React.FC = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const classes = useStyles();
  const rootState = useSelector((state: RootState) => state);
  const operator = rootState.mobileId.operator || PhoneOperator.Unknown;
  const operatorLabel = formatMessage({id: `phone_operator.${operator}.title`});
  const phone = getInputOrRestoredPhone(rootState) || '';
  const formattedPhone = conformToMask(phone.substring(1), phoneMask, {}).conformedValue;
  const workMode = getWorkMode(rootState);
  const canShowManual = workMode !== WorkMode.Full;

  useEffect(()=>{
    if(operator === PhoneOperator.Megafon){
      Metrika.reach(Goal.mobileId.MegafonScreen);
    }
    else if(operator === PhoneOperator.Beeline){
      Metrika.reach(Goal.mobileId.BeelineScreen);
    }
    else if(operator === PhoneOperator.MTS){
      Metrika.reach(Goal.mobileId.MtsScreen);
    }
  }, [operator]);

  return (
    <MainLayout
      boxClassname={classes.boxClassname}
      height={590}
      title={<FormattedMessage id="welcome.title"/>}
    >
      <Space direction="vertical">
        <Typography variant="body1">
          <FormattedMessage id="profile.description" />
        </Typography>
        {formattedPhone && operator !== PhoneOperator.Unknown && (
          <Typography variant="body1">
            <FormattedMessage
              id="mobile_id.by_phone.desc"
              values={{
                phone: <strong style={{whiteSpace: 'nowrap'}}>{formattedPhone}</strong>,
                operator: operatorLabel
              }}
            />
          </Typography>
        )}
      </Space>

      {operator === PhoneOperator.Megafon && (
        <SingleOption
          icon={<MegafonIcon />}
          label={
            <FormattedMessage
              id="mobile_id.by_phone.start"
              values={{ operator: <FormattedMessage id="phone_operator.Megafon.title" /> }}
            />
          }
          detalization={<FormattedMessage id="phone_operator.Megafon.description" />}
          onSelect={async (): Promise<any> => {
            await Action.mobileId.StartRequest()(dispatch);
          }}
          postfix={<IdentificationDuration title={<FormattedMessage id="mobile_id.by_phone.duration" />} />}
        />
      )}
      {operator === PhoneOperator.Beeline && (
        <SingleOption
          icon={<BeelineWithMidIcon />}
          label={
            <FormattedMessage
              id="mobile_id.by_phone.start"
              values={{ operator: <FormattedMessage id="phone_operator.Beeline.title" /> }}
            />
          }
          detalization={<FormattedMessage id="phone_operator.Beeline.description" />}
          onSelect={async (): Promise<any> => {
            await Action.mobileId.StartRequest()(dispatch);
          }}
          postfix={<IdentificationDuration title={<FormattedMessage id="mobile_id.by_phone.duration" />} />}
        />
      )}
      {operator === PhoneOperator.MTS && (
        <SingleOption
          icon={<MTSIcon />}
          label={
            <FormattedMessage
              id="mobile_id.by_phone.start"
              values={{ operator: <FormattedMessage id="phone_operator.MTS.title" /> }}
            />
          }
          detalization={<FormattedMessage id="phone_operator.MTS.description" />}
          onSelect={async (): Promise<any> => {
            await Action.mobileId.StartRequest()(dispatch);
          }}
          postfix={<IdentificationDuration title={<FormattedMessage id="mobile_id.by_phone.duration" />} />}
        />
      )}
      {canShowManual && (
        <Link
          data-test="manual-input"
          onClick={async (e: React.MouseEvent<any>): Promise<void> => {
            e.preventDefault();
            await Action.mobileId.SetStatus(MobileIdStatus.Skip)(dispatch);
            await Action.mobileId.SaveState()(dispatch);
            await Action.global.Transmit({ state: UIState.PROFILE })(dispatch);

            Metrika.reach(Goal.mobileId.GotoUprid);
          }}
          href="#"
          style={{
            textAlign: 'center',
          }}
        >
          <FormattedMessage id="mobile_id.manual.or_start" />
        </Link>
      )}

      {canShowManual && operator === PhoneOperator.Unknown && (
        <SingleOption
          icon={<ManualIcon />}
          label={<FormattedMessage id="mobile_id.manual.start" />}
          detalization={<FormattedMessage id="mobile_id.manual.desc" />}
          onSelect={async (): Promise<any> => {
            await Action.mobileId.SetStatus(MobileIdStatus.Skip)(dispatch);
            await Action.mobileId.SaveState()(dispatch);
            await Action.global.Transmit({ state: UIState.PROFILE })(dispatch);

            Metrika.reach(Goal.mobileId.GotoUprid);
          }}
          postfix={<IdentificationDuration title={<FormattedMessage id="mobile_id.manual.duration" />} />}
        />
      )}

      {!canShowManual && operator === PhoneOperator.Unknown && (
        <Typography variant="body1">
          <FormattedMessage
            id="mobile_id.by_phone.cannot_handle_phone"
            values={{ phone: <strong style={{whiteSpace: 'nowrap'}}>{formattedPhone}</strong>, operator }}
          />
        </Typography>
      )}
    </MainLayout>
  );
};
