import React, { ReactNode, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { Checkbox, FormControlLabel, Link, Typography, useTheme } from '@material-ui/core';

import { MainLayout } from '@/layout';
import { getInputOrRestoredPhone, RootState } from '@/model/types';
import { documentPath, documentTypeMessageMapping } from '@/pages/AgreementDocumentPage/types';
import { Action } from '@/model/actions';
import { Document } from '@/model/agreement/types';
import Space from '@/components/Space';
import { ReactComponent as PhoneIcon } from './icons/phone-checked.svg';
import { conformToMask } from 'react-text-mask';
import { phoneMask } from '@/components/PhoneInput';
import { Config } from '@/config';
import AboutProviderLabel from '@/components/AboutProviderLabel';

export const RegistrationPage: React.FC = () => {

  const classes = useTheme();
  const dispatch = useDispatch();

  const state = useSelector((state: RootState) => state);
  const {agreement} = state;
  const phone = getInputOrRestoredPhone(state) || '';
  const formattedPhone = conformToMask(phone.substring(1), phoneMask, {}).conformedValue;
  const needPhoneExtraCheck = state.remoteState.data?.needPhoneExtraCheck === true;
  const showAgreement = ! Config.DefaultAgreement;

  useEffect(()=>{
    if( ! agreement.isModal)
      Action.agreement.SetModal()(dispatch);
  }, [agreement, dispatch]);

  return (
    <MainLayout
      title={<FormattedMessage id="welcome.title" />}
      height={438}
      submit={{
        onClick: async (): Promise<void> => {

          Action.agreement.SetDone({done: true})(dispatch);
          Action.agreement.StoreDone()(dispatch);

          if(needPhoneExtraCheck){
            await Action.confirmation.Send()(dispatch);
          } else {
            await Action.mobileId.ShowMobileIdIfNeed( { refreshMobileIdOperatorFromServer: false })(dispatch);
          }
        },
        disabled: !agreement.userAgreement,
        title: <FormattedMessage id="registration.submit.title" />,
      }}
      footer={{
        beforeSubmit: (
          <>
            { showAgreement &&
              <FormControlLabel
                style={{marginBottom: '9px'}}
                control={
                  <Checkbox
                    checked={agreement.userAgreement}
                    onChange={(_: React.ChangeEvent<HTMLInputElement>, confirmed: boolean): void =>
                      Action.agreement.SetConfirm({ confirmed, document: Document.USER_AGREEMENT })(dispatch)
                    }
                  />
                }
                label={
                  <FormattedMessage
                    id={`${documentTypeMessageMapping[Document.USER_AGREEMENT]}.link`}
                    values={{
                      link: (content: string): ReactNode => (
                        <Link component={RouterLink} to={documentPath(Document.USER_AGREEMENT)}>
                          {content}
                        </Link>
                      ),
                    }}
                  />
                }
              />
            }
            { ! showAgreement && <AboutProviderLabel/> }
          </>)
      }}
    >
      <Typography variant="body1" style={{ ...classes.formLayout.baseElement }}>
        <FormattedMessage id="registration.already_done" />
      </Typography>
      <Space
        direction="horizontal"
        style={{
          alignItems: 'center',
          border: '0.5px solid #E4E4EE',
          borderRadius: '12px',
          padding: '10px',
          flexWrap: 'wrap'
        }}>
        <PhoneIcon style={{flexShrink: 0}}/>
        <span
          style={{
            marginLeft: '4px',
            whiteSpace: 'nowrap',
            fontSize: '18px',
            fontWeight: 500
          }}>
          {formattedPhone}
        </span>
      </Space>
    </MainLayout>
  );
};

export default withRouter(RegistrationPage);
