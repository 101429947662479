import * as auth from './auth/types';
import * as confirmation from './confirmation/types';
import * as documents from './documents/types';
import * as global from './global/types';
import * as profile from './profile/types';
import * as verification from './verification/types';
import * as remoteState from './remote_state/types';
import { WorkMode } from './remote_state/types';
import * as agreementState from './agreement/types';
import * as mobileId from './mobileId/types';
import * as params from './params/types';
import { Config } from '@/config';
import { Util } from '@/utils/util';


export interface RootState {
  global: global.State;
  auth: auth.AuthState;
  confirmation: confirmation.State;
  profile: profile.ProfileState;
  documents: documents.DocumentState;
  verification: verification.State;
  remoteState: remoteState.RemoteState;
  agreement: agreementState.AgreementState;
  mobileId: mobileId.MobileIdState;
  params: params.AppParams;
}

export function getInputOrRestoredPhone(rootState: RootState) {
  return rootState.global.phone?.value
    || rootState.remoteState.data?.profile?.phone?.number;
}

export function getWorkMode(rootState: RootState) {
  return rootState.remoteState.data?.workMode || WorkMode.Any;
}

export function skipInn(rootState: RootState){
  return Config.SkipInn || Util.parseBool(rootState.params['skip-inn'], false);
}

