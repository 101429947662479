import React from 'react';

import { MainLayout } from '@/layout';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@material-ui/core';

export const InvalidSessionPage: React.FC = () => {

  return (
    <MainLayout title={<FormattedMessage id="invalid_session.title" />}>
      <Typography>
        <FormattedMessage id="invalid_session.description"/>
      </Typography>
    </MainLayout>
  );
};

export default InvalidSessionPage;
