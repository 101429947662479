import React from 'react';
import { LocalStorage } from '@/api/storage';

export const DEFAULT_I18N = 'ru-RU';
export const I18N_LOCAL_STORE_KEY = 'LANG';

const parseFromQuery = (): string | undefined | null => new URLSearchParams(window.location.search).get('lang');
const parseFromLocalStore = (): string | undefined | null => LocalStorage.getItem(I18N_LOCAL_STORE_KEY);

const saveToLocalStore = (locale: string): void => {
  LocalStorage.setItem(I18N_LOCAL_STORE_KEY, locale);
};

export const withLocalization = (Component: React.FC) => (props: any) => {
  const locale = parseFromQuery() || parseFromLocalStore() || DEFAULT_I18N;
  saveToLocalStore(locale);
  return (
    <Component locale={locale} {...props} />
  );
};

export default withLocalization;
