import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { createStyles, Snackbar, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { RootState } from '@/model/types';
import { AlertTitle } from '@material-ui/lab';
import { Action } from '@/model/actions';

const Alert: React.FC<AlertProps> = (props: AlertProps) => <MuiAlert elevation={6} variant="filled" {...props} />;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    snackbar: {
      padding: theme.spacing(),
      top: '10px',
    },
    alertTitle: {
      color: theme.palette.error.contrastText,
    },
    alert: {
      width: theme.MainContainer.width - theme.spacing(2),
      color: theme.palette.error.contrastText,
      backgroundColor: theme.palette.error.dark,
    },
  }),
);

const Notifications: React.FC = () => {
  const classes = useStyles();
  const errors = useSelector((state: RootState) => state.global.errors);
  const dispatch = useDispatch();

  return errors.length > 0 ? (
    <>
      {errors.map(({ messageId, id }) => (
        <Snackbar open className={classes.snackbar} anchorOrigin={{ horizontal: 'center', vertical: 'top' }} key={id}>
          <Alert
            severity="error"
            className={classes.alert}
            onClose={(): void => {
              Action.global.support.RemoveError({ id })(dispatch);
            }}
          >
            <AlertTitle className={classes.alertTitle}>
              <FormattedMessage id="common.error.title" />
            </AlertTitle>
            <FormattedMessage id={messageId} />
          </Alert>
        </Snackbar>
      ))}
    </>
  ) : (
    <></>
  );
};

export default Notifications;
