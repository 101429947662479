import React, { useEffect, useState } from 'react';

import * as locales from '@material-ui/core/locale';
import { CssBaseline } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { MuiPickersOverrides } from '@material-ui/pickers/typings/overrides';
import { AlertClassKey, AlertTitleClassKey } from '@material-ui/lab';
import { ThemeProvider } from '@material-ui/styles';

import { DEFAULT_I18N } from '../withI18nSupport';
import { useSelector } from 'react-redux';
import { RootState } from '@/model/types';
import { AppParams } from '@/model/params/types';
import { Util } from '@/utils/util';

const tryLocale = (locale: string): [string, Record<string, string>] | undefined => {
  const foundLocale = (locales as { [k in string]: {} })[locale.replace('-', '')];
  return foundLocale && [locale, foundLocale];
};

const parseLocale = (locale: string): [string, Record<string, string>] =>
  tryLocale(locale) as [string, Record<string, string>];

declare module '@material-ui/core/styles/overrides' {
  export interface ComponentNameToClassKey extends MuiPickersOverrides {
    MuiAlert: AlertClassKey;
    MuiAlertTitle: AlertTitleClassKey;
  }
}

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    MainContainer: {
      height: number;
      width: number;
      display: string;
      padding: string;
    };
    formLayout: {
      baseElement: {
        marginBottom: string;
      };
      subElement: {
        marginBottom: string;
      };
    };
    activeElementBackground: string;
  }

  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    MainContainer?: {
      height: number;
      width: number;
      display: string;
      padding: string | number;
    };
    formLayout: {
      baseElement: {
        marginBottom: string;
      };
      subElement: {
        marginBottom: string;
      };
    };
    activeElementBackground: string;
  }
}

export interface ThemeValues {
  mainColor: string,
  backColor: string,
  font: string,
}

const defaultThemeValues:ThemeValues = {
  mainColor: '#00C489',
  backColor: '#141233',
  font: 'Roboto'
}

let themeValues = defaultThemeValues;

export function getThemeValues() {
  return themeValues;
}

function initThemeValues(params: AppParams):ThemeValues{
  return {
    mainColor: toColor(params['t-main-color'] || defaultThemeValues.mainColor),
    backColor: toColor(params['t-back-color'] || defaultThemeValues.backColor),
    font: params['t-font'] || defaultThemeValues.font,
  }
}

function toColor(val?:string):string {
  if( ! val)
    return '';

  if(val.startsWith('#')
      || val.startsWith('rgb'))
    return val;

  return Util.isHexString(val)? `#${val}` : val;
}


const crateTheme = (locale: string): Theme => {

  const values = themeValues;

  const activeElementBackground = '#F2F2F7';
  const errorColor = '#F22462';
  const errorBackgroundColor = '#571741';
  const buttonBackgroundColor = '#E9E9F2';
  const bodyColor = '#141233';
  const body2Color = '#999BBF';
  const inputLabelColor = '#8D8EA6';
  const isOtherFont = defaultThemeValues.font !== values.font;

  return createMuiTheme(
    {
      activeElementBackground,
      MainContainer: {
        height: 584,
        width: 440,
        display: 'flex',
        padding: 0,
      },
      formLayout: {
        baseElement: {
          marginBottom: '20px',
        },
        subElement: {
          marginBottom: '8px',
        },
      },
      overrides: {
        MuiLinearProgress: {
          colorPrimary: {
            backgroundColor: '#C0BED8',
          },
          barColorPrimary: {
            backgroundColor: '#302E4C',
          },
        },
        MuiAlert: {
          outlinedWarning: {
            '& .MuiAlertTitle-root': {
              color: 'inherit'
            }
          },
          root: {
            borderRadius: '12px',
          },
          filledSuccess: {
            backgroundColor: values.mainColor,
          },
          message: {
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '18px',
            paddingBottom: '20px',
          },
        },
        MuiAlertTitle: {
          root: {
            color: 'white',
            fontWeight: 500,
            fontSize: '18px',
            lineHeight: '24px',
          },
        },
        MuiDivider: {
          light: {
            backgroundColor: '#585673',
          },
        },
        MuiFormHelperText: {
          root: {
            '&$error': {
              margin: 0,
              marginTop: '16px',
              fontSize: '15px',
              lineHeight: '19px',
            },
          },
        },
        MuiInputLabel: {
          filled: {
            color: inputLabelColor,
            '&$error': {
              color: errorColor,
            },
          },
        },
        MuiFormLabel: {
          filled: {
            color: inputLabelColor,
            '&$error': {
              color: errorColor,
            },
          },
        },
        MuiInput: {
          root: {
            backgroundColor: activeElementBackground,
            borderRadius: '12px',
            '&$error': {
              '& > input': {
                color: errorColor,
              },
              '& > .MuiInputAdornment-root > button': {
                color: errorColor,
              },
            },
            '& > .MuiInputAdornment-root > button': {
              color: bodyColor,
            },
          },
          input: {
            padding: '16px',
            color: bodyColor,
            lineHeight: '24px',
            height: '24px',
            fontSize: '16px',
          },
        },
        MuiFilledInput: {
          root: {
            backgroundColor: activeElementBackground,
            borderRadius: '12px',
            borderTopRightRadius: '12px',
            borderTopLeftRadius: '12px',
            '&$disabled': {
              backgroundColor: buttonBackgroundColor,
            },
            '&$focused': {
              backgroundColor: activeElementBackground,
            },
            '&:hover': {
              backgroundColor: activeElementBackground,
            },
            '&$error': {
              '& > input': {
                color: errorColor,
              },
              '& > .MuiInputAdornment-root > button': {
                color: errorColor,
              },
            },
            '& > .MuiInputAdornment-root > button': {
              color: bodyColor,
            },
          },
          input: {
            color: bodyColor,
            lineHeight: '24px',
          },
        },
        MuiCheckbox: {
          root: {
            color: buttonBackgroundColor,
            '&$checked': {
              color: values.mainColor,
            },
            '& .MuiIconButton-label': {
              position: 'relative',
              zIndex: 0,
            },
            '&:not($checked) .MuiIconButton-label:after': {
              content: '""',
              left: 4,
              top: 4,
              height: 15,
              width: 15,
              position: 'absolute',
              backgroundColor: 'white',
              zIndex: -1,
            },
          },
          colorSecondary: {
            '&$checked': {
              color: values.mainColor,
            },
          },
          colorPrimary: {
            '&$checked': {
              color: values.mainColor,
            },
          },
        },
        MuiButton: {
          root: {
            fontWeight: 500,
            '&$disabled': {
              color: body2Color,
              backgroundColor: buttonBackgroundColor,
            },
          },
          outlined: {
            '&$disabled': {
              borderColor: buttonBackgroundColor,
            },
          },
        },
        MuiLink: {
          root: {
            color: values.mainColor,
          },
        },
        MuiPickersToolbar: {
          toolbar: {
            backgroundColor: '#FFFFFF',
          },
        },
        MuiPickersDay: {
          daySelected: {
            color: '#FFFFFF',
          },
        },
      },
      palette: {
        primary: {
          light: '#FFFFFF',
          main: '#727272',
          dark: '#141233',
          contrastText: '#141233',
        },
        secondary: {
          light: '#ff5e50',
          main: '#e41e26',
          dark: '#a90000',
          contrastText: '#FFFFFF',
        },
        error: {
          light: errorBackgroundColor,
          contrastText: errorColor,
          main: errorColor,
          dark: errorBackgroundColor,
        },
      },
      typography: {
        fontFamily: `${values.font}${isOtherFont? `, ${defaultThemeValues.font}` : ''}, Arial`,
        h3: {
          fontStyle: 'normal',
          fontWeight: 900,
          fontSize: '32px',
          lineHeight: '37px',
          textAlign: 'center',
        },
        h4: {
          fontStyle: 'normal',
          fontWeight: 'bold',
          fontSize: '20px',
          lineHeight: '22px',
          textAlign: 'left',
        },
        h5: {
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: '20px',
          lineHeight: '26px',
          textAlign: 'left',
        },
        body1: {
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '20px',
          letterSpacing: '0em',
          color: bodyColor,
        },
        body2: {
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '14px',
          lineHeight: '20px',
          color: '#FFFFFF',
        },
        button: {
          width: '100%',
          fontStyle: 'normal',
          fontWeight: 'normal',
          textTransform: 'inherit',
        },
        overline: {
          color: values.mainColor,
        },
      },
    },
    parseLocale(locale),
  );
};

const withTheme = (Component: React.FunctionComponent) => ({
  locale = DEFAULT_I18N,
  ...props
}: {
  locale?: string;
  [_: string]: unknown;
}): JSX.Element => {

  const rootState = useSelector((state: RootState) => state);
  const {params} = rootState;
  const paramsLoaded = params.loaded;
  const [theme, setTheme] = useState<Theme>();

  useEffect(()=>{
    if(paramsLoaded && !theme){

      // init all values
      themeValues = initThemeValues(params);

      // make theme
      const themeInst = crateTheme(locale);
      setTheme(themeInst);
    }
  }, [paramsLoaded, theme, params, locale]);

  // wait for params
  if(!paramsLoaded || !theme){
      return (
        <>
        </>
      );
  }

  const themeVal: any = theme;
  return (
    // MuiThemeProvider makes the theme available down the React tree
    // thanks to React context.
    <ThemeProvider theme={themeVal}>
      {/* Reboot kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <Component {...props} />
    </ThemeProvider>)
}

export default withTheme;
