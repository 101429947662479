import { ActionWithPayload } from '@/utils/redux/action-creator';

import { createReducer } from '@/utils/redux/reducer-util';
import { Action } from '@/model/actions';
import { initialState, State } from './types';
import { SaveVerificationResultActionPayload } from './actions';

export const reducers = createReducer<State>(initialState, {
  [Action.verification.SetStatus.type](
    state: State,
    { payload: { status } }: ActionWithPayload<SaveVerificationResultActionPayload>,
  ): State {
    return { ...state, status };
  },
});

export default reducers;
