
export const StringUtil = {

    replaceAll(str: string, token: string, newToken: string) {
        return str.split(token).join(newToken)
    },

    digitsOnly(str: string){
        return str.replace(/[^\d]/g, '')
    },

    removeBegin(str: string, ...anyFirst: string[]){

        if( ! str)
            return str;

        let cur;
        for (let i = 0; i < anyFirst.length; i++) {

            cur = anyFirst[i];
            if( ! cur)
                continue;

            if( ! str.startsWith(cur))
                continue;

            return str.substring(cur.length);
        }

        return str;
    },

    removeEnd(str: string, last: string){

        if( ! str)
            return str;

        if( ! str.endsWith(last))
            return str;

        return str.substring(0, str.length - last.length);
    },
};