import { Value } from '@/model/common';

export interface ProfileState {
  firstName?: Value<string>;
  lastName?: Value<string>;
  middleName?: Value<string>;
  birthDay?: Value<string>;
  passport?: Value<string>;
  userInfoChanged: boolean;
  passportChanged: boolean;
  needMiddleName: boolean;
}

export const initialState: ProfileState = {
  userInfoChanged: true,
  passportChanged: true,
  needMiddleName: true,
};
