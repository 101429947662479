import { StringUtil } from '@/utils/string-util';


export const Phone = {

    normalizePhone(phone: string) {

        if( ! phone)
            return phone;

        phone = StringUtil.digitsOnly(phone);

        if (phone.startsWith('89') && Phone.isStandardPhoneSize(phone))
            phone = '7' + phone.substring(1);

        return phone;
    },

    isStandardPhoneSize(phone: string){
        return phone.length === 11;
    }

};


export enum PhoneOperator {
    MTS = 'MTS',
    Beeline = 'Beeline',
    Megafon = 'Megafon',
    Unknown = 'Unknown',
}

export interface MobileIdConfig {
    checkBirthday: boolean
}