import { createReducer } from '@/utils/redux/reducer-util';
import { AppParams, initialState } from './types';
import { Action } from '@/model/actions';
import { ActionWithPayload } from '@/utils/redux/action-creator';
import { SetParamPayload } from '@/model/params/actions';

export const reducers = createReducer<AppParams>(initialState, {
  [Action.params.SetAll.type](state: AppParams, {payload}: ActionWithPayload<Record<string, any>>): AppParams {
    return {
      ...state,
      ...payload
    } as any;
  },
  [Action.params.SetParam.type](state: AppParams, {payload}: ActionWithPayload<SetParamPayload>): AppParams {
    return {
      ...state,
      [payload.key]: payload.value,
    } as any;
  }
});

export default reducers;
