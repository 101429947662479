import { LangType } from '@/model/Lang';


export interface State {
  phone?: {
    value: string;
    hasError: boolean;
  };
  loading: boolean;
  errors: GlobalIdentifiedError[];
  lang?: LangType;
  invalidSession: boolean;
}

export type GlobalError = {
  messageId: string;
};

export type GlobalIdentifiedError = GlobalError & {
  id: string;
};

export enum UIState {
  LOGIN = 'LOGIN',
  REGISTRATION = 'REGISTRATION',
  AGREEMENT = 'AGREEMENT',
  CONFIRMATION = 'CONFIRMATION',
  MOBILE_ID = 'MOBILE-ID',
  PROFILE = 'PROFILE',
  DOCUMENTS = 'DOCUMENTS',
  VERIFICATION = 'VERIFICATION',
}

export enum TransmissionState {
  NONE = 'NONE',
  IN_PROGRESS = 'IN_PROGRESS',
  FINISHED = 'FINISHED',
  ERROR = 'ERROR',
}

export const initialState: State = {
  loading: false,
  errors: [],
  invalidSession: false,
};
