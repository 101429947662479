import React, { MouseEvent, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@material-ui/core';

import { MainLayout } from '@/layout';
import { TitleButtonType } from '@/layout/components/TitleButton';
import { RootState } from '@/model/types';
import { ProfileValueType } from '@/model/profile/actions';
import { Action } from '@/model/actions';
import { PhoneOperator } from '@/model/Phone';
import { MobileIdStatus } from '@/model/mobileId/types';
import { UIState } from '@/model/global/types';
import { DateFieldInput } from '@/pages/ProfilePage/components';
import { WorkMode } from '@/model/remote_state/types';
import { DateUtil } from '@/utils/date-util';
import { DigitsKeyboardAttrs } from '@/components/mobile-util';
import { Config } from '@/config';
import AboutProviderLabel from '@/components/AboutProviderLabel';
import { ProfileFieldInput } from '@/pages/ProfilePage/components/ProfileFieldInput';
import { PassportMaskInput } from '@/pages/ProfilePage/components/PassportMaskInput';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import { Goal, Metrika } from '@/api/metrika';
import { getNameSuggest } from '@/api/name';
import { Alert } from '@material-ui/lab';
import { Util } from '@/utils/util';

function isValidRusNamePart(value: string): boolean{
  if(!value)
    return true;
  value = value.trim();
  // space
  // а-яА-Я   (Cyrillic expect special chars for 'ё')
  // ё 1105   (win,nix)
  // ë 235    (old macos?)
  // Ё 1025   (win,nix)
  // Ë 203    (macos)
  // – 8211   (middle, Alt + 0150)
  // — 8212   (long, Alt + 0151)
  // − 8722   (minus)
  // - 45     (keyboard)
  return /^[ а-яА-ЯёëЁË–—−-]+$/.test(value);
}

function getNameSpecialErrCode(prefix: string, value?: string): string|undefined {

  if(!value || isValidRusNamePart(value))
    return undefined;

  const invalidChars = value.replace(/[а-яА-ЯёëЁË]/g, '');
  if(invalidChars.length === value.length)
    return prefix + '.invalid_chars';
  else
    return prefix + '.mix_langs';
}

const minSelectYearDay = '1900-01-01';
const minSelectDate = new Date(minSelectYearDay);


const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
  },
  tooltip: {
    position: 'absolute',
    right: '0px',
    top: '20px',
    height: '70px',
    width: '200px',
    zIndex: 100,
    backgroundColor: 'rgba(255, 244, 229, 0.5)',
    overflow: 'hidden',
    cursor: 'pointer',
    '& .MuiAlert-message': {
      fontSize: '12px',
    }
  },
  middleNameWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '14px !important',
  },
  middleNameMode: {
    marginTop: '10px',
    textAlign: 'right',
  },
  middleNameModeLink: {
    color: '#141233',
    textDecoration: 'none',
  }
}));

export const ProfilePage: React.FC = () => {

  const dispatch = useDispatch();
  const classes = useStyles();

  const [firstValidation, setFirstValidation] = useState(true);
  const [showMiddleNameError, setShowMiddleNameError] = useState(false);
  const [lastNameIsName, setLastNameIsName] = useState(false);
  const [firstNameSuggest, setFirstNameSuggest] = useState('');
  const [lastNameTooltipVal, setLastNameTooltipVal] = useState('');
  const [firstNameTooltipVal, setFirstNameTooltipVal] = useState('');

  const rootState = useSelector((state: RootState) => state);
  const { firstName, lastName, passport, middleName, birthDay, needMiddleName } = rootState.profile;
  const {operator} = rootState.mobileId;
  const mobileIdStatus = rootState.mobileId.status;
  const workMode = rootState.remoteState?.data?.workMode || WorkMode.Any;

  const showBack = workMode !== WorkMode.Simple && operator !== PhoneOperator.Unknown;
  const { formatMessage } = useIntl();

  const maxSelectDate = DateUtil.get14YearsOld();
  const maxSelectYearDay = DateUtil.formatToISOYearDay(maxSelectDate);
  const showProviderInfo = Config.DefaultAgreement;

  useEffect(()=>{
    if(firstValidation){

      if(lastName?.value){
        onLastNameUpdated(lastName.value).catch(Util.onErr);
      }

      if(firstName?.value){
        onFirstNameUpdated(firstName.value).catch(Util.onErr);
      }
      
      setFirstValidation(false);
    }
  }, [firstValidation, lastName, firstName]);

  useEffect(()=>{
    if(showMiddleNameError){
      setShowMiddleNameError(false);
    }
  }, [showMiddleNameError]);

  useEffect(()=>{
    const hasNameOrLastName = (firstName?.value && firstName.value.length > 0)
      || (lastName?.value && lastName?.value?.length > 0);

    if(firstValidation
      && hasNameOrLastName
      && !middleName
      && needMiddleName){

      Action.profile.Set({
        value: '',
        hasError: true,
        type: ProfileValueType.MiddleName
      })(dispatch);
      setShowMiddleNameError(true);
    }
  }, [firstName, lastName, middleName, needMiddleName, firstValidation]);

  const changeMiddleNameMode = (e: MouseEvent<any>): void =>{

    e.preventDefault();

    const newVal = !needMiddleName;

    Action.profile.NeedMiddleName(newVal)(dispatch);

    Action.profile.Set({
      value: '',
      hasError: newVal,
      type: ProfileValueType.MiddleName
    })(dispatch);

    if(newVal)
      setShowMiddleNameError(true);
    else
      Metrika.reach(Goal.profile.MiddleNameSkip);
  };

  const onLastNameUpdated = async (value: string) => {

    // skip if user didnt change old value
    if(value === lastNameTooltipVal)
      return;

    try {
      const resp = await getNameSuggest(value);
      if(resp.isName){
        setLastNameIsName(true);
        Metrika.reach(Goal.tooltip.NameInLastName);
      }
    } catch (e){
      // skip
    }
  };

  const onFirstNameUpdated = async (value: string) => {

    // skip if user didnt change old value
    if(value === firstNameTooltipVal)
      return;

    try {
      const resp = await getNameSuggest(value);
      if(resp.suggestName){
        const suggestName = resp.suggestName.charAt(0).toUpperCase() + resp.suggestName.slice(1);
        setFirstNameSuggest(suggestName);
        Metrika.reach(Goal.tooltip.MayBeWrongName);
      }
    } catch (e){
      // skip
    }
  }

  const closeLastNameTooltip = ()=> {
    setLastNameIsName(false);
    setLastNameTooltipVal(lastName?.value || '');
  }

  const closeFirstNameTooltip = ()=> {
    setFirstNameSuggest('');
    setFirstNameTooltipVal(firstName?.value || '');
  }

  return (
    <MainLayout
      height={650}
      title={<FormattedMessage id="profile.title" />}
      {...(showBack
        ? {
            buttons: {
              leftTitle: {
                type: TitleButtonType.BACK,
                onClick: () => {
                  if (mobileIdStatus === MobileIdStatus.Skip) {
                    Action.mobileId.SetStatus(MobileIdStatus.NeedSelect)(dispatch);
                    Action.mobileId.SaveState()(dispatch);
                  }
                  Action.global.Transmit({ state: UIState.MOBILE_ID })(dispatch);
                  Metrika.reach(Goal.mobileId.ReturnFromUprid);
                },
              },
            },
          }
        : {})}
      submit={{
        onClick: async (): Promise<void> => {
          await Action.profile.Save()(dispatch);
        },
        disabled:
          // prettier: FIXME: https://github.com/prettier/prettier/pull/7111
          // prettier-ignore
          !firstName || firstName.hasError
          || !lastName || lastName.hasError
          || (needMiddleName && !middleName)
          || (middleName && middleName.hasError)
          || !birthDay || birthDay.hasError
          || !passport || passport.hasError,

        title: <FormattedMessage id="profile.submit.title" />,
      }}
      footer={{
        beforeSubmit: showProviderInfo && (<AboutProviderLabel/>)
      }}
    >
      <Typography variant="body1">
        <FormattedMessage id="profile.description" />
      </Typography>

      <div className={classes.wrapper}>
        <ProfileFieldInput
          type={ProfileValueType.LastName}
          value={lastName}
          i18nPrefix="profile.fields.lastName"
          validateValue={(value: string): boolean => value?.trim().length > 0 && isValidRusNamePart(value)}
          validate={firstValidation}
          specialErrorCode={getNameSpecialErrCode('profile.fields.lastName', lastName?.value)}
          updatedGoal={Goal.profile.LastNameUpdated}
          onBlur={onLastNameUpdated}
          onChanged={closeLastNameTooltip}
        />
        {lastNameIsName &&
          <Alert severity="warning" className={classes.tooltip} onClick={closeLastNameTooltip}>
            <FormattedMessage id="profile.fields.lastName.maybe_name" />
          </Alert>
        }
      </div>
      <div className={classes.wrapper}>
        <ProfileFieldInput
          type={ProfileValueType.FirstName}
          value={firstName}
          i18nPrefix="profile.fields.firstName"
          validateValue={(value: string): boolean => value?.trim().length > 0 && isValidRusNamePart(value)}
          validate={firstValidation}
          specialErrorCode={getNameSpecialErrCode('profile.fields.firstName', firstName?.value)}
          updatedGoal={Goal.profile.NameUpdated}
          onBlur={onFirstNameUpdated}
          onChanged={closeFirstNameTooltip}
        />
        {firstNameSuggest &&
          <Alert severity="warning" className={classes.tooltip} onClick={closeFirstNameTooltip}>
            <FormattedMessage
              id="profile.fields.firstName.suggest_name"
              values={{
                name: firstNameSuggest
              }}
            />
          </Alert>
        }
      </div>

      <div className={classes.middleNameWrapper}>

        <ProfileFieldInput
          type={ProfileValueType.MiddleName}
          value={middleName}
          i18nPrefix="profile.fields.middleName"
          validateValue={(value: string): boolean => (needMiddleName? value?.trim().length > 0 : true) && isValidRusNamePart(value)}
          validate={firstValidation}
          specialErrorCode={getNameSpecialErrCode('profile.fields.middleName', middleName?.value)}
          specialLabelCode={!needMiddleName? 'profile.fields.middleName.no_value' : undefined}
          disabled={!needMiddleName}
          showErrorDirectly={showMiddleNameError}
          updatedGoal={Goal.profile.MiddleNameUpdated}
        />

        <span className={classes.middleNameMode}>
          <a href="#" className={classes.middleNameModeLink} onClick={changeMiddleNameMode}>
            <span style={{ borderBottom: 'dashed 1px rgba(20, 18, 51, 0.6)' }}>
              <FormattedMessage id={needMiddleName? 'profile.fields.middleName.need_skip' : 'profile.fields.middleName.cannot_skip'} />
            </span>
          </a>
        </span>

      </div>

      <DateFieldInput
        InputAttrs={DigitsKeyboardAttrs}
        value={{
          value: birthDay?.value ? new Date(birthDay.value) : undefined,
          hasError: !!birthDay?.hasError
        }}
        maxDate={maxSelectDate}
        minDate={minSelectDate}
        messages={{
          invalid: <FormattedMessage id="profile.fields.birthDay.errors.invalid" />,
          empty: <FormattedMessage id="profile.fields.birthDay.errors.empty" />,
          max: <FormattedMessage id="profile.fields.birthDay.errors.max" values={{ year: 14 }} />,
          min: <FormattedMessage id="profile.fields.birthDay.errors.min" />,
          placeholder: formatMessage({ id: 'profile.fields.birthDay.title' }),
        }}
        onChange={async (value?: Date): Promise<void> => {

          const isValidDate = value && ! Number.isNaN(value.getTime());
          const yearDay = value && isValidDate? DateUtil.formatToISOYearDay(value) : '';

          const hasError = !isValidDate
            || yearDay > maxSelectYearDay
            || yearDay < minSelectYearDay;

          await Action.profile.Set({
            value: !hasError ? yearDay : undefined,
            hasError,
            type: ProfileValueType.BirthDay,
          })(dispatch);

          if(!firstValidation && !hasError){
            Metrika.reach(Goal.profile.BirthDayUpdated);
          }

        }}
        validate={firstValidation}
      />
      <ProfileFieldInput
        InputAttrs={DigitsKeyboardAttrs}
        type={ProfileValueType.Passport}
        value={passport}
        i18nPrefix="profile.fields.passport"
        inputComponent={PassportMaskInput}
        normalizeValue={(value: string): string => value.replace(/[^\d]/g, '')}
        validateValue={(value: string): boolean => value.replace(/[^\d]/g, '').length === 10}
        validate={firstValidation}
        updatedGoal={Goal.profile.PassportUpdated}
      />
    </MainLayout>
  );
};

export default ProfilePage;
