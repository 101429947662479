import moment from 'moment';
import React, { createRef, ReactNode, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import MomentUtils from '@date-io/moment';
// eslint-disable-next-line import/no-unresolved
import { DateType } from '@date-io/type';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import { Value } from '@/model/common';
import { Util } from '@/utils/util';

type DateFieldInputProps = {
  InputAttrs?: any,
  value?: Value<Date>;
  onChange: (value?: Date) => Promise<void> | void;
  messages?: {
    invalid?: ReactNode;
    max?: ReactNode;
    min?: ReactNode;
    empty?: ReactNode;
    placeholder?: string;
  };
  minDate?: Date;
  maxDate?: Date;
  disables?: {
    future?: boolean;
    past?: boolean;
  };
  validate?:boolean;
};

const DateFieldInput: React.FC<DateFieldInputProps> = ({
  InputAttrs,
  value,
  onChange,
  messages,
  disables,
  minDate,
  maxDate,
  validate,
}: DateFieldInputProps) => {

  const inputValue = value?.value;
  const hasError = value?.hasError;

  const ref = createRef<any>();
  const [localValue, setLocalValue] = useState<any>(inputValue || null);
  const [canShowError, setCanShowError] = useState(!!(validate && inputValue));
  const [valueChanged, setValueChanged] = useState(false);
  const [focusReturned, setFocusReturned] = useState(false);

  const showError = canShowError && hasError;

  if(validate && inputValue){
    onChange(inputValue);
  }

  // for hide error if user start update input
  useEffect(()=>{
    if(focusReturned && valueChanged){
      setFocusReturned(false);
      setValueChanged(false);
      setCanShowError(false);
    }
  }, [focusReturned, valueChanged]);

  // extra props fot input field
  useEffect(()=>{
    if(InputAttrs && ref.current){
      Object.keys(InputAttrs).forEach(key => {
        ref.current.setAttribute(key, InputAttrs[key]);
      });
    }
  }, [InputAttrs, ref]);

  const handleChange = (v: DateType | null, inputStr?: string | null) => {

    // bugfix: emulate "empty date message" by invalidDateMessage message
    setLocalValue(Util.isBlank(inputStr)? '' : v);

    onChange(v && v.isValid()? v.toDate() : undefined);
    setValueChanged(true);
  };

  const handleBlur = () => {

    setCanShowError(true);
    setValueChanged(false);

    // bugfix: emulate "empty date message" by invalidDateMessage message
    if(Util.isEmpty(localValue)) {
      setLocalValue('')
      onChange(undefined)
    }
  };

  const handleFocus = () =>{
    setFocusReturned(true);
  }

  const invalidDateMessage = showError?
    (messages?.invalid || <FormattedMessage id="common.components.date.errors.invalid" />) : '';
  const maxDateMessage = showError?
    (messages?.max || <FormattedMessage id="common.components.date.errors.max" />) : '';
  const minDateMessage = showError?
    (messages?.min || <FormattedMessage id="common.components.date.errors.min" />) : '';

  return (
    <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
      <KeyboardDatePicker
        inputRef={ref}
        error={showError}
        // helperText // cannot use helperText for "empty date message": it overrides other error messages
        invalidDateMessage={invalidDateMessage}
        maxDateMessage={maxDateMessage}
        minDateMessage={minDateMessage}
        style={{flexShrink: 0}}
        maxDate={maxDate}
        minDate={minDate}
        inputVariant="filled"
        InputProps={{
          disableUnderline: true,
        }}
        clearLabel={<FormattedMessage id="common.components.date.buttons.clear" />}
        okLabel={<FormattedMessage id="common.components.date.buttons.ok" />}
        cancelLabel={<FormattedMessage id="common.components.date.buttons.cancel" />}
        clearable
        disableFuture={!!disables?.future}
        disablePast={!!disables?.past}
        variant="dialog"
        format="DD/MM/yyyy"
        value={localValue}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        label={messages?.placeholder}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DateFieldInput;
