import { call, putResolve, select, takeEvery } from 'redux-saga/effects';

import { withErrorHandler } from '@/utils/redux/action-creator';

import { Action } from '@/model/actions';
import { RootState } from '@/model/types';
import { isAgreementDone, storeAgreementDone } from '@/api/agreement';
import { Config } from '@/config';

function* storeAgreement(): Generator {

  const rootState = (yield select((state: RootState) => state)) as RootState;
  const phone = rootState.remoteState.data?.profile?.phone?.number;

  if(phone)
    yield call(storeAgreementDone, phone);
}


function* restoreAgreement(): Generator {

  const rootState = (yield select((state: RootState) => state)) as RootState;
  const phone = rootState.remoteState.data?.profile?.phone?.number;

  const done = phone? (yield call(isAgreementDone, phone)) as boolean : false;
  yield putResolve(Action.agreement.SetDone({done: Config.DefaultAgreement || done}).pure);
}


export default function* rootSaga(): Generator {

  yield takeEvery(Action.agreement.StoreDone.type,
    withErrorHandler(
      storeAgreement));

  yield takeEvery(Action.agreement.LoadDoneState.type,
    withErrorHandler(
      restoreAgreement
    ));
}
