import { actionCreator } from '@/utils/redux/action-creator';
import { VerificationStatus } from './types';


export type SaveVerificationResultActionPayload = { status: VerificationStatus };


const creator = actionCreator('VERIFICATION');

export const VerificationAction = {
  Start:      creator.asyncAction<void>('START'),
  Repeat:     creator.asyncAction<void>('REPEAT'),
  WaitStatus: creator.syncAction('WAIT_STATUS'),
  SetStatus:  creator.asyncActionWithPayload<SaveVerificationResultActionPayload, void>('SET_STATUS'),
};
