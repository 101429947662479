import { InputLabel } from '@material-ui/core';
import React, { PropsWithChildren } from 'react';

type LabelProps = PropsWithChildren<{ className?: string }>;

export const Label: React.FC<LabelProps> = ({ className, children }: LabelProps) => (
  <InputLabel className={className} filled style={{ fontSize: '14px', lineHeight: '20px' }}>
    {children}
  </InputLabel>
);

export default Label;
