import { makeStyles } from '@material-ui/styles';
import { Checkbox, createStyles, FormControlLabel, TextField, Theme } from '@material-ui/core';
import React, { createRef, forwardRef, ReactNode, useEffect, useState } from 'react';
import { InputBaseComponentProps } from '@material-ui/core/InputBase/InputBase';
import { Value as DocumentValue } from '@/model/documents/types';
import { FormattedMessage } from 'react-intl';

import { Space } from '@/components';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    checkboxFormControl: {
      marginLeft: 0,
      '& .MuiFormControlLabel-label': {
        paddingLeft: theme.spacing(1.5),
      },
    },
    checkbox: {
      padding: 0,
    },
    checkboxContainer: {
      paddingTop: theme.spacing(1.5),
      borderRadius: '8px',
      flex: 0,
      flexShrink: 0,
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      backgroundColor: theme.activeElementBackground,
      marginBottom: theme.formLayout.baseElement.marginBottom,
    },
    input: {
      backgroundColor: '#E9E9F2 !important',
    },
  }),
);

type DocumentFieldInputProps = {
  InputAttrs?: any,
  inputComponent?: React.ElementType<InputBaseComponentProps>;
  value?: DocumentValue;
  placeholder?: string;
  invalidMessage: ReactNode;
  ref?: React.Ref<any>;
  onChange: (value: string) => Promise<void> | void;
  validate?: boolean;
};

const DocumentFieldInput: React.FC<DocumentFieldInputProps> = forwardRef((
  {
    InputAttrs,
    inputComponent,
    invalidMessage,
    onChange,
    placeholder,
    value,
    validate,
  }: DocumentFieldInputProps,
  ref) => {
  const classes = useStyles();

  const hasError = !!value?.hasError;
  const inputValue = value?.value;
  const [canShowError, setCanShowError] = useState(!!(validate && inputValue));
  const [firstFocus, setFirstFocus] = useState(true);
  const showError = canShowError && hasError;

  if(validate && inputValue){
    onChange(inputValue);
  }

  useEffect(()=>{
    if(firstFocus){
      (ref as any).current?.focus();
      setFirstFocus(false);
    }

  }, [firstFocus, setFirstFocus, ref]);

  useEffect(()=>{
    const elem = (ref as any).current;
    if(InputAttrs && elem){
      Object.keys(InputAttrs).forEach(key => {
        elem.setAttribute(key, InputAttrs[key]);
      });
    }
  }, [InputAttrs, ref])

  return (
    <TextField
      inputRef={ref}
      variant="standard"
      inputProps={{className: clsx('ym-disable-keys')}}
      InputProps={{ disableUnderline: true, className: classes.input }}
      {...(inputComponent ? { inputComponent } : {})}
      label={placeholder}
      value={inputValue || ''}
      onChange={async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
        setCanShowError(false);
        const inputValue = event.target.value;
        await onChange(inputValue);
      }}
      onBlur={()=>{
        // time for checkbox to remove this component
        // without error blink
        setTimeout(()=>{
          setCanShowError(true)
        }, 200);
      }}
      {...(showError
        ? {
            helperText: invalidMessage,
            error: true,
          }
        : {})}
    />
  );
});

type DocumentCheckboxProps = {
  InputAttrs?: any,
  extraComponent?: ReactNode;
  normalizeValue?: (value: string) => string;
  onChange: ({ value, selected }: { value: string; selected: boolean }) => Promise<void> | void;
  value?: DocumentValue;
  i18nPrefix: string;
  validate?: boolean;
};

const DocumentCheckbox: React.FC<DocumentCheckboxProps> = ({
  InputAttrs,
  extraComponent,
  i18nPrefix,
  normalizeValue = (inputValue: string): string => inputValue,
  onChange,
  value,
  validate,
}: DocumentCheckboxProps) => {
  const classes = useStyles();
  //const { formatMessage } = useIntl();
  const [textValue, setTextValue] = useState<string>(value?.value || '');
  const ref = createRef<any>();

  return (
    <Space direction="vertical" className={classes.checkboxContainer}>
      <FormControlLabel
        className={classes.checkboxFormControl}
        control={
          <Checkbox
            className={classes.checkbox}
            checked={value?.selected || false}
            onChange={(_: React.ChangeEvent<HTMLInputElement>, selected: boolean): void => {
              onChange({ selected, value: normalizeValue(textValue) });
            }}
          />
        }
        label={<FormattedMessage id={`${i18nPrefix}.title`} />}
      />
      {value?.selected && (
        <>
          <DocumentFieldInput
            InputAttrs={InputAttrs}
            ref={ref}
            value={value}
            onChange={(inputValue): void => {
              setTextValue(inputValue);
              onChange({ selected: true, value: normalizeValue(inputValue) });
            }}
            // placeholder={formatMessage({ id: `${i18nPrefix}.title` })}
            invalidMessage={<FormattedMessage id={`${i18nPrefix}.invalid`}/>}
            validate={validate}
          />
          {extraComponent}
        </>
      )}
    </Space>
  );
};

export default DocumentCheckbox;
