import React, { ReactNode } from 'react';

import { MainLayout } from '@/layout';
import { FormattedMessage } from 'react-intl';
import { Link, Typography } from '@material-ui/core';
import { Config } from '@/config';

const supportEmail = Config.SupportEmail;

export const InitErrorPage: React.FC = () => {

  return (
    <MainLayout title={<FormattedMessage id="init_error.title" />}>
      <Typography>
        <FormattedMessage
          id="init_error.description"
          values={{
            link: (content: string): ReactNode => <Link href={`mailto:${supportEmail}`}>{content}</Link>,
          }}
        />
      </Typography>
    </MainLayout>
  );
};

export default InitErrorPage;
