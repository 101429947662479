import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/model/types';
import { ProfilePhoneStatus, RemoteState, RemoteStateStatus, WorkMode } from '@/model/remote_state/types';
import { InitErrorPage } from '@/pages';
import { AppPath } from '@/App';
import { StringUtil } from '@/utils/string-util';
import { Action } from '@/model/actions';
import { documentByPath } from '@/pages/AgreementDocumentPage/types';
import { VerificationStatus } from '@/model/verification/types';
import { Util } from '@/utils/util';
import { MobileIdStatus } from '@/model/mobileId/types';
import { PhoneOperator } from '@/model/Phone';
import { Lang } from '@/model/Lang';
import { useIntl } from 'react-intl';
import { ModalQueryFlag } from '@/model/Modal';
import { Config } from '@/config';
import ServiceMaintenancePage from '@/pages/ServiceMaintenancePage';
import { Goal, Metrika } from '@/api/metrika';
import { history } from '@/hocs/withStore/configureStore';


const log = Util.getLog('hocs/withRemoteState');
const emptyRender = (<></>);

// protection from second redirect ( sometimes this hoc called on Action.global.Transmit )
let canRedirect = true;

const withProfileInit = (Component: React.FC) => (props: any) => {

  const skipRemoteState = isAgreementDocument();

  if(skipRemoteState)
    return (
      <>
        <Component {...props} />
      </>
    );

  const dispatch = useDispatch();
  const rootState = useSelector((state: RootState) => state);
  const {remoteState, agreement, verification, mobileId, params} = rootState;
  const lang = Lang.toLang(useIntl().locale);
  const paramsLoaded = params.loaded;

  const {status} = remoteState;
  const isError = status === RemoteStateStatus.Error;
  const inServiceMaintenance = Config.InServiceMaintenance;

  // loaded flags
  const remoteStateAnyLoaded = isError? true : status !== RemoteStateStatus.NotLoaded;
  const agreementLoaded = isError? true : agreement.done !== undefined;
  const mobileIdStatusLoaded = isError? true : mobileId.status !== undefined;

  const dataReady = remoteStateAnyLoaded
    && agreementLoaded
    && mobileIdStatusLoaded
    && paramsLoaded;

  useEffect(()=>{

    if(!paramsLoaded)
      return;

    // load remote state
    if( !remoteStateAnyLoaded) {
      Action.global.SetLang(lang)(dispatch);
      Action.remoteState.Load()(dispatch);
    }

    // restore agreement status
    if( remoteStateAnyLoaded && !agreementLoaded ){
      Action.agreement.LoadDoneState()(dispatch);
    }

    // restore mobileId status
    if( remoteStateAnyLoaded && !mobileIdStatusLoaded ){
      Action.mobileId.RestoreState()(dispatch);
    }

  }, [
    paramsLoaded,
    remoteStateAnyLoaded,
    agreementLoaded,
    mobileIdStatusLoaded,
    lang,
    dispatch
  ]);

  useEffect(()=>{

    if(isError)
      Metrika.reachOnce(Goal.err.InvalidToken);

    if(inServiceMaintenance)
      Metrika.reachOnce(Goal.err.Maintenance);

  }, [isError, inServiceMaintenance])


  if(isError){
    return (<InitErrorPage/>);
  }

  // wait
  if( !dataReady) {
    return emptyRender;
  }

  if(inServiceMaintenance){
    return (<ServiceMaintenancePage/>);
  }

  const appPath = getAppPath();
  const verificationStatus = verification.status || VerificationStatus.NotVerified;
  const phoneStatus = getPhoneStatus(remoteState);
  const agreementDone = !!agreement.done;
  const mobileIdStatus = mobileId.status || MobileIdStatus.NeedSelect;
  const mobileIdOperator = mobileId.operator || PhoneOperator.Unknown;
  const workMode = getWorkMode(remoteState);
  const isPersona = isPersonified(remoteState);
  const needPhoneExtraCheck = remoteState.data?.needPhoneExtraCheck === true;

  const regState = isRegistrationState(phoneStatus, verificationStatus);

  const agreementState = isNeedAgreementState(
    phoneStatus,
    verificationStatus,
    agreementDone,
    needPhoneExtraCheck);

  const mobileIdState = isMobileIdState(
    phoneStatus,
    verificationStatus,
    agreementDone,
    mobileIdStatus,
    mobileIdOperator,
    workMode,
    isPersona);

  const profileState = isFillProfileState(
    phoneStatus,
    verificationStatus,
    agreementDone,
    mobileIdStatus,
    mobileIdOperator,
    workMode,
    isPersona);

  const verificationState = isVerificationState(verificationStatus, isPersona);

  if(canRedirect)
    log.info('states', {
      regState,
      agreementState,
      mobileIdState,
      profileState,
      verificationState
    });

  const statePath = getStatePath(AppPath.Registration, regState)
    || getStatePath(AppPath.Agreement, agreementState)
    || getStatePath(AppPath.MobileId, mobileIdState)
    || getStatePath(AppPath.Profile, profileState)
    || getStatePath(AppPath.Verification, verificationState);

  // redirect to valid state path
  if(canRedirect
      && statePath
      && appPath !== statePath){
    canRedirect = false;
    history.replace(statePath);
    return emptyRender;
  }

  // App is ready: redirects not allowed
  canRedirect = false;

  return (
    <>
      <Component {...props} />
    </>
  );
};


function getStatePath(targetPath: string, isTargetPath: boolean): string | null {
  return isTargetPath? targetPath : null;
}


function isRegistrationState(phoneStatus: ProfilePhoneStatus, verificationStatus: VerificationStatus): boolean {
  return phoneStatus === ProfilePhoneStatus.NotVerified
    && (verificationStatus === VerificationStatus.NotVerified
        || verificationStatus === VerificationStatus.VerificationFailed);
}

function isMobileIdState(
  phoneStatus: ProfilePhoneStatus,
  verificationStatus: VerificationStatus,
  agreementDone: boolean,
  mobileIdStatus: MobileIdStatus,
  mobileIdOperator: PhoneOperator,
  workMode: WorkMode,
  isPersona: boolean,
): boolean {

  if(isPersona)
    return false;

  // no mobile id on simple mode
  if(workMode === WorkMode.Simple)
    return false;

  // no profile on full mode
  if(workMode === WorkMode.Full){
    return phoneStatus === ProfilePhoneStatus.Verified
      && agreementDone
  }

  // mobile or profile
  return phoneStatus === ProfilePhoneStatus.Verified
    && verificationStatus === VerificationStatus.NotVerified
    && agreementDone
    && mobileIdStatus !== MobileIdStatus.Skip
    && mobileIdOperator !== PhoneOperator.Unknown;
}

function isFillProfileState(
  phoneStatus: ProfilePhoneStatus,
  verificationStatus: VerificationStatus,
  agreementDone: boolean,
  mobileIdStatus: MobileIdStatus,
  mobileIdOperator: PhoneOperator,
  workMode: WorkMode,
  isPersona: boolean,
): boolean {

  if(isPersona)
    return false;

  // no profile on full mode
  if(workMode === WorkMode.Full)
    return false;

  // no mobile id on simple mode
  if(workMode === WorkMode.Simple){
    return phoneStatus === ProfilePhoneStatus.Verified
      && verificationStatus === VerificationStatus.NotVerified
      && agreementDone;
  }

  // mobile or profile
  return phoneStatus === ProfilePhoneStatus.Verified
    && verificationStatus === VerificationStatus.NotVerified
    && agreementDone
    && (mobileIdStatus === MobileIdStatus.Skip
        || mobileIdOperator === PhoneOperator.Unknown);
}

function isNeedAgreementState(
  phoneStatus: ProfilePhoneStatus,
  verificationStatus: VerificationStatus,
  agreementDone: boolean,
  needPhoneExtraCheck: boolean,
) {
  return phoneStatus === ProfilePhoneStatus.Verified
    && verificationStatus === VerificationStatus.NotVerified
    && (needPhoneExtraCheck || !agreementDone);
}

function isVerificationState(
  verificationStatus: VerificationStatus,
  isPersona: boolean
) {
  return isPersona || verificationStatus !== VerificationStatus.NotVerified;
}


function getPhoneStatus(state: RemoteState): ProfilePhoneStatus {
  return state.data?.profile?.phone?.status || ProfilePhoneStatus.NotVerified;
}

function isAgreementDocument(): boolean{
  const path = window.location.pathname;
  const isModal = window.location.search === ModalQueryFlag;
  return !isModal && documentByPath(path) !== undefined;
}

function getWorkMode(state: RemoteState): WorkMode {
  return state.data?.workMode || WorkMode.Any;
}

function isPersonified(state:RemoteState): boolean {
  return state.data?.isPersonified || false;
}

function getAppPath() {

  const path = StringUtil.removeBegin(window.location.pathname, '/');

  let appPath = path.split('/')[0];
  appPath = `/${appPath}`;

  return appPath;
}

export default withProfileInit;