import { actionCreator } from '@/utils/redux/action-creator';

import { RemoteStateData, RemoteStateStatus } from './types';

export type RemoteStateResp = {
  status: RemoteStateStatus,
  data?: RemoteStateData
}

const creator = actionCreator('REMOTE_STATE');

export const RemoteAction = {
  Load:   creator.asyncAction('LOAD_STATE'),
  Set:    creator.syncActionWithPayload<RemoteStateResp>('SET_STATE'),
  Reload: creator.asyncAction('RELOAD_STATE'),
};
