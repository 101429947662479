import { call, put, select, takeEvery } from 'redux-saga/effects';

import { withErrorHandler } from '@/utils/redux/action-creator';
import { UIState } from '@/model/global/types';

import { Action } from '@/model/actions';
import { queryFindInn, queryPutPassport, queryPutUserInfo } from '@/api/profile';
import { RootState, skipInn } from '@/model/types';
import { withGlobalLock } from '@/model/global/sagas';
import { Util } from '@/utils/util';
import { ExtraDocValueType } from '@/model/documents/actions';
import { Goal, Metrika } from '@/api/metrika';
import { StartVerificationContext } from '@/model/verification/sagas';
import { VerificationStatus } from '@/model/verification/types';

function* submitProfile(): Generator {

  const rootState = (yield select((state: RootState) => state)) as RootState;
  const {profile, verification: {status: oldVerificationStatus}} = rootState;
  const {userInfoChanged, passportChanged} = profile;
  const firstName = profile.firstName?.value;
  const lastName = profile.lastName?.value;
  const middleName = profile.middleName?.value;
  const birthDay = profile.birthDay?.value;
  const passport = profile.passport?.value;

  // skipping auto find inn if user already got a failed verification before
  const tryToFindInn = ! skipInn(rootState)
      && oldVerificationStatus !== VerificationStatus.VerificationFailed;

  let successUserUpdated = false;
  let successPassportUpdated = false;

  const allValuesSet = ! Util.isBlank(firstName)
    && ! Util.isBlank(lastName)
    && ! Util.isBlank(middleName)
    && ! Util.isBlank(birthDay)
    && ! Util.isBlank(passport);

  const callToFindInn = allValuesSet
    && tryToFindInn
    && (userInfoChanged || passportChanged);

  const putUser = async (): Promise<any> => {

      const out = await queryPutUserInfo({
        firstName: firstName || '',
        lastName: lastName || '',
        middleName,
        birthDay,
      });

      successUserUpdated = true;
      mayBeSendUpdatedMetrika();

      return out;
  };

  const putPassport = async (): Promise<any> => {
    if(passportChanged){

      const out = await queryPutPassport(passport);

      successPassportUpdated = true;
      mayBeSendUpdatedMetrika();

      return out;
    }
    return true;
  };

  const mayBeSendUpdatedMetrika = ()=> {
    // user and passport updated
    if(userInfoChanged
      && passportChanged
      && successUserUpdated
      && successPassportUpdated){
        Metrika.reach(Goal.profile.Updated);
    }
    // user updated, passport the same
    else if(userInfoChanged
      && ! passportChanged
      && successUserUpdated){
      Metrika.reach(Goal.profile.Updated);
    }
    // user the same, passport updated
    else if( ! userInfoChanged
      && passportChanged
      && successPassportUpdated){
      Metrika.reach(Goal.profile.Updated);
    }
  }

  const findInn = async () => {
    if(callToFindInn)
      return queryFindInn({firstName, lastName, middleName, birthDay, passport} as any);
    else
      return null;
  };

  const [innResult, infoResult, passportResult] = (yield call(async () => {
    return Promise.allSettled([
      findInn(),
      putUser(),
      putPassport(),
    ]);
  })) as any[];

  const savedErrors = [];

  if( ! Util.isEmpty(infoResult.value)){
    yield put(Action.profile.UserInfoUpdated().pure);
  } else {
    savedErrors.push(infoResult.reason);
  }

  if( ! Util.isEmpty(passportResult.value)){
    yield put(Action.profile.PassportUpdated().pure);
  } else {
    savedErrors.push(passportResult.reason);
  }

  if(savedErrors.length > 0){
    Metrika.reach(Goal.err.ProfileUpdate);
  }

  // nothing saved
  if(savedErrors.length === 2) {
    throw savedErrors[0];
  }

  // part saved: show message about it
  if(savedErrors.length === 1) {
    yield put(Action.global.AddError({ messageId: 'profile.errors.partly_saved' }).pure);
  }

  // all saved and got inn:
  // set inn to model and start verification
  if(savedErrors.length === 0
      && ! Util.isEmpty(innResult.value)){

    const taxCode: string = innResult.value;

    yield put(Action.document.Set({
      selected: true,
      value: taxCode,
      type: ExtraDocValueType.TAX_ID,
      hasError: false,
    }).pure);

    StartVerificationContext.withAutoInn = true;

    yield put(Action.document.SaveAndStartVerification().pure);
  }
  // fill extra docs by user
  else {

    if(callToFindInn){
      const taxIdServiceUnavailable = ! Util.isEmpty(innResult.reason);

      if(taxIdServiceUnavailable){
        Metrika.reach(Goal.err.GetInn);
      }

      yield put(Action.document.SetTaxIdServiceUnavailable(taxIdServiceUnavailable).pure);
    }

    yield put(Action.global.Transmit({ state: UIState.DOCUMENTS }).pure);
  }

}

export default function* rootSaga(): Generator {
  yield takeEvery(Action.profile.Save.type,
    withGlobalLock(
      withErrorHandler(
        submitProfile
      )));
}
