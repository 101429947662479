import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Typography, useTheme } from '@material-ui/core';

import { useTimer } from '@/hooks';
import { MainLayout } from '@/layout';
import { RootState } from '@/model/types';
import { ConfirmationState } from '@/model/confirmation/types';
import { BackButton } from '@/components';
import { conformToMask } from 'react-text-mask';
import { phoneMask } from '@/components/PhoneInput';
import { Action } from '@/model/actions';

import { ConfirmationCodeInput } from './components';
import { Goal, Metrika } from '@/api/metrika';

export const ConfirmationPage: React.FC = () => {
  const classes = useTheme();
  const dispatch = useDispatch();

  const [valid, setValid] = useState<boolean>(false);
  const [errorId, setErrorId] = useState<string | undefined>();
  const [code, setCode] = useState<string | undefined>();
  const [focusOn, setFocusOn] = useState(true);

  const rootState = useSelector((state: RootState) => state);
  const phone = rootState.global.phone?.value;
  const { state, validTill, reqId } = rootState.confirmation;

  const isDone = state === ConfirmationState.PASSED;
  const canSend = valid && !errorId && state !== ConfirmationState.ATTEMPTS_NUMBER_EXHAUSTED;
  const disabled = state === ConfirmationState.ATTEMPTS_NUMBER_EXHAUSTED;
  const [secondsTilRetry] = useTimer(validTill);

  useEffect(() => {
    if (state === ConfirmationState.WRONG_CODE) {
      setErrorId('confirmation.errors.wrong.code');
    } else if (state === ConfirmationState.ATTEMPTS_NUMBER_EXHAUSTED) {
      setErrorId('confirmation.errors.attempts.number.exhausted');
    } else {
      setErrorId(undefined);
    }
  }, [state]);

  useEffect(()=>{
    if(focusOn){
      setFocusOn(false);
    }
  }, [focusOn]);

  const submit = async ()=> {
    const sendCode = code? code.trim() : '';
    if (canSend && reqId && sendCode.length === 6) {
      await Action.confirmation.CheckCode({ reqId, code: sendCode })(dispatch);
    }
  }

  return (
    <MainLayout
      title={<FormattedMessage id="confirmation.title" />}
      buttons={{ leftTitle: BackButton }}
      height={470}
      submit={{
        onClick: submit,
        title: <FormattedMessage id="confirmation.submit.title" />,
        disabled: isDone || !valid || !!errorId,
      }}
    >
      <Typography variant="body1" style={{ ...classes.formLayout.subElement }}>
        <FormattedMessage id="confirmation.code.description" />
      </Typography>
      <Typography
        variant="body1"
        style={{
          ...classes.formLayout.subElement,
          fontWeight: 500,
          fontSize: '16px',
          marginBottom: '22px'
        }}>
        {phone && conformToMask(`+${phone}`, phoneMask, {}).conformedValue}
      </Typography>
      <ConfirmationCodeInput
        value={code}
        disabled={disabled}
        focusOn={focusOn}
        errorId={errorId}
        onEnter={submit}
        onChange={async (newCode: string): Promise<void> => {

          setCode(newCode);
          setValid(newCode.replace(/[^\d]/g, '').length === 6);

          if (state === ConfirmationState.WRONG_CODE) {
            await Action.confirmation.support.SetState({ state: ConfirmationState.SENT })(dispatch);
          }
        }}
      />
      {secondsTilRetry && secondsTilRetry > 0 ? (
        <Typography variant="body1" style={{ textAlign: 'center' }}>
          <FormattedMessage
            id="confirmation.repeat.description"
            values={{
              timer: (
                <strong>{moment.utc(moment.duration(secondsTilRetry, 's').asMilliseconds()).format('mm:ss')}</strong>
              ),
            }}
          />
        </Typography>
      ) : (
        <Link
          onClick={async (): Promise<void> => {

            Metrika.reach(Goal.phone.ResendCode);

            await Action.confirmation.Send()(dispatch);

            setCode('');
            setErrorId(undefined);
            setValid(false);

            setTimeout(()=>{
              setFocusOn(true);
            }, 2000);
          }}
          href="#"
          style={{
            textAlign: 'center',
            fontSize: '15px'
          }}
        >
          <FormattedMessage id="confirmation.repeat.link" />
        </Link>
      )}
    </MainLayout>
  );
};

export default ConfirmationPage;
