import clsx from 'clsx';
import React, { MouseEvent, PropsWithChildren, ReactNode } from 'react';
import { useSelector } from 'react-redux';

import { Box, Button, Link, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';

import { Space } from '@/components';
import { BaseContainer, HeaderBox } from '@/layout/components';
import { BaseContainerProps } from '@/layout/components/BaseContainer';
import { HeaderBoxProps } from '@/layout/components/HeaderBox';
import { RootState } from '@/model/types';
import { StubPartner } from '@/model/remote_state/types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Util } from '@/utils/util';
import PartnerTitle from '@/components/PartnerTitle';
import { isFrameMode } from '@/hocs/withIFrameDetect';
import { getThemeValues } from '@/hocs/withTheme';

const useStyles = makeStyles((theme: Theme) => {

  const values = getThemeValues();

  const button = {
    marginBottom: theme.spacing(2),
    borderRadius: '12px',
    padding: 0,
    fontSize: '18px',
    lineHeight: `${theme.spacing(7)}px`,
  };

  return {
    header: {
      paddingTop: theme.spacing(),
      marginBottom: theme.spacing(3),
    },
    footer: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      paddingBottom: theme.spacing(2),
    },
    afterSubmit: {
      justifyContent: 'center',
    },
    titleButton: {
      position: 'absolute',
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(0.5),
    },
    mainBox: {
      width: theme.MainContainer.width,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto',
    },
    withFooter: {
      justifyContent: 'space-between'
    },
    mainContent: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      marginBottom: '20px',
      overflowX: 'auto',
      display: 'flex',
      flexDirection: 'column',
      flexShrink: 0,
    },
    buttonEnabled: {
      ...button,
      background: `${values.mainColor} !important`,
      borderColor: `${values.mainColor} !important`,
      color: theme.palette.secondary.contrastText,
    },
    buttonDisabled: {
      ...button,
    },
  };
});

type SubmittableProps = PropsWithChildren<{
  onSubmit?: () => void | Promise<void>;
  mainContentClassName?:string,
}>;

const Submittable: React.FC<SubmittableProps> = (
  {
    onSubmit,
    children,
    mainContentClassName,
  }: SubmittableProps
) => {
  const classes = useStyles();
  return onSubmit ? (
    <form
      className={clsx(classes.mainBox, classes.withFooter, 'x-main-content', mainContentClassName)}
      onSubmit={(e): void => {
        e.stopPropagation();
        e.preventDefault();
        onSubmit();
      }}
    >
      {children}
    </form>
  ) : (
    <Box className={clsx(classes.mainBox, classes.withFooter, 'x-main-content', mainContentClassName)}>{children}</Box>
  );
};

export type MainLayoutProps = Omit<BaseContainerProps, 'children'> &
  HeaderBoxProps &
  PropsWithChildren<{
    footer?: {
      beforeSubmit?: ReactNode;
      afterSubmit?: ReactNode;
      className?: string;
    };
    submit?: {
      title: ReactNode;
      onClick: () => void | Promise<void>;
      disabled?: boolean;
    };
    childrenParentClassName?: string,
    headerClassName?: string,
    mainContentClassName?: string,
  }>;

// const selectPartner = makeSelectPartner();

export const MainLayout: React.FC<MainLayoutProps> = ({
  title,
  children,
  buttons,
  submit,
  height,
  footer,
  childrenParentClassName,
  headerClassName,
  mainContentClassName,
  ...props
}: MainLayoutProps) => {

  const { formatMessage } = useIntl();
  const remoteData = useSelector((state: RootState) => state.remoteState.data);
  const partner = remoteData?.partner || StubPartner;
  const hasReturnUrl = ! isFrameMode() && ! Util.isBlank(partner.returnUrl);

  const classes = useStyles();
  const baseClasses = useTheme();
  // const partner = useSelector(selectPartner);
  const headerHeight = 68;
  const footerHeight = 32;

  const mainHeight = (height && height !== 'unset' ? height : baseClasses.MainContainer.height)
    + (partner?.name ? headerHeight : 0)
    + (footer ? footerHeight : 0)
    + (!submit ? -30 : 0);

  return (
    <BaseContainer
      {...props}
      height={mainHeight}
    >
      <HeaderBox
        title={title}
        buttons={buttons}
        prefixTitle={remoteData?.partner? <PartnerTitle/> : undefined}
        headerClassName={headerClassName}
      />
      <Submittable
        onSubmit={submit?.onClick}
        mainContentClassName={mainContentClassName}>
        <Space direction="vertical" size={3} className={clsx(classes.mainContent, childrenParentClassName)}>
          {children}
        </Space>
        <Space direction="none" size={2} className={clsx(classes.footer, footer?.className)}>
          {footer?.beforeSubmit && <Space size={0}>{footer.beforeSubmit}</Space>}
          {submit && (
            <Button
              className={submit.disabled ? classes.buttonDisabled : classes.buttonEnabled}
              variant="outlined"
              color="primary"
              type="submit"
              disabled={submit.disabled}
            >
              {submit.title}
            </Button>
          )}
          {footer?.afterSubmit && (
            <Space size={0} className={classes.afterSubmit}>
              {footer.afterSubmit}
            </Space>
          )}
          {hasReturnUrl && (
            <Link
              style={{
                display: 'block',
                textAlign: 'center',
                color: '#141233',
                textDecoration: 'none',
                margin: '4px 0px 16px'
              }}
              data-test="back-to-partner"
              onClick={(e: MouseEvent<any>) => {
                e.preventDefault();
                if (window.confirm(formatMessage({id: 'common.back_confirm'}))) {
                  window.location.href = partner?.returnUrl;
                }
              }}
              href="#"
            >
              <span style={{ borderBottom: 'solid 1px rgba(20, 18, 51, 0.6)' }}>
                <FormattedMessage id="common.back_to_partner_link" />
              </span>
            </Link>
          )}
        </Space>
      </Submittable>
    </BaseContainer>
  );
};

export default MainLayout;
