import { ActionWithPayload } from '@/utils/redux/action-creator';

import { createReducer } from '@/utils/redux/reducer-util';
import { Action } from '@/model/actions';
import { initialState, DocumentState } from './types';
import { SaveValueActionPayload, ExtraDocValueType } from './actions';

export const reducers = createReducer<DocumentState>(initialState, {

  [Action.document.ValuesUpdated.type](
    state: DocumentState
  ): DocumentState {
    return {
      ...state,
      changed: false
    }
  },

  [Action.document.Set.type](
    state: DocumentState,
    { payload: { value, type, hasError, selected } }: ActionWithPayload<SaveValueActionPayload>,
  ): DocumentState {
    switch (type) {
      case ExtraDocValueType.SSN:
        return { ...state, changed: true, ssn: { value, hasError, selected } };
      case ExtraDocValueType.TAX_ID:
        return { ...state, changed: true, taxId: { value, hasError, selected } };
      default:
        return state;
    }
  },

  [Action.document.SetTaxIdServiceUnavailable.type](
    state: DocumentState,
    { payload }: ActionWithPayload<boolean>,
  ): DocumentState {
    return {
      ...state,
      taxIdServiceUnavailable: payload,
    }
  },
});

export default reducers;
