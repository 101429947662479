import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import { history } from '@/hocs/withStore/configureStore';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    cursor: 'pointer',
    lineHeight: '0px'
  },
}));

export type BackButtonProps = {
  className?: string;
  onBack?: () => void;
};

export const BackButton: React.FC<BackButtonProps> = ({ className, onBack }: BackButtonProps) => {
  const classes = useStyles();

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <span
      className={clsx(classes.button, className, 'x-back')}
      onClick={onBack || ((): void => history.goBack())}>
      <svg
        width="23"
        height="16"
        viewBox="0 0 23 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M2 8L9 1M2 8L9 15M2 8H22" stroke="#141233" strokeWidth="2" strokeLinecap="round"/>
      </svg>
    </span>
  );
};

export default BackButton;
