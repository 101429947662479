import { put, putResolve, select, takeEvery } from 'redux-saga/effects';

import { withGlobalLock } from '@/model/global/sagas';
import { withErrorHandler } from '@/utils/redux/action-creator';
import { UIState } from '@/model/global/types';
import { RootState } from '@/model/types';
import { ConfirmationState } from '@/model/confirmation/types';

import { Action } from '@/model/actions';

function* registerUser(): Generator {
  yield putResolve(Action.global.CleanErrors().pure);
  yield putResolve(Action.confirmation.Send().pure);
}

function* resetConfirmation(): Generator {

  const rootState = (yield select((state: RootState) => state)) as RootState;
  const {state} = rootState.confirmation;

  if (state === ConfirmationState.INTERNAL_ERROR) {
    yield put(Action.global.Transmit({ state: UIState.REGISTRATION }).pure);
  } else if (state === ConfirmationState.PASSED) {
    yield put(Action.mobileId.ShowMobileIdIfNeed({ refreshMobileIdOperatorFromServer: true }).pure);
  }
}

export default function* rootSaga(): Generator {

  yield takeEvery(
    Action.auth.Registration.type,
    withGlobalLock(
      withErrorHandler(
        registerUser
      )),
  );

  yield takeEvery( Action.confirmation.Reset.type,
    withGlobalLock(
      resetConfirmation));
}
