

export interface State {
  state?: ConfirmationState;
  validTill?: Date;
  reqId?: string;
}

export enum ConfirmationState {
  SENT                      = 'SENT',
  ATTEMPTS_NUMBER_EXHAUSTED = 'ATTEMPTS_NUMBER_EXHAUSTED',
  WRONG_CODE                = 'WRONG_CODE',
  INTERNAL_ERROR            = 'INTERNAL_ERROR',
  PASSED                    = 'PASSED',
}

export const initialState: State = {};
