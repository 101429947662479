import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/model/types';
import { StubPartner } from '@/model/remote_state/types';
import { createRef } from 'react';


const PartnerTitle: React.FC = () => {

  const imgRef = createRef<any>();
  const partner = useSelector((state: RootState) => state.remoteState.data?.partner || StubPartner);
  const {name, iconHasNaming, iconUrl} = partner;
  const hasTitle = !iconUrl || !iconHasNaming;

  return (
    <span
      style={{
        display: 'flex',
        height: '45px',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      {iconUrl &&
        <img
          ref={imgRef}
          src={iconUrl}
          alt=""
          style={{
            height: '44px',
            width: hasTitle? '44px' : '135px',
            marginRight: hasTitle? '5px' : '0px',
            marginLeft: hasTitle? '-22px' : '0px'
          }}
          onError={()=>{
            imgRef.current.src = './none.png'
          }}
        />}
      {hasTitle &&
        <span
          style={{
            display: 'inline-block',
            maxWidth: '300px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}>
          {name}
        </span>}
    </span>
  );
}

export default PartnerTitle;