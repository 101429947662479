import { call, delay, put, select, spawn, takeEvery } from 'redux-saga/effects';
import { Util } from '@/utils/util';
import { Action } from '@/model/actions';
import { getInputOrRestoredPhone, getWorkMode, RootState } from '@/model/types';
import {
  getMobileIdData,
  queryGetMobileIdOperator,
  queryGetMobileIdRequestStatus,
  queryStartMobileId,
  StartMobileIdReq,
  storeMobileIdData,
} from '@/api/mobileId';
import {
  GetPhoneOperatorResp,
  MobileIdData,
  MobileIdReq,
  MobileIdRequestState,
  MobileIdRequestStatus,
  MobileIdStatus,
} from '@/model/mobileId/types';
import { withGlobalLock } from '@/model/global/sagas';
import { PhoneOperator } from '@/model/Phone';
import { UIState } from '@/model/global/types';
import { ActionWithPayload, withErrorHandler } from '@/utils/redux/action-creator';
import { ShowMobileIdIfNeedPayload, WaitResultPayload } from '@/model/mobileId/actions';
import { WorkMode } from '@/model/remote_state/types';
import { ApiError } from '@/model/ApiError';
import { Goal, Metrika } from '@/api/metrika';

const log = Util.getLog('mobileId.sagas');

function* restoreState(): Generator {

  const rootState = (yield select((state: RootState) => state)) as RootState;
  const phone = rootState.remoteState.data?.profile?.phone?.number;

  let data: MobileIdData | undefined;

  if(phone) {
    data = (yield call(getMobileIdData, phone)) as MobileIdData;
  }

  data = data || { status: MobileIdStatus.NeedSelect};

  yield put(Action.mobileId.SetReqId(data.reqId).pure);
  yield put(Action.mobileId.SetStatus(data.status).pure);
}


function* saveState(): Generator {

  const rootState = (yield select((state: RootState) => state)) as RootState;
  const [phone, status, reqId] = [
    getInputOrRestoredPhone(rootState),
    rootState.mobileId.status,
    rootState.mobileId.reqId
  ];

  if(phone && status){
    yield call(storeMobileIdData, phone, {status, reqId})
  }
}

function* showMobileIdIfNeed(
  {payload: { refreshMobileIdOperatorFromServer}}: ActionWithPayload<ShowMobileIdIfNeedPayload>
): Generator {

  const rootState = (yield select((state: RootState) => state)) as RootState;
  let {operator, config} = rootState.mobileId;
  const workMode = getWorkMode(rootState);

  if(refreshMobileIdOperatorFromServer){

    // load mobileId operator from server
    try {
      const resp = (yield call(queryGetMobileIdOperator)) as GetPhoneOperatorResp;
      operator = resp.operator;
      config = resp.mobileIdConfig;
    } catch (e) {
      log.error('cannot queryGetMobileIdOperator', e);
    }

    operator = operator || PhoneOperator.Unknown;

    yield put(Action.mobileId.SetOperator(operator).pure);

    if(config){
      yield put(Action.mobileId.SetMobileIdConfig(config).pure);
    }
  }

  // Full: only mobile id
  if(workMode === WorkMode.Full){
    yield put(Action.global.Transmit({ state: UIState.MOBILE_ID, replaceHistory: true }).pure);
  }
  // Simple: only manual input
  else if(workMode === WorkMode.Simple){
    yield put(Action.global.Transmit({ state: UIState.PROFILE, replaceHistory: true }).pure);
  }
  // mobile id if operator if fine
  else if(operator !== PhoneOperator.Unknown){
    yield put(Action.global.Transmit({ state: UIState.MOBILE_ID, replaceHistory: true }).pure);
  }
  // manual input on unknown operator
  else {
    yield put(Action.global.Transmit({ state: UIState.PROFILE, replaceHistory: true }).pure);
  }

}


function* startMobileIdRequest() {

  const rootState = (yield select((state: RootState) => state)) as RootState;
  const {mobileId} = rootState;
  const {config, birthDayForCheck} = mobileId;
  const oldReqId = mobileId.reqId;

  const hasValidBirthDayForCheck = birthDayForCheck && ! birthDayForCheck.hasError;

  const needBirthDay = config
    && config.checkBirthday
    && !hasValidBirthDayForCheck;

  if(config && config.checkBirthday && needBirthDay){
    yield put(Action.mobileId.SetStatus(MobileIdStatus.NeedBirthDay).pure);
    yield put(Action.mobileId.SaveState().pure);
    return;
  }

  const data: StartMobileIdReq = {};
  if(hasValidBirthDayForCheck){
    data.birthDayForCheck = birthDayForCheck?.value;
  }

  let reqId: string|undefined;

  try {

    Metrika.reach(Goal.mobileId.SendReq);

    const resp = (yield call(queryStartMobileId, data)) as MobileIdReq;
    reqId = resp.reqId;
  } catch (e){

    if(e.code !== ApiError.common.RateLimit) {
      Metrika.reach(Goal.err.MobileIdStart);
      throw e;
    }

    // can use old reqId
    if(oldReqId){
      reqId = oldReqId;
    }
    else {
      Metrika.reach(Goal.mobileId.TooManyReqs);
      yield put(Action.global.AddError({ messageId: 'common.error.to_many_request' }).pure);
      return;
    }
  }

  yield put(Action.mobileId.SetReqId(reqId).pure);
  yield put(Action.mobileId.SetStatus(MobileIdStatus.WaitResult).pure);
  yield put(Action.mobileId.SaveState().pure);
}


let curWaitResultSpawnId: string;

function* waitMobileIdResultStart(
  {payload: {reqId}}: ActionWithPayload<WaitResultPayload>
): Generator {
  curWaitResultSpawnId = Util.uuid();
  yield spawn(waitMobileIdResult, reqId, curWaitResultSpawnId);
}


function* waitMobileIdResult(checkReqId: string, spawnId: string): Generator {
  let nextCall = true;
  while (nextCall) {

    // check is outdated
    if(curWaitResultSpawnId !== spawnId)
      break;

    let reqState: MobileIdRequestState;
    let errorCode: string | undefined;

    try {
      const reqStatus = (yield call(queryGetMobileIdRequestStatus, checkReqId)) as MobileIdRequestStatus;

      reqState = reqStatus.state;
      errorCode = reqStatus.errorCode;

    }catch (e) {

      // connection problem
      if( ! e.status || e.status >= 500){
        reqState = MobileIdRequestState.WaitConfirm;
      }
      // bad request
      else {
        log.error('cannot get request status', e);
        reqState = MobileIdRequestState.FailedGetInfo;
      }
    }

    const rootState = (yield select((state: RootState) => state)) as RootState;
    const {reqId, status} = rootState.mobileId;

    // check is outdated
    if(curWaitResultSpawnId !== spawnId
        || reqId !== checkReqId
        || status !== MobileIdStatus.WaitResult)
      break;

    switch (reqState) {
      case MobileIdRequestState.WaitConfirm: {
        nextCall = true;
        break;
      }
      case MobileIdRequestState.CanceledByUser: {
        Metrika.reach(Goal.mobileId.CancelReq);
        yield onUserCanceled();
        nextCall = false;
        break;
      }
      case MobileIdRequestState.FailedGetInfo: {
        yield onFailedGetInfo(errorCode);
        nextCall = false;
        break;
      }
      case MobileIdRequestState.VerificationNotStarted: {
        Metrika.reach(Goal.err.CannotStartUpridAndPersonification);
        yield onVerificationNotStarted();
        nextCall = false;
        break;
      }
      case MobileIdRequestState.VerificationStarted: {
        Metrika.reach(Goal.err.StartUpridNotPersonification);
        yield onVerificationStarted();
        nextCall = false;
        break;
      }
      case MobileIdRequestState.PersonificationDone: {
        Metrika.reachOnce(Goal.mobileId.PersonificationDone);
        // same as VerificationStarted for view
        yield onVerificationStarted();
        nextCall = false;
        break;
      }
      default: {
        // unknown status
        yield onFailedGetInfo();
        nextCall = false;
      }
    }

    // wait to check again
    if(nextCall)
      yield delay(5000);
  }
}

function* onUserCanceled() {
  yield put(Action.mobileId.SetStatus(MobileIdStatus.NeedSelect).pure);
  yield put(Action.mobileId.SaveState().pure);
  yield put(Action.global.AddError({ messageId: 'mobile_id.errors.canceled_by_user' }).pure);
}

function* onFailedGetInfo(errorCode?: string) {

  yield put(Action.mobileId.SetStatus(MobileIdStatus.NeedSelect).pure);
  yield put(Action.mobileId.SaveState().pure);

  let messageId = 'mobile_id.errors.failed_get_info';

  if(errorCode === ApiError.mobileId.InvalidBirthDayForCheck){
    messageId = 'mobile_id.errors.invalid_birthday_for_check';
    Metrika.reach(Goal.err.InvalidBirthdateForMobileId);
    yield put(Action.mobileId.SetBirthdayForCheck(undefined).pure);
  }

  Metrika.reach(Goal.err.GetUserInfoFromMobileId);
  yield put(Action.global.AddError({ messageId}).pure);
}

function* onVerificationNotStarted() {

  const rootState = (yield select((state: RootState) => state)) as RootState;
  const workMode = getWorkMode(rootState);

  // try to start uprid instead of personification
  if(workMode !== WorkMode.Full){
    yield put(Action.remoteState.Reload().pure);
    yield put(Action.global.Transmit({state: UIState.PROFILE}).pure);
    yield put(Action.mobileId.SetStatus(MobileIdStatus.Skip).pure);
    yield put(Action.mobileId.SaveState().pure);
    yield put(Action.global.AddError({messageId: 'mobile_id.errors.verification_not_started'}).pure);
  }
  // show failed state
  else {
    yield put(Action.mobileId.SetStatus(MobileIdStatus.Failed).pure);
    yield put(Action.mobileId.SaveState().pure);
  }
}

// eslint-disable-next-line require-yield
function* onVerificationStarted() {
  yield put(Action.remoteState.Reload().pure);
  yield put(Action.global.Transmit({state: UIState.VERIFICATION, replaceHistory: true}).pure);
  yield put(Action.mobileId.SetStatus(MobileIdStatus.Skip).pure);
  yield put(Action.mobileId.SaveState().pure);
}



export default function* rootSaga() {

  yield takeEvery(Action.mobileId.RestoreState.type, restoreState);

  yield takeEvery(Action.mobileId.SaveState.type, saveState);

  yield takeEvery(Action.mobileId.ShowMobileIdIfNeed.type,
    withGlobalLock(
      showMobileIdIfNeed
    ));

  yield takeEvery(Action.mobileId.StartRequest.type,
    withGlobalLock(
      withErrorHandler(
        startMobileIdRequest
      )));

  yield takeEvery(Action.mobileId.WaitResult.type, waitMobileIdResultStart);
}
