
// from: https://medium.com/@95yashsharma/polyfill-for-promise-allsettled-965f9f2a003
// support for old browsers
export function initPolyfillForPromiseAllSettled(){

  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  if(Promise.allSettled)
    return;

  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  Promise.allSettled = function (promises: Promise<any>[]){
    const mappedPromises = promises.map((p: Promise<any>) => {
      return p
        .then((value) => {
          return {
            status: 'fulfilled',
            value,
          };
        })
        .catch((reason) => {
          return {
            status: 'rejected',
            reason,
          };
        });
    });
    return Promise.all(mappedPromises);
  }
}