import { Action, Dispatch } from 'redux';
import { Util } from '@/utils/util';

const log = Util.getLog('ReduxUtil');

export interface ActionWithPayload<P> extends Action<string> {
  type: string;
  payload: P;
}

export interface ActionWithoutPayload extends Action<string> {
  type: string;
}

export interface AsyncActionWithPayload<P, R> {
  pure: ActionWithPayload<P>;

  (dispatch: Dispatch<any>): Promise<R>;
}

export interface AsyncActionFactoryWithPayload<P, R> {
  type: string;

  (payload: P): AsyncActionWithPayload<P, R>;
}

export interface AsyncAction<R> {
  pure: ActionWithoutPayload;

  (dispatch: Dispatch<any>): Promise<R>;
}

export interface AsyncActionFactory<R> {
  type: string;

  (): AsyncAction<R>;
}

export interface SyncAction {
  pure: ActionWithoutPayload;

  (dispatch: Dispatch<any>): void;
}

export interface SyncActionFactory {
  type: string;

  (): SyncAction;
}

export interface SyncActionWithPayload<P> {
  pure: ActionWithPayload<P>;

  (dispatch: Dispatch<any>): void;
}

export interface SyncActionFactoryWithPayload<P> {
  type: string;

  (payload: P): SyncActionWithPayload<P>;
}

export type ActionCreator = {
  asyncActionWithPayload<P, R>(type: string): AsyncActionFactoryWithPayload<P, R>;
  asyncAction<R>(type: string): AsyncActionFactory<R>;
  syncActionWithPayload<P>(type: string): SyncActionFactoryWithPayload<P>;
  syncAction(type: string): SyncActionFactory;
};

export function actionCreator<ActionType>(namespace: string): ActionCreator {
  return {
    asyncActionWithPayload<P, R>(type: string): AsyncActionFactoryWithPayload<P, R> {
      const f = (payload: P): AsyncActionWithPayload<P, R> => {
        const pure = {
          type: `${namespace}/${type}`,
          payload,
        };
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        const d = (dispatch: Dispatch<any>): Promise<R> => dispatch(pure);
        d.pure = pure;
        return d;
      };
      f.type = `${namespace}/${type}`;
      return f;
    },
    asyncAction<R>(type: string): AsyncActionFactory<R> {
      const f = (): AsyncAction<R> => {
        const pure = {
          type: `${namespace}/${type}`,
        };
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        const d = (dispatch: Dispatch<any>): Promise<R> => dispatch(pure);
        d.pure = pure;
        return d;
      };
      f.type = `${namespace}/${type}`;
      return f;
    },
    syncActionWithPayload<P>(type: string): SyncActionFactoryWithPayload<P> {
      const f = (payload: P): SyncActionWithPayload<P> => {
        const pure = {
          type: `${namespace}/${type}`,
          payload,
        };
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        const d = (dispatch: Dispatch<any>): void => dispatch(pure);
        d.pure = pure;
        return d;
      };
      f.type = `${namespace}/${type}`;
      return f;
    },
    syncAction(type: string): SyncActionFactory {
      const f = (): SyncAction => {
        const pure = {
          type: `${namespace}/${type}`,
        };
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        const d = (dispatch: Dispatch<any>): void => dispatch(pure);
        d.pure = pure;
        return d;
      };
      f.type = `${namespace}/${type}`;
      return f;
    },
  };
}

let defaultErrorHandler: (error: any)=> Generator;

export function setDefaultErrorHandler(fn: (error: any)=> Generator){
  defaultErrorHandler = fn;
}

export const withErrorHandler = <T extends Action>(
  worker: (action: T) => unknown,
  onError?: (error: any) => void,
): ((action: T) => Generator<unknown, unknown, T>) =>
  function* runner(action: T): Generator<unknown, void, T> {
    try {

      yield worker(action);

    }
    catch (e) {

      log.error('cannot handle action', e);

      // any handler
      if(onError)
        yield onError(e);
      else if(defaultErrorHandler)
        yield defaultErrorHandler(e);
    }
  };
