import clsx from 'clsx';
import React, { PropsWithChildren, ReactNode } from 'react';

import { Box, Button, CircularProgress, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';

import { ReactComponent as FailedIcon } from '@/assets/icons/status-failed.svg';
import { ReactComponent as SuccessIcon } from '@/assets/icons/status-success.svg';
import { Space } from '@/components';
import { BaseContainer } from '@/layout/components';
import { BaseContainerProps } from '@/layout/components/BaseContainer';
import { getThemeValues } from '@/hocs/withTheme';

const useStyles = makeStyles((theme: Theme) => {

  const values = getThemeValues();

  const button = {
    marginBottom: theme.spacing(2),
    borderRadius: '12px',
    padding: 0,
    fontSize: '18px',
    lineHeight: `${theme.spacing(7)}px`,
  };

  return {
    logoutBox: {
      position: 'absolute',
      right: 0,
      top: 0,
    },
    headerIconWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    headerIcon: {
      position: 'absolute',
      alignSelf: 'center',
      zIndex: 2,
      height: 84,
    },
    headerIconContainer: {
      position: 'absolute',
      alignSelf: 'center',
      backgroundColor: '#302E4D',
      borderRadius: '50%',
      width: 84,
      height: 84,
      textAlign: 'center',
      verticalAlign: 'middle',
      zIndex: 2,
    },
    header: {
      paddingTop: theme.spacing(),
      marginBottom: theme.spacing(3),
    },
    container: {
      paddingTop: 42 + theme.spacing(3),
    },
    footer: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      paddingBottom: theme.spacing(2),
    },
    afterSubmit: {
      justifyContent: 'center',
    },
    titleButton: {
      position: 'absolute',
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(0.5),
    },
    mainBox: {
      width: theme.MainContainer.width,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto',
    },
    mainContent: {
      flex: 1,
      overflowX: 'auto',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    buttonEnabled: {
      ...button,
      background: `${values.mainColor} !important`,
      borderColor: `${values.mainColor} !important`,
      color: theme.palette.secondary.contrastText,
    },
    buttonDisabled: {
      ...button,
    },
  };
});

type SubmittableProps = PropsWithChildren<{
  submittable?: boolean;
}>;

const Submittable: React.FC<SubmittableProps> = ({ submittable, children }: SubmittableProps) => {
  const classes = useStyles();
  return submittable ? (
    <form
      className={clsx(classes.mainBox, 'x-status')}
      onSubmit={(e): void => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {children}
    </form>
  ) : (
    <Box className={clsx(classes.mainBox, 'x-status')}>{children}</Box>
  );
};

export type Status = 'success' | 'failed' | 'awaiting';

export type StatusLayoutProps = Omit<BaseContainerProps, 'children'> &
  PropsWithChildren<{
    footer?: {
      beforeSubmit?: ReactNode;
      beforeSubmitClassname?: string;
      afterSubmit?: ReactNode;
      afterSubmitClassname?: string;
      className?: string;
    };
    status: Status;
    submit?: {
      title: ReactNode;
      onClick: () => void | Promise<void>;
      disabled?: boolean;
    };
  }>;

export const StatusLayout: React.FC<StatusLayoutProps> = ({
  children,
  status,
  submit,
  height,
  footer,
  ...props
}: StatusLayoutProps) => {
  const classes = useStyles();
  const baseClasses = useTheme();
  const footerHeight = 32;
  return (
    <BaseContainer
      {...props}
      boxClassname={clsx(classes.container, 'x-status-content')}
      containerStart={
        <div className={clsx(classes.headerIconWrapper, 'x-header-icon-wrapper')}>
          {status === 'success' && <SuccessIcon className={clsx(classes.headerIcon, 'x-status-icon')} />}
          {status === 'failed' && <FailedIcon className={clsx(classes.headerIcon, 'x-status-icon')} />}
          {status === 'awaiting' && (
            <Box className={clsx(classes.headerIconContainer, 'x-status-icon', 'x-status-icon-progress')}>
              <CircularProgress
                thickness={4}
                size={48}
                variant="indeterminate"
                style={{ color: 'white', marginTop: 18 }}
              />
            </Box>
          )}
        </div>
      }
      height={
        // prettier: FIXME: https://github.com/prettier/prettier/pull/7111
        // prettier-ignore
        (height && height !== 'unset' ? height : baseClasses.MainContainer.height)
        // + (partner?.name ? headerHeight : 0)
        + (footer ? footerHeight : 0)
      }
    >
      <Submittable submittable={!!submit}>
        <Space direction="vertical" size={3} className={classes.mainContent}>
          {children}
        </Space>
        <Space direction="vertical" size={2} className={clsx(classes.footer, footer?.className)}>
          {footer?.beforeSubmit && (
            <Space size={0} className={footer?.beforeSubmitClassname}>
              {footer.beforeSubmit}
            </Space>
          )}
          {submit && (
            <Button
              className={submit.disabled ? classes.buttonDisabled : classes.buttonEnabled}
              onClick={submit.onClick}
              variant="outlined"
              color="primary"
              type="submit"
              disabled={submit.disabled}
            >
              {submit.title}
            </Button>
          )}
          {footer?.afterSubmit && (
            <Space size={0} className={clsx(classes.afterSubmit, footer?.afterSubmitClassname)}>
              {footer.afterSubmit}
            </Space>
          )}
        </Space>
      </Submittable>
    </BaseContainer>
  );
};

export default StatusLayout;
