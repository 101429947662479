import * as React from 'react';
import { FormattedMessage } from 'react-intl';


const AboutProviderLabel: React.FC = () => (
  <div style={{ fontSize: '11px', textAlign: 'center', margin: '0 auto 15px auto'}}>
    <FormattedMessage id="common.about_identity_provider" />
  </div>
);


export default AboutProviderLabel;