import React from 'react';

import { GlobalBackdrop } from './components';

const withGlobalBackdrop = (Component: React.FunctionComponent): React.FC => ({
  ...props
}: {
  [k in string]: unknown;
}): JSX.Element => (
  <>
    <Component {...props} />
    <GlobalBackdrop />
  </>
);

export default withGlobalBackdrop;
