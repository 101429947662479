
import { Net } from '@/utils/axios';
import { ReqIdResp } from '@/api/dto';


export const queryPutUserPhone = async (
  {phone, lang}: {phone: string, lang: string}
  ): Promise<ReqIdResp> => {

  const resp: ReqIdResp = await Net.put('/user/phone', {
    phone,
    lang
  });

  return resp;
};

export const queryConfirmUserPhone = async (
  {reqId, code} : {reqId: string, code: string}
  ) =>{

  await Net.post('/user/phone/confirm', {
    reqId,
    code
  });
};

export const querySendPhoneExtraConfirmCode = async (lang: string): Promise<ReqIdResp> => {

  const resp: ReqIdResp = await Net.post('/user/phone/send-extra-confirm-code', {
    lang
  });

  return resp;
}

export const queryExtraConfirmPhone = async (
  {reqId, code} : {reqId: string, code: string}
) =>{

  await Net.post('/user/phone/extra-confirm', {
    reqId,
    code
  });
};