import { Backdrop, CircularProgress, createStyles, Theme } from '@material-ui/core';
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import { RootState } from '@/model/types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
      backgroundColor: 'rgba(0,0,0,0.3)'
    },
  }),
);

const noop = (e: Event): void => {
  e.preventDefault();
  e.stopPropagation();
};

const GlobalBackdrop: React.FC = () => {
  const classes = useStyles();
  const loading = useSelector((state: RootState) => state.global.loading);
  useEffect(() => {
    if (loading) {
      window.addEventListener('keyup', noop, false);
      window.addEventListener('keydown', noop, false);
    }
    return (): void => {
      window.removeEventListener('keyup', noop, false);
      window.removeEventListener('keydown', noop, false);
    };
  }, [loading]);

  if(!loading)
    return (<></>);

  return (
    <>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default GlobalBackdrop;
