import React, { ReactNode } from 'react';

import { Box, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  mainBoxContainer: {
    paddingTop: theme.spacing(8),
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  mainContainer: {
    ...theme.MainContainer,
    flexDirection: 'column',
    overflow: 'auto',
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
    borderRadius: '12px',
    position: 'relative',
  },
}));

export type BaseContainerProps = {
  height?: number | 'unset';
  width?: number | 'unset';
  containerClassname?: string;
  boxClassname?: string;
  containerStart?: ReactNode;
  children?: React.ReactNode;
};

export const BaseContainer: React.FC<BaseContainerProps> = ({
  containerStart,
  containerClassname,
  boxClassname,
  children,
  height,
  width,
}: BaseContainerProps) => {
  const classes = useStyles();

  return (
    <Box
      {...(width ? { style: { width } } : {})}
      minHeight="100vh"
      data-test="box"
      className={clsx(classes.mainBoxContainer, containerClassname, 'x-main-box')}
    >
      {containerStart}
      <Container
        fixed
        maxWidth="xs"
        className={clsx(classes.mainContainer, boxClassname, 'x-main-container')}
        {...(height ? { style: { height } } : {})}
      >
        {children || (<></>)}
      </Container>
    </Box>
  );
};

export default BaseContainer;
