import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Typography, useTheme } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';

import { BackButton } from '@/components';
import { ReactComponent as LinkIcon } from '@/assets/icons/link.svg';
import { MainLayout } from '@/layout';
import { ExtraDocValueType } from '@/model/documents/actions';
import { RootState, skipInn } from '@/model/types';
import { DocumentCheckbox } from '@/pages/DocumentsPage/components';
import { Action } from '@/model/actions';
import { isValidInn, isValidSnils } from '@/model/documents/types';
import { DigitsKeyboardAttrs } from '@/components/mobile-util';
import AboutProviderLabel from '@/components/AboutProviderLabel';
import { Config } from '@/config';
import { Util } from '@/utils/util';
import { Goal, Metrika } from '@/api/metrika';

export const DocumentsPage: React.FC = () => {

  const baseClasses = useTheme();
  const dispatch = useDispatch();

  const [firstValidation, setFirstValidation] = useState(true);
  const state = useSelector((state: RootState) => state);
  const { taxId, ssn, taxIdServiceUnavailable } = state.documents;
  const taxSelected = taxId?.selected || false;
  const ssnSelected = ssn?.selected || false;
  const useInn = ! skipInn(state);
  const showProviderInfo = Config.DefaultAgreement;
  const warnInn = Util.parseBool(state.params['warn-inn'], false);
  const showInnWarning = taxIdServiceUnavailable || warnInn || !useInn;

  useEffect(()=>{
    if(firstValidation){
      setFirstValidation(false);
    }
  }, [firstValidation]);

  const innBlock = (<DocumentCheckbox
    InputAttrs={DigitsKeyboardAttrs}
    i18nPrefix="documents.fields.taxId"
    value={taxId}
    normalizeValue={(value): string => value.replace(/[^\d]/g, '')}
    onChange={async ({ value, selected }: { value: string; selected: boolean }) => {

        const hasError = !isValidInn(value);

        await Action.document.Set({
          selected,
          value,
          type: ExtraDocValueType.TAX_ID,
          hasError,
        })(dispatch);

        if(!firstValidation && !hasError){
          Metrika.reach(Goal.doc.InnUpdated);
        }
      }
    }
    extraComponent={
      <Link href="https://service.nalog.ru/inn.do" target="_blank" style={{ alignSelf: 'flex-end' }}>
        <FormattedMessage id="documents.fields.taxId.link"/>
        <LinkIcon style={{ verticalAlign: 'sub', marginLeft: baseClasses.spacing(1.5) }}/>
      </Link>
    }
    validate={firstValidation}
  />);

  const snilsBlock = (<DocumentCheckbox
    InputAttrs={DigitsKeyboardAttrs}
    i18nPrefix="documents.fields.ssn"
    value={ssn}
    normalizeValue={(value): string => value.replace(/[^\d]/g, '')}
    onChange={async ({ value, selected }: { value: string; selected: boolean }) => {

        const hasError = !isValidSnils(value);

        await Action.document.Set({
          selected,
          value,
          type: ExtraDocValueType.SSN,
          hasError,
        })(dispatch);

        if(!firstValidation && !hasError){
          Metrika.reach(Goal.doc.SnilsUpdated);
        }
      }
    }
    validate={firstValidation}
  />);

  return (
    <MainLayout
      title={<FormattedMessage id="documents.title" />}
      buttons={{ leftTitle: BackButton }}
      submit={{
        onClick: async (): Promise<void> => {
          await Action.document.SaveAndStartVerification()(dispatch);
        },
        // prettier: FIXME: https://github.com/prettier/prettier/pull/7111
        // prettier-ignore
        disabled:
          (!taxSelected
            && !ssnSelected)
          || (taxSelected
            && (!taxId?.value
              || taxId?.value?.length === 0
              || taxId?.hasError))
          || (ssnSelected
            && (!ssn?.value
              || ssn?.value?.length === 0
              || ssn?.hasError))
          || (!useInn && !ssnSelected),
        title: <FormattedMessage id="documents.submit.title" />,
      }}
      footer={{
        beforeSubmit: showProviderInfo && (<AboutProviderLabel/>)
      }}
    >
      <Typography variant="body1" style={{ ...baseClasses.formLayout.baseElement }}>
        <FormattedMessage id={useInn? 'documents.description' : 'documents.description_single'} />
      </Typography>

      { ! showInnWarning &&
        <>
          {useInn && innBlock}
          {snilsBlock}
        </>
      }
      { showInnWarning &&
        <>
          <Alert severity="warning" variant="outlined">
            <AlertTitle>
              <FormattedMessage id="common.warning"/>
            </AlertTitle>
            <FormattedMessage id="documents.inn_service_unavailable"/>
          </Alert>
          {snilsBlock}
          {useInn && innBlock}
        </>
      }



    </MainLayout>
  );
};

export default DocumentsPage;
