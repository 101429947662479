

export enum VerificationStatus {
  NotVerified = 'NotVerified',
  InProgress = 'InProgress',
  VerificationFailed = 'VerificationFailed',
  Verified = 'Verified',
  AlreadyVerifiedInOtherProfile = 'AlreadyVerifiedInOtherProfile',
}

export interface State {
  status?: VerificationStatus;
}

export const initialState: State = {};
