import { FormattedMessage } from 'react-intl';
import React, { ReactNode } from 'react';
import { Typography } from '@material-ui/core';
import { FormattedMessageWithMarkupProps } from '@/components/FormattedMessageWithMarkup/types';

const FormattedMessageWithMarkup: React.FC<FormattedMessageWithMarkupProps> = ({
  values = {},
  ...props
}: FormattedMessageWithMarkupProps) => (
  <FormattedMessage
    {...props}
    values={{
      br: <br />,
      paragraph: (content: string): ReactNode => <Typography variant="body1">{content}</Typography>,
      bold: (content: string): ReactNode => <strong>{content}</strong>,
      h1: (content: string): ReactNode => <Typography variant="h4">{content}</Typography>,
      h2: (content: string): ReactNode => <Typography variant="h5">{content}</Typography>,
      ul: (content: string): ReactNode => <ul>{content}</ul>,
      li: (content: string): ReactNode => <li>{content}</li>,
      ...values,
    }}
  />
);

export default FormattedMessageWithMarkup;
