import { UIState } from '@/model/global/types';
import { Document } from '@/model/agreement/types';

export const DOCUMENTS_PATH = UIState.AGREEMENT.toLowerCase();

export const documentTypeMessageMapping: { [k in Document]: string } = {
  [Document.USER_AGREEMENT]: 'registration.documents.license.agreement',
};

export const documentTypePathMapping: { [k in Document]: string } = {
  [Document.USER_AGREEMENT]: 'user',
};

export function documentPath(k: Document): string {
  return `${DOCUMENTS_PATH}/${documentTypePathMapping[k]}`;
}

export function documentByPath(path: string): Document | undefined {
  if (path.endsWith(documentPath(Document.USER_AGREEMENT))) {
    return Document.USER_AGREEMENT;
  }
  return undefined;
}
