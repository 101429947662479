import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/model/types';
import { MobileIdStatus } from '@/model/mobileId/types';
import { NeedSelectPage } from '@/pages/MobileIdPage/NeedSelectPage';
import { WaitUserResult } from '@/pages/MobileIdPage/WaitUserResult';
import { NeedBirthDayPage } from '@/pages/MobileIdPage/NeedBirthDayPage';
import { Goal, Metrika } from '@/api/metrika';
import { MobileIdFailed } from '@/pages/MobileIdPage/MobileIdFailed';


export const MobileIdPage: React.FC = () => {

  const rootState = useSelector((state: RootState) => state);
  const status = rootState.mobileId.status || MobileIdStatus.NeedSelect;

  useEffect(()=>{
    if(status === MobileIdStatus.NeedSelect){
      Metrika.reachOnce(Goal.mobileId.StartScreen);
    }
    else if(status === MobileIdStatus.Failed){
      Metrika.reachOnce(Goal.mobileId.Failed);
    }
  }, [status]);

  switch (status) {
    case MobileIdStatus.NeedSelect:
      return (<NeedSelectPage/>);
    case MobileIdStatus.Skip:
      return (<NeedSelectPage/>);
    case MobileIdStatus.NeedBirthDay:
      return (<NeedBirthDayPage/>);
    case MobileIdStatus.WaitResult:
      return (<WaitUserResult/>);
    case MobileIdStatus.Failed:
      return (<MobileIdFailed/>);
    default:
      return (<></>);
  }

};

export default MobileIdPage;
