import axios from 'axios';
import { Config } from '@/config';
import { SessionStorage } from '@/api/storage';
// eslint-disable-next-line @typescript-eslint/camelcase
import jwt_decode from 'jwt-decode';

export const Net = axios.create({
  baseURL: Config.ServerUrl,
  timeout: Config.FetchTimeout,
  withCredentials: true,
});

export interface SessionListener {

  onSessionUpdated: (sessionExpDate: number|undefined)=>void;
}

// if sessionStorage is unsupported by Browser:
// reloading of Page will remove a Session
let curPageToken: string|undefined;
let sessionExpDate: number|undefined;
const sessionListeners: SessionListener[] = [];

export function addSessionListener(listener: SessionListener){
  sessionListeners.push(listener);
}

// restored old session after all listeners added
setTimeout(initSessionExpDate, 0);


Net.interceptors.request.use( (config)=> {

  const token = getSessionToken();
  if(token)
    config.headers.Authorization =  `Bearer ${token}`;

  return config;
});

Net.interceptors.response.use(
  (response) => {

    const token = response.headers['x-auth'];
    if(token)
      storeSessionToken(token);

    return response.data;
  },
  (e) => {
    const out: any = new Error();

    out.url = e.config?.url;
    out.method = e.request?.method;
    out.message = e.message;

    if(e.response){

      out.status = e.response.status;
      out.statusText = e.response.statusText;

      const data = e.response.data || {};
      Object.keys(data).forEach((key)=>{
        out[key] = data[key];
      });
    }

    throw out;
  },
);



export function clearSessionToken(){
  curPageToken = undefined;
  SessionStorage.removeItem('auth');
}

function storeSessionToken(token: string) {
  if (token) {

    curPageToken = token;
    SessionStorage.setItem('auth', token);

    initSessionExpDate();
  }
}

function getSessionToken(){
  return curPageToken || SessionStorage.getItem('auth');
}


function initSessionExpDate(){
  try {

    const token = getSessionToken();
    if( ! token){
      sessionExpDate = undefined;
      return;
    }

    const payload = parseJwt(token);
    if(!payload || ! payload.exp){
      sessionExpDate = undefined;
      return;
    }

    sessionExpDate = Number.parseInt(payload.exp, 10) * 1000;

  } finally {
    sessionListeners.forEach(listener => {
      listener.onSessionUpdated(sessionExpDate);
    });
  }
}

export function getSessionExpDate(): number | undefined {
  return sessionExpDate;
}


function parseJwt(token: string): any{
  try {
    return jwt_decode(token);
  } catch (e) {
    return null;
  }
}