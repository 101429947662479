import clsx from 'clsx';
import React, {
  createRef,
  FocusEvent,
  forwardRef,
  KeyboardEvent,
  ReactNode,
  RefObject,
  SyntheticEvent,
  useEffect,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';

import { Box, createStyles, FormHelperText, Input, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    error: {
      color: theme.palette.error.main,
      alignSelf: 'center',
      textAlign: 'center',
      marginTop: '10px !important',
      marginBottom: '20px !important',
      fontSize: '15px'
    },
    codeBox: {
      justifyContent: 'center',
      display: 'flex',
      flexShrink: 0,
      '& > *': {
        marginRight: '3px',
        marginLeft: '3px',
      },
      '& > :first-child': {
        marginLeft: 0,
      },
      '& > :last-child': {
        marginRight: 0,
      },
      '& > :nth-child(3)': {
        marginRight: '10px',
      },
      '& > :nth-child(4)': {
        marginLeft: '10px',
      },
      marginBottom: '20px !important',
    },
    codeBoxError: {
      marginBottom: '0 !important',
    },
    codeGroupBox: {
      marginRight: '7px',
      marginLeft: '7px',
      flex: 'auto',
      '& > *': {
        marginRight: '3px',
        marginLeft: '3px',
      },
    },
    singleDigitInput: {
      width: '40px',
      marginBottom: 0,
    },
    singleDigitInputHTML: {
      lineHeight: '51px',
      height: '51px',
      padding: '2.5px',
      textAlign: 'center',
      fontSize: '30px',
      borderRadius: '8px',
      width: '40px',
    },
  }),
);

type DigitInputProps = {
  value?: string;
  ref?: React.Ref<any>;
  hasError?: boolean;
  setFocus?: boolean;
  onBackspace?: () => Promise<void> | void;
  onEnter?: () => void;
  onChange: (value: string) => Promise<void> | void;
  disabled?: boolean;
};

const DigitInput: React.FC<DigitInputProps> = forwardRef(
  (
    {
      value,
      hasError,
      setFocus,
      onChange,
      onBackspace,
      onEnter,
      disabled,
    }: DigitInputProps,
    ref
  ) => {

    const classes = useStyles();

    useEffect(()=>{
      if(setFocus){
        (ref as any)?.current?.focus();
      }
    }, [setFocus, ref]);

    return (
      <Input
        autoComplete="off"
        type="number"
        disabled={disabled || undefined}
        inputRef={ref}
        value={value?.trim()}
        // because real event is in e, nativeEvent differs for browsers
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        onBeforeInput={(e: InputEvent): void => {
          e.preventDefault();
          const data = e.data?.replace(/[^\d]/g, '');
          if (data?.length && data?.length > 0) {
            const newValue = data.charAt(0);
            if (e.target) {
              e.target.dispatchEvent(new InputEvent('input', { bubbles: true, data: newValue }));
            }
          }
        }}
        onInput={(e: SyntheticEvent<HTMLInputElement, InputEvent>): void => {
          const newValue = e.nativeEvent?.data || '';
          onChange(newValue);
        }}
        onKeyUp={(event: KeyboardEvent<HTMLInputElement>): void => {
          // 8 - backspace
          if (event.keyCode === 8 && value?.replace(/[^\d]/g, '') === '') {
            if(onBackspace)
              onBackspace();
          }
          else if(event.keyCode === 13){
            if(onEnter)
              onEnter();
          }
        }}
        onFocus={(event: FocusEvent<HTMLInputElement>): void => {
          event.target.select();
        }}
        disableUnderline
        className={classes.singleDigitInput}
        inputProps={{ className: clsx(classes.singleDigitInputHTML, 'ym-disable-keys') }}
        {...(hasError
          ? {
            error: true,
            'aria-describedby': 'component-error-text',
          }
          : {})}
      />
    );
  },
);

type ConfirmationCodeInputProps = {
  value?: string;
  errorId?: string;
  onChange: (value: string) => Promise<void> | void;
  onEnter?: ()=> void;
  disabled?: boolean,
  focusOn?: boolean;
};

const ConfirmationCode: React.FC<ConfirmationCodeInputProps> = (
  {
    value: initialValue,
    onChange,
    onEnter,
    errorId,
    focusOn,
    disabled,
  }: ConfirmationCodeInputProps
) => {
  const classes = useStyles();
  const [initial, saveInitial] = useState<string | undefined>();
  const [firstShow, setFirstShow] = useState(true);
  const [value, setValue] = useState<string[]>((initialValue || '').padEnd(6, ' ').split(''));

  useEffect(() => {
    if (initialValue !== initial) {
      saveInitial(initialValue);
      setValue((initialValue || '').padEnd(6, ' ').split(''));
    }
  }, [initial, initialValue]);

  useEffect(()=>{

    if( firstShow)
      setFirstShow(false);

  }, [firstShow, setFirstShow]);

  const handleChangeValue = (newValue: string | undefined, idx: number): void => {
    if (idx < value.length) {
      const v = [...value];
      v[idx] = newValue || ' ';
      setValue(v);
      onChange(v.join(''));
    }
  };

  const components: Array<[ReactNode, RefObject<HTMLInputElement>]> = Array.from({ length: 6 }, (v, i) => i).map((idx: number) => {
    const ref = createRef<HTMLInputElement>();
    return [
      <DigitInput
        key={`digitalinput_${idx}`}
        disabled={disabled}
        hasError={!!errorId}
        setFocus={idx === 0 && (firstShow || !!focusOn)}
        value={value[idx]}
        ref={ref}
        onBackspace={(): void => {
          if (idx - 1 >= 0) {
            components[idx - 1]?.[1]?.current?.focus();
          }
        }}
        onEnter={onEnter}
        onChange={(newValue: string | undefined): void => {
          handleChangeValue(newValue, idx);
          if (newValue?.trim() !== '') {
            if (idx + 1 < components.length) {
              components[idx + 1]?.[1]?.current?.focus();
            }
          }
        }}
      />,
      ref,
    ];
  });

  useEffect(()=>{
    if(!disabled && errorId){
      components[components.length-1][1]?.current?.focus();
    }
  }, [disabled, errorId, components])

  return (
    <>
      <Box {...(errorId ? { className: clsx(classes.codeBox, classes.codeBoxError) } : { className: classes.codeBox })}>
        {components.map(([component]) => component)}
      </Box>
      {!!errorId && (
        <FormHelperText id="component-error-text" className={classes.error}>
          <FormattedMessage id={errorId} />
        </FormHelperText>
      )}
    </>
  );
};

export default ConfirmationCode;
