import clsx from 'clsx';
import React from 'react';

import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';

import { ReactComponent as LogoIcon } from '@/assets/icons/logo.svg';
import { ReactComponent as LogoutIcon } from '@/assets/icons/logout.svg';
import BackButton from '@/components/BackButton';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    cursor: 'pointer',
  },
}));

export enum TitleButtonType {
  LOGO = 'LOGO',
  BACK = 'BACK',
  CLOSE = 'CLOSE',
  LOGOUT = 'LOGOUT',
}

export type TitleButtonProp =
  | { type: TitleButtonType; onClick: () => void | Promise<any> }
  | React.FC<{ className: string } & any>;

export type TitleButtonProps = {
  type: TitleButtonProp;
  className?: string;
};

export const TitleButton: React.FC<TitleButtonProps> = ({ type, className: extraClassName }: TitleButtonProps) => {
  const classes = useStyles();
  const className = clsx(classes.button, extraClassName);
  if (typeof type === 'function') {
    return type({ className });
  }
  switch (type.type) {
    case TitleButtonType.CLOSE:
      return <CloseIcon onClick={type.onClick} className={className} />;
    case TitleButtonType.LOGO:
      return <LogoIcon onClick={type.onClick} className={className} />;
    case TitleButtonType.LOGOUT:
      return <LogoutIcon onClick={type.onClick} className={className} />;
    case TitleButtonType.BACK:
    default:
      return <BackButton onBack={type.onClick} className={className}/>;
  }
};

export default TitleButton;
