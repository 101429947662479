import React, { useEffect, useState } from 'react';
import { InputBaseComponentProps } from '@material-ui/core/InputBase/InputBase';
import { Value } from '@/model/common';
import { ProfileValueType } from '@/model/profile/actions';
import { useDispatch } from 'react-redux';
import { Action } from '@/model/actions';
import { TextFieldInput } from '@/components';
import { FormattedMessage } from 'react-intl';
import { Metrika } from '@/api/metrika';

export type ProfileFieldInputProps = {
  InputAttrs?: any,
  inputComponent?: React.ElementType<InputBaseComponentProps>;
  normalizeValue?: (value: string) => string;
  validateValue?: (value: string) => boolean;
  onChanged?: (value: string) => any;
  onBlur?: (value: string) => any;
  value?: Value<string>;
  type: ProfileValueType;
  i18nPrefix: string;
  validate?: boolean;
  specialErrorCode?: string;
  specialLabelCode?: string;
  disabled?: boolean;
  showErrorDirectly?: boolean;
  updatedGoal?: string,
};

export const ProfileFieldInput: React.FC<ProfileFieldInputProps> = ({
  InputAttrs,
  inputComponent,
  value,
  type,
  i18nPrefix,
  normalizeValue = (inputValue: string): string => inputValue,
  validateValue = (): boolean => true,
  onChanged = () => {},
  onBlur = () => {},
  validate,
  specialErrorCode,
  specialLabelCode,
  disabled,
  showErrorDirectly,
  updatedGoal,
}: ProfileFieldInputProps) => {

  const dispatch = useDispatch();

  const hasError = !!value?.hasError;
  const inputValue = value?.value;
  const [canShowError, setCanShowError] = useState(!!(validate && inputValue));
  const showError = canShowError && hasError;

  useEffect(()=>{
    if(showErrorDirectly){
      setCanShowError(true);
    }
  }, [showErrorDirectly])

  function updateModelValue(inputValue: string): boolean {
    const value = normalizeValue(inputValue);
    const hasError = !validateValue(value);
    Action.profile.Set({ value, hasError, type })(dispatch);
    return hasError;
  }

  if(validate && inputValue){
    updateModelValue(inputValue);
  }

  return (
    <TextFieldInput
      InputAttrs={InputAttrs}
      disabled={disabled}
      InputLabelProps={inputComponent ? { shrink: true } : {}}
      inputComponent={inputComponent}
      onBlur={(): void => {

        setCanShowError(true);

        if (!inputValue) {
          updateModelValue('');
        } else {
          onBlur(inputValue);
        }

        if(!hasError && updatedGoal){
          Metrika.reach(updatedGoal);
        }

      }}
      onChange={(inputValue): void => {
        setCanShowError(false);
        updateModelValue(inputValue);
        onChanged(inputValue);
      }}
      value={{ value: inputValue || '', hasError: showError }}
      messages={{
        error: <FormattedMessage id={specialErrorCode || `${i18nPrefix}.invalid`} />,
        label: <FormattedMessage id={specialLabelCode || `${i18nPrefix}.title`} />,
      }}
    />
  );
};