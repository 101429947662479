import { Net } from '@/utils/axios';
import { RemoteStateData } from '@/model/remote_state/types';
import { LangType } from '@/model/Lang';
import { LSCache } from '@/api/storage';

const cacheKey = '/status';
const cacheTimeMs = 1000;

export const queryGetInitState = async (
  lang: LangType,
  authToken?:string,
  jwtMode?:boolean,
): Promise<RemoteStateData>=>{

  const fromCache: RemoteStateData = LSCache.get(cacheKey);
  if(fromCache) {
    return fromCache;
  }

  const authParam = authToken? `&auth=${authToken}` : '';
  const jwtParam = jwtMode? '&jwt=true' : '';
  const result: RemoteStateData = await Net.get(`/init?lang=${lang}${authParam}${jwtParam}`);

  LSCache.set(cacheKey, result, cacheTimeMs);
  return result;
};


export const queryPreloadImage = async (iconUrl?: string) => {

  let done = false;
  let timeoutId: any;
  const waitTimeout = 1000;

  return new Promise((resolve) => {

    const onDone = ()=>{

      if(done)
        return;

      done = true;
      clearTimeout(timeoutId);

      resolve();
    };

    if( ! iconUrl) {
      onDone();
      return;
    }

    const img = new Image();

    img.onload = ()=>{
      onDone();
    };
    img.onerror = ()=>{
      onDone();
    }
    timeoutId = setTimeout(onDone, waitTimeout);


    // start
    img.src = iconUrl;

    // already loaded
    if(img.complete)
      onDone();
  })

}