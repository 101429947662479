import {FocusEvent} from 'react'

export function onMaskFocus(event: FocusEvent<HTMLInputElement>){
  const {target} = event;
  const {value} = target;
  const firstMask = value.indexOf('_');
  if(firstMask > -1) {
    setTimeout(()=>{
      target.setSelectionRange(firstMask, firstMask);
    }, 0);
  }
}